import { Controller } from "@hotwired/stimulus"
import { hide, removeRequired, show } from "../../utils"
import coupaTaskInstructionData from "../../assets/coupa_task_instructions.json"
import StyledSelectController from "../styled_select_controller"

// Connects to data-controller="workflows--task-form"
export default class extends Controller {
  static targets = [
    "preview",
    "taskTypeRadioButton",
    "taskTypeSelect",
    "teammateAssigneeIdSelect",
    "ceManagerAssigneeIdSelect",
    "assigneeIdField",
    "nameContainer",
    "nameField",
    "instructionsContainer",
    "instructionsField",
    "workflowTaskIntegrationTaskIdField",
    "workflowTaskIntegrationTypeField",
    "workflowTaskIntegrationIdField",
    "taskToReopenField",
    "taskToReopenContainer",
    "assignmentTypeSelect",
    "internalCheckboxContainer",
    "internalCheckbox",
    "fakeInternalCheckboxContainer",
    "subgroupSelect",
    "subworkflowInformation",
    "subworkflowViewerButton",
    "dueDateSection",
    "groupLabelSection",
    "subgroupsContainer",
    "subgroupSelectContainer",
    "taskTypesContainer",
    "tasksInputsContainer",
    "taskTypesSelect",
    "taskFormContainer",
    "taskFormSelect",
  ]
  static outlets = ["styled-select"]
  styledSelectOutlet: StyledSelectController
  hasStyledSelectOutlet: Boolean
  previewTarget: HTMLElement
  taskTypesSelectTarget: HTMLSelectElement
  taskTypeRadioButtonTargets: [HTMLInputElement]
  taskTypeSelectTarget: HTMLSelectElement
  hasTaskTypeSelectTarget: Boolean
  teammateAssigneeIdSelectTarget: HTMLInputElement
  ceManagerAssigneeIdSelectTarget: HTMLInputElement
  assigneeIdFieldTarget: HTMLInputElement
  nameContainerTarget: HTMLDivElement
  nameFieldTarget: HTMLInputElement
  instructionsContainerTarget: HTMLDivElement
  instructionsFieldTarget: HTMLInputElement
  workflowTaskIntegrationTaskIdFieldTarget: HTMLInputElement
  workflowTaskIntegrationTypeFieldTarget: HTMLInputElement
  workflowTaskIntegrationIdFieldTarget: HTMLInputElement
  taskToReopenFieldTarget: HTMLSelectElement
  taskToReopenContainerTarget: HTMLDivElement
  assignmentTypeSelectTarget: HTMLSelectElement
  internalCheckboxContainerTarget: HTMLDivElement
  hasInternalCheckboxContainerTarget: Boolean
  internalCheckboxTarget: HTMLInputElement
  hasInternalCheckboxTarget: Boolean
  fakeInternalCheckboxContainerTarget: HTMLDivElement
  hasFakeInternalCheckboxContainerTarget: Boolean
  subgroupSelectTarget: HTMLSelectElement
  hasSubgroupSelectTarget: Boolean
  subworkflowInformationTarget: HTMLDivElement
  subworkflowViewerButtonTarget: HTMLDivElement
  dueDateSectionTarget: HTMLDivElement
  groupLabelSectionTarget: HTMLDivElement
  subgroupsContainerTarget: HTMLDivElement
  subgroupSelectContainerTargets: [HTMLDivElement]
  taskTypesContainerTarget: HTMLDivElement
  tasksInputsContainerTargets: [HTMLDivElement]
  taskFormContainerTarget: HTMLDivElement
  taskFormSelectTarget: HTMLSelectElement

  static values = {
    visibleTaskGroups: String,
    visibleTaskDetails: String,
    context: String,
    initialSelection: String,
    initialSubgroupSelection: String,
    existingRecord: Boolean,
    taskPreviewUrl: String,
    assigneeId: String,
    assignmentType: String,
    taskToReopenId: String,
    taskInternal: Boolean,
    taskType: String,
    noPreview: Boolean,
    editable: Boolean,
    coupaSupplierOnboardingLink: String,
    coupaSupplierOnboardingInstructions: String,
    selectingCoupaTaskType: Boolean,
  }
  visibleTaskGroupsValue: string
  visibleTaskDetailsValue: string
  contextValue: string
  initialSelectionValue: string
  initialSubgroupSelectionValue: string
  existingRecordValue: boolean
  taskPreviewUrlValue: string
  assignmentTypeValue: string
  taskToReopenIdValue: string
  taskInternalValue: boolean
  taskTypeValue: string | null
  noPreviewValue: boolean
  coupaSupplierOnboardingLinkValue: string | null
  coupaSupplierOnboardingInstructionsValue: string | null
  selectingCoupaTaskTypeValue: boolean

  connect() {
    this.updateSubgroupSelections(this.initialSelectionValue, this.initialSubgroupSelectionValue, this.taskTypeValue)
    // Setup task form edit state
    this.toggleTaskFormSelect(this.taskTypesSelectTarget.value)

    this.resetAssignmentType()
  }

  onApprovalTypeChange(event) {
    if (event.target.value == "external") {
      this.updateTypeSelections("external_approval")
    } else {
      this.updateTypeSelections("approval")
    }
  }

  onTaskTypeChange(event) {
    this.updateSubgroupSelections(event.target.value)
    this.conditionallyToggleSubworkflowFields(event.target.value)
    this.conditionallyUpdateSubworkflowHeadings(event.target.value)
    this.toggleTaskFormSelect(event.target.value)
  }

  onSubworkflowPlaceholderChange(event) {
    this.toggleSubworkflowViewerButton(event.target.value)
    this.updateNameWithSubworkflowPlaceholder(event.target.selectedOptions[0].text)
  }

  onSubgroupChange(event) {
    this.selectingCoupaTaskTypeValue = event.srcElement.value === "coupa"
    this.updateTypeSelections(event.target.value)
  }

  updateSubgroupSelections(taskGroupId: string, taskSubgroupId?: string | null, taskClass?: string | null) {
    const subGroupValue = this.hasSubgroupSelectTarget
      ? taskSubgroupId || this.subgroupSelectTarget.value
      : taskSubgroupId
    this.toggleIntegrationSelectEnabled(taskGroupId, subGroupValue)
    let subgroupsPresent = false

    this.subgroupSelectContainerTargets.forEach((container) => {
      if (container.id === `${taskGroupId}-subgroups`) {
        subgroupsPresent = true
        container.style.display = "block"
      } else {
        container.style.display = "none"
      }
    })

    if (subgroupsPresent) {
      this.subgroupsContainerTarget.style.display = "block"
    } else {
      this.subgroupsContainerTarget.style.display = "none"
    }

    this.updateTypeSelections(taskSubgroupId, taskGroupId, taskClass)
  }

  updateTypeSelections(taskSubgroupId: string | null, taskGroupId?: string | null, taskClass?: string | null) {
    let taskTypesPresent = false
    this.tasksInputsContainerTargets.forEach((wrapper) => {
      this.setTaskOnFormInputs(wrapper, taskSubgroupId, taskGroupId, taskClass)
      if (this.showTaskWrapper(wrapper, taskSubgroupId, taskGroupId)) {
        taskTypesPresent = true
      }
    })

    this.taskTypesContainerTarget.style.display = taskTypesPresent ? "block" : "none"

    this.executeConditionalSetup()
    this.updatePreview()
    this.conditionallyToggleSubworkflowFields(taskSubgroupId || taskGroupId)
  }
  isMatchingWrapper(wrapper: HTMLDivElement, taskGroupId: string | null, taskSubgroupId: string | null) {
    let idPrefix = taskSubgroupId || this.firstTaskSubgroupId(taskGroupId) || taskGroupId
    return wrapper.id === `${idPrefix}-task-container`
  }
  setTaskOnFormInputs(
    wrapper: HTMLDivElement,
    taskSubgroupId: string | null,
    taskGroupId: string | null,
    taskClass: string | null,
  ) {
    let radioButtons = wrapper.querySelectorAll('input[type="radio"]') as NodeListOf<HTMLInputElement>

    radioButtons.forEach((button) => (button.checked = false))
    if (this.isMatchingWrapper(wrapper, taskGroupId, taskSubgroupId)) {
      radioButtons.forEach((button, index) => {
        if (taskClass === button.value || index === 0) {
          this.setWorkflowTaskIntegration(button)
          button.checked = true
        }
      })
    }
  }

  showTaskWrapper(wrapper: HTMLDivElement, taskSubgroupId: string | null, taskGroupId: string | null) {
    let radioButtons = wrapper.querySelectorAll('input[type="radio"]') as NodeListOf<HTMLInputElement>
    let showWrapper = false

    if (this.isMatchingWrapper(wrapper, taskGroupId, taskSubgroupId)) {
      let isSubgroupDefined = wrapper.classList.contains("subgroup-defined-tasks")

      if (isSubgroupDefined || radioButtons.length > 1) {
        if (this.contextValue === "approval_chains") {
          if (taskSubgroupId === "integration") {
            showWrapper = true
          }
        } else {
          showWrapper = true
        }
      }
    }

    wrapper.style.display = showWrapper ? "block" : "none"
    return showWrapper
  }

  firstTaskSubgroupId(taskGroupId) {
    let group = JSON.parse(this.visibleTaskGroupsValue).find((taskGroup) => taskGroup["group_id"] === taskGroupId)
    if (group["subgroups_data"]) {
      return group["subgroups_data"]["subgroups"][0]["subgroup_id"]
    }
  }

  taskGroupIdForTaskType(taskType: string) {
    let group = JSON.parse(this.visibleTaskGroupsValue).find((taskGroup) => {
      let taskKlasses = []
      if (taskGroup["subgroups_data"]) {
        taskKlasses = taskGroup["subgroups_data"]["subgroups"].flatMap((subgroup) => {
          return subgroup["tasks"].map((task) => task["klass"])
        })
      } else {
        taskKlasses = taskGroup["tasks"].map((task) => task["klass"])
      }

      return taskKlasses.includes(taskType)
    })

    if (group) return group["group_id"]
  }

  toggleAssigneeTypesSelect(checkedTaskType) {
    if (this.getTaskDetails(checkedTaskType) === undefined) {
      return
    }
    const assignmentTypes = this.getTaskDetails(checkedTaskType).assignment_types
    const assignmentOptions = Array.from(this.assignmentTypeSelectTarget.options)

    const assignmentTypesExceptions = (type: string) => {
      // approval_hierarchies feature allows for custom department assignment types
      if (type.startsWith("department_") && type !== "department_manager") return true

      return false
    }

    assignmentOptions.forEach((option) => {
      if (!assignmentTypesExceptions(option.value)) {
        option.disabled = !assignmentTypes.includes(option.value)
      }
    })
  }

  conditionallyToggleSubworkflowFields(value: String): void {
    if (this.contextValue !== "enhanced_workflows") {
      return
    }

    if (value === "subworkflow_placeholder") {
      this.hideNonSubworkflowFields()
      this.showSubworkflowFields()
    } else {
      this.hideSubworkflowFields()
      this.showNonSubworkflowFields()
    }
  }

  conditionallyUpdateSubworkflowHeadings(value: String): void {
    if (this.contextValue !== "enhanced_workflows") {
      return
    }

    if (value === "subworkflow_placeholder") {
      document.getElementsByClassName("workflow-task-stage-heading")[0].getElementsByTagName("div")[0].innerHTML =
        "Stage"
    }
  }

  toggleSubworkflowViewerButton(value: String): void {
    if (this.contextValue !== "enhanced_workflows") {
      return
    }

    const link = this.subworkflowViewerButtonTarget.getElementsByTagName("a")[0]
    const url_parts = link.href.split("/").slice(3, 7)

    if (value === "") {
      hide(this.subworkflowViewerButtonTarget)
    } else {
      link.href = "/" + url_parts.join("/") + `/${value}/visualization`
      show(this.subworkflowViewerButtonTarget)
    }
  }

  updateNameWithSubworkflowPlaceholder(value: String): void {
    if (this.contextValue !== "enhanced_workflows") {
      return
    }

    if ("Please Select" && value !== "") {
      this.nameFieldTarget.value = value
    }
  }

  hideDueDateSection(): void {
    hide(this.dueDateSectionTarget)
    this.setAssignmentType("unassigned")
    removeRequired([
      this.nameFieldTarget,
      this.assigneeIdFieldTarget,
      this.ceManagerAssigneeIdSelectTarget,
      this.teammateAssigneeIdSelectTarget,
    ])
  }

  hideNonSubworkflowFields(): void {
    this.hideDueDateSection()
    hide(this.groupLabelSectionTarget)
    hide(this.nameContainerTarget)
    hide(this.instructionsContainerTarget)
    this.hideInternalCheckboxContainer()
  }

  showSubworkflowFields(): void {
    show(this.subworkflowInformationTarget)
  }

  hideSubworkflowFields(): void {
    hide(this.subworkflowInformationTarget)
  }

  showNonSubworkflowFields(): void {
    show(this.dueDateSectionTarget)
    show(this.nameContainerTarget)
    show(this.instructionsContainerTarget)
    this.showInternalCheckboxContainer()
    show(this.dueDateSectionTarget)
    show(this.groupLabelSectionTarget)
  }

  showInternalCheckboxContainer() {
    if (this.hasInternalCheckboxContainerTarget) {
      show(this.internalCheckboxContainerTarget)
    }
  }

  hideInternalCheckboxContainer() {
    if (this.hasInternalCheckboxContainerTarget) {
      hide(this.internalCheckboxContainerTarget)
    }
  }

  onTaskTypeSelect(event) {
    this.setWorkflowTaskIntegration(event.target)
    this.executeConditionalSetup()
    this.updatePreview()
  }

  getSelectedTaskType(): string {
    if (this.selectingCoupaTaskTypeValue && this.hasTaskTypeSelectTarget) {
      return this.taskTypeSelectTarget.value
    } else {
      return Array.prototype.find.call(this.taskTypeRadioButtonTargets, (button) => button.checked)?.value
    }
  }
  updatePreview() {
    if (this.noPreviewValue) {
      return
    }

    if (this.assigneeIdFieldTarget.value || this.nameFieldTarget.value || this.instructionsFieldTarget.value) {
      let url = new URL(this.taskPreviewUrlValue)

      const type = this.getSelectedTaskType()
      if (type) {
        url.searchParams.append("type", this.getSelectedTaskType())
        url.searchParams.append("assignee_id", this.assigneeIdFieldTarget.value)
        url.searchParams.append("name", this.nameFieldTarget.value)
        url.searchParams.append("instructions", this.instructionsFieldTarget.value)
        url.searchParams.append(
          "request_task_integration_attributes[integration_type]",
          this.workflowTaskIntegrationTypeFieldTarget.value,
        )
        url.searchParams.append(
          "request_task_integration_attributes[integration_id]",
          this.workflowTaskIntegrationIdFieldTarget.value,
        )

        this.previewTarget.src = url.toString()
      }
    }
  }

  executeConditionalSetup() {
    const selectedTaskType = this.getSelectedTaskType()
    const taskDetails = this.getTaskDetails(selectedTaskType)
    const taskConditionalSetup = taskDetails?.conditional_setup

    this.showNameInstructions()
    this.unsetTaskToReopen()
    this.resetAssignmentType()
    this.unsetMakeInternal()
    this.toggleAssigneeTypesSelect(selectedTaskType)

    if (taskConditionalSetup) {
      if (taskConditionalSetup.includes("hide_name_instructions")) {
        this.hideNameInstructions()
      }
      if (taskConditionalSetup.includes("task_to_reopen")) {
        this.setTaskToReopen()
      }
      if (taskConditionalSetup.includes("csm_assignment")) {
        this.setCsmAssignment()
      }
      if (taskConditionalSetup.includes("ce_assignment")) {
        this.setCeAssignment()
      }
      if (taskConditionalSetup.includes("requestor_assignment")) {
        this.setRequestorAssignment()
      }
      if (taskConditionalSetup.includes("tropic_staff_assignment")) {
        this.setTropicStaffAssignment()
      }
      if (taskConditionalSetup.includes("ce_manager_assignment")) {
        this.setCeManagerAssignment()
      }
      if (taskConditionalSetup.includes("procurement_specialist_assignment")) {
        this.setProcurementSpecialistAssignment()
      }
      if (taskConditionalSetup.includes("make_internal")) {
        this.setMakeInternal()
      }
      if (taskConditionalSetup.includes("fixed_name")) {
        this.setFixedName(taskDetails)
      }
      if (taskConditionalSetup.includes("custom_instructions")) {
        this.setInstructions(taskDetails)
      }
    }
  }

  hideNameInstructions() {
    hide(this.nameContainerTarget)
    hide(this.instructionsContainerTarget)
  }

  showNameInstructions() {
    show(this.nameContainerTarget)
    show(this.instructionsContainerTarget)
  }

  unsetTaskToReopen() {
    if (this.contextValue === "approval_chains") {
      return
    }

    if (this.hasTaskToReopenContainerTarget) {
      this.taskToReopenContainerTarget.style.display = "none"
      this.taskToReopenFieldTarget.selectedIndex = null
    }
  }

  setTaskToReopen() {
    if (this.contextValue === "approval_chains" || !this.hasTaskToReopenContainerTarget) {
      return
    }

    this.taskToReopenContainerTarget.style.display = "block"
    if (!this.taskToReopenFieldTarget.selectedIndex && this.taskToReopenIdValue) {
      Array.prototype.forEach.call(this.taskToReopenFieldTarget.options, (option, index) => {
        if (option.value === this.taskToReopenIdValue) {
          this.taskToReopenFieldTarget.selectedIndex = index
        }
      })
    }
  }

  resetAssignmentType() {
    this.setAssignmentType(this.assignmentTypeValue)
  }

  setAssignmentType(assignmentType) {
    this.assignmentTypeSelectTarget.value = assignmentType
    this.assignmentTypeSelectTarget.dispatchEvent(new Event("change"))
  }

  setCsmAssignment() {
    this.setAssignmentType("customer_success_manager")
  }

  setTropicStaffAssignment() {
    this.setAssignmentType("tropic_staff")
  }

  setCeAssignment() {
    this.setAssignmentType("commercial_executive")
  }

  setCeManagerAssignment() {
    this.setAssignmentType("commercial_executive_manager")
  }

  setProcurementSpecialistAssignment() {
    this.setAssignmentType("procurement_specialist")
  }

  setRequestorAssignment() {
    this.setAssignmentType("requestor")
  }

  unsetMakeInternal() {
    if (this.hasInternalCheckboxTarget && this.hasFakeInternalCheckboxContainerTarget) {
      this.internalCheckboxTarget.checked = this.taskInternalValue
      this.internalCheckboxTarget.classList.remove("hidden")
      this.fakeInternalCheckboxContainerTarget.classList.add("hidden")
    }
  }

  setMakeInternal() {
    if (this.hasInternalCheckboxTarget) {
      this.internalCheckboxTarget.checked = true
      this.internalCheckboxTarget.classList.add("hidden")
      this.fakeInternalCheckboxContainerTarget.classList.remove("hidden")
    }
  }

  setFixedName(taskDetails) {
    if (!this.existingRecordValue) {
      this.nameFieldTarget.value = taskDetails.fixed_name
    }
  }

  setInstructions(taskDetails) {
    if (!this.existingRecordValue && this.instructionsFieldTarget.editor) {
      this.instructionsFieldTarget.editor.loadHTML(taskDetails.instructions_html)
    }
  }

  onAssigneeIdChange(_event) {
    this.updatePreview()
  }

  getTaskDetails(taskType) {
    if (this.contextValue === "requests") {
      return JSON.parse(this.visibleTaskDetailsValue).find(
        (details) => details.klass === this.normalizeTaskPrefix(taskType),
      )
    } else {
      return JSON.parse(this.visibleTaskDetailsValue).find((details) => details.klass === taskType)
    }
  }

  normalizeTaskPrefix(taskType) {
    return taskType.replace("RequestTasks", "WorkflowTasks")
  }

  setDefaultTaskName(defaultName: string, integrationType: string, taskType: string) {
    if (this.taskGroupIdForTaskType(this.normalizeTaskPrefix(taskType)) === "integration") {
      if (!!this.existingRecordValue && !!this.nameFieldTarget.value) return

      if (integrationType == "docusign") {
        this.nameFieldTarget.value = `DocuSign: ${defaultName}`
      } else {
        this.nameFieldTarget.value = defaultName
      }
    }
  }

  setWorkflowTaskIntegration(element: HTMLInputElement | HTMLSelectElement) {
    const taskType = element.value
    const taskDetails = this.getTaskDetails(taskType)
    const taskIntegrationId = taskDetails["task_integration_id"]
    const integrationType = taskDetails["integration_type"]
    const integrationId = taskDetails["integration_id"]

    if (integrationType && integrationId) {
      if (taskIntegrationId) {
        this.workflowTaskIntegrationTaskIdFieldTarget.value = taskIntegrationId
        this.workflowTaskIntegrationTaskIdFieldTarget.disabled = false
      }
      this.workflowTaskIntegrationTypeFieldTarget.value = integrationType
      this.workflowTaskIntegrationIdFieldTarget.value = integrationId
      this.workflowTaskIntegrationTypeFieldTarget.disabled = false
      this.workflowTaskIntegrationIdFieldTarget.disabled = false
    } else {
      this.workflowTaskIntegrationTaskIdFieldTarget.removeAttribute("value")
      this.workflowTaskIntegrationTypeFieldTarget.removeAttribute("value")
      this.workflowTaskIntegrationIdFieldTarget.removeAttribute("value")
      this.workflowTaskIntegrationTaskIdFieldTarget.disabled = true
      this.workflowTaskIntegrationTypeFieldTarget.disabled = true
      this.workflowTaskIntegrationIdFieldTarget.disabled = true
    }

    let labelText =
      element instanceof HTMLSelectElement ? element.selectedOptions[0].innerText : element.labels[0].innerText

    this.setDefaultTaskName(labelText, integrationType, taskType)

    let instructionData = coupaTaskInstructionData[labelText]

    if (labelText === "Onboard Supplier" && this.coupaSupplierOnboardingInstructionsValue.length > 0) {
      instructionData["content"] = this.coupaSupplierOnboardingInstructionsValue
    }

    if (instructionData && this.instructionsFieldTarget.editor) {
      this.instructionsFieldTarget.editor.loadHTML(
        instructionData.content.replaceAll("SUPPLIER_ONBOARDING_URL", this.coupaSupplierOnboardingLinkValue || ""),
      )
    }
  }

  toggleIntegrationSelectEnabled(taskType: string | null, integrationType: string | null) {
    if (!this.hasStyledSelectOutlet) {
      return
    }

    if (taskType == "integration" && integrationType == "coupa") {
      this.selectingCoupaTaskTypeValue = true
    } else {
      this.selectingCoupaTaskTypeValue = false
    }
  }

  toggleTaskFormSelect(taskType: string): void {
    if (taskType === "form") {
      show(this.taskFormContainerTarget)
      this.taskFormSelectTarget.required = true
    } else {
      hide(this.taskFormContainerTarget)
      this.taskFormSelectTarget.required = false
      this.taskFormSelectTarget.value = ""
    }
  }

  selectingCoupaTaskTypeValueChanged() {
    if (!this.hasStyledSelectOutlet) {
      return
    }

    if (this.selectingCoupaTaskTypeValue) {
      this.styledSelectOutlet.enableInput()
    } else {
      this.styledSelectOutlet.disableInput(false)
    }
  }
}
