const css = `.spendControl-module__container___dByBx {
  position: relative;
  width: 100%;
  max-height: 520px;
  min-height: 520px;
  display: flex;
  flex-direction: column;
}

.spendControl-module__title___U3Ovx {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.25px;
}

.spendControl-module__header___bQE1U {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.spendControl-module__manageConnection___FOpv8 {
  color: #6b63e8;
  cursor: pointer;
}

.spendControl-module__viewUsers___0OiER {
  color: #6b63e8;
  cursor: pointer;
}

.spendControl-module__loader___8-Aaw {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "container": "spendControl-module__container___dByBx",
  "title": "spendControl-module__title___U3Ovx",
  "header": "spendControl-module__header___bQE1U",
  "manageConnection": "spendControl-module__manageConnection___FOpv8",
  "viewUsers": "spendControl-module__viewUsers___0OiER",
  "loader": "spendControl-module__loader___8-Aaw"
};