import { Controller } from "@hotwired/stimulus"
import { parseMaskedFloat } from "../../utils/index"

interface EventListenerObject {
  element: Element
  listener: EventListenerOrEventListenerObject
}

export default class SumLineController extends Controller {
  static targets = [
    "currencySymbol",
    "expenseAmount",
    "expenseLineItemSubTotal",
    "expenseSum",
    "itemLineItemSubTotal",
    "itemLineTotal",
    "itemQuantity",
    "itemRate",
    "itemSum",
    "total",
  ]

  connect(): void {
    this.calculateSums()
    this.updateCurrencySymbol()
  }

  calculateSums(): void {
    this.calculateExpenseSum()
    this.calculateItemSum()
  }

  calculateExpenseSum(): void {
    if (this.hasExpenseAmountTarget === false) {
      return
    }

    const expenseInputs = this.expenseAmountTargets
    const sum = expenseInputs.reduce((acc, input) => acc + (parseMaskedFloat(input.value) || 0), 0)
    this.expenseSumTarget.textContent = sum.toFixed(2)
    this.expenseLineItemSubTotalTarget.textContent = sum.toFixed(2)

    this.updateTotal()
  }

  calculateItemSum(): void {
    let sum = 0

    if (this.hasItemRateTarget === false) {
      return
    }

    this.itemRateTargets.forEach((item: { value: string }, index: string | number) => {
      const quantity = parseFloat(this.itemQuantityTargets[index]?.value) || 0
      const itemValue = parseMaskedFloat(item.value) || 0

      this.itemLineTotalTargets[index].textContent = (quantity * itemValue).toFixed(2)
      sum += quantity * itemValue
    })

    this.itemSumTarget.textContent = sum.toFixed(2)
    this.itemLineItemSubTotalTarget.textContent = sum.toFixed(2)

    this.updateTotal()
  }

  updateTotal(): void {
    // This is to clear the total if there are no items or expenses
    if (!this.hasItemQuantityTarget) {
      this.itemLineItemSubTotalTarget.textContent = 0
      this.itemSumTarget.textContent = 0
    }
    if (!this.hasExpenseAmountTarget) {
      this.expenseLineItemSubTotalTarget.textContent = 0
      this.expenseSumTarget.textContent = 0
    }

    const expenseSum = this.hasExpenseSumTarget ? parseFloat(this.expenseSumTarget.textContent || 0) : 0
    const itemSum = this.hasItemSumTarget ? parseFloat(this.itemSumTarget.textContent || 0) : 0
    const total = expenseSum + itemSum

    this.totalTarget.textContent = total.toFixed(2)
  }

  updateCurrencySymbol(): void {
    if (this.hasItemRateTarget === false) {
      return
    }

    setTimeout(() => {
      this.currencySymbolTargets.forEach((target: HTMLElement) => {
        target.textContent = this.itemRateTargets[0].previousElementSibling?.textContent
      })
    }, 25)
  }
}
