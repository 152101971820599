import { Controller } from "@hotwired/stimulus"
import { pluralize } from "../utils/inflector"

// Connects to data-controller="bulk-actions-count"
export default class extends Controller {
  static targets = ["counterElement"]

  static values = {
    counterDescription: String,
  }

  counterElementTarget: HTMLElement
  hasCounterDescriptionValue: boolean
  counterDescriptionValue: string

  handleRowSelectionFunc: (e) => void

  connect() {
    this.handleRowSelectionFunc = this.handleRowSelection.bind(this)
    document.addEventListener("BulkActions:recordSelected", this.handleRowSelectionFunc)
  }

  handleRowSelection(e) {
    const { _, isSelected } = e.detail

    const currentCount = parseInt(this.counterElementTarget.innerHTML.match(/\d+/)[0])
    let newCount = 0
    if (isSelected) {
      newCount = currentCount + 1
    } else {
      newCount = currentCount - 1
    }

    this.counterElementTarget.innerHTML = this.counterElementTarget.innerHTML.replace(
      `${currentCount.toString()}`,
      newCount.toString(),
    )

    if (this.hasCounterDescriptionValue) {
      let descriptionRegex = new RegExp(String.raw`${this.counterDescriptionValue}s?`)
      this.counterElementTarget.innerHTML = this.counterElementTarget.innerHTML.replace(
        descriptionRegex,
        pluralize(this.counterDescriptionValue, newCount),
      )
    }
  }
}
