import { Controller } from "@hotwired/stimulus"
import { disable, enable } from "../utils"

// Connects to data-controller="document-bulk-edit-form"
export default class extends Controller {
  static targets = ["confirmationButton"]
  static values = {
    supplierSelected: { Boolean, default: false },
    documentsSelected: { Boolean, default: true },
  }

  confirmationButtonTarget: HTMLLinkElement
  hasConfirmationButtonTarget: boolean
  supplierSelectedValue: boolean
  documentsSelectedValue: boolean

  handleSupplierSelectionFunction: (e) => void
  handleDocumentsSelectionFunction: (e) => void

  connect() {
    if (this.hasConfirmationButtonTarget) {
      this.handleSupplierSelectionFunction = this.setSupplierSelected.bind(this)
      window.addEventListener("DocumentBulkEditForm:supplierCompleted", this.handleSupplierSelectionFunction)

      this.handleDocumentsSelectionFunction = this.setDocumentsSelected.bind(this)
      window.addEventListener("DocumentBulkEditForm:documentsSelected", this.handleDocumentsSelectionFunction)

      this.handleConfirmationRequestButton()
    }
  }

  disconnect() {
    window.removeEventListener("DocumentBulkEditForm:supplierCompleted", this.handleSupplierSelectionFunction)
    window.removeEventListener("DocumentBulkEditForm:documentsSelected", this.handleDocumentsSelectionFunction)
  }

  setSupplierSelected(e) {
    this.supplierSelectedValue = e.detail.enable
    this.handleConfirmationRequestButton()
  }

  setDocumentsSelected(e) {
    this.documentsSelectedValue = e.detail.enable
    this.handleConfirmationRequestButton()
  }

  handleConfirmationRequestButton() {
    if (this.documentsSelectedValue && this.supplierSelectedValue) {
      enable(this.confirmationButtonTarget)
      this.confirmationButtonTarget.classList.remove("disabled-link-button")
    } else {
      disable(this.confirmationButtonTarget)
      this.confirmationButtonTarget.classList.add("disabled-link-button")
    }

    // when page has finished loading
    if (document.getElementById("supplier-search_input")) {
      this.copyFormParamsToConfirmationButton()
    }
  }

  copyFormParamsToConfirmationButton() {
    const formQueryParams = new URLSearchParams(new URL(this.documentForm().action).search)
    const confirmButtonUrl = new URL(this.confirmationButtonTarget.href)

    if (this.contractFormValue() === "") {
      formQueryParams.append("supplier_id", this.supplierFormValue())
    } else {
      formQueryParams.append("contract_id", this.contractFormValue())
    }

    let baseUrl = confirmButtonUrl.origin + confirmButtonUrl.pathname
    this.confirmationButtonTarget.href = `${baseUrl}?${formQueryParams.toString()}`
  }

  documentForm(): HTMLElement {
    return document.getElementById("document-update-form")
  }

  supplierFormValue(): string {
    return document.getElementById("supplier-search_input").value.toString()
  }

  contractFormValue(): string {
    return document.getElementById("new_contract_id").value.toString()
  }

  submitForm(e) {
    e.preventDefault()
    this.documentForm().requestSubmit()
  }
}
