import React from "react"

import styles from "./dropdownButton.module.scss"
import CaretUp from "../../../../assets/images/caret_up.svg"
import CaretDown from "../../../../assets/images/caret_down.svg"

interface IProps {
  backgroundColor?: string
  buttonText: string
  expanded: boolean

  clickHandler(boundParam: any)

  style?: React.CSSProperties
  className?: string
  expandedClassName?: string
  disabled?: boolean
  caretColor?: string
}

const DropdownButton: React.FC<IProps> = ({
  buttonText,
  backgroundColor = "",
  clickHandler,
  expanded,
  style,
  className,
  expandedClassName,
  disabled,
  caretColor,
}) => (
  <div
    className={
      expanded ? `${styles.dropdownButton} ${className} ${expandedClassName}` : `${styles.dropdownButton} ${className}`
    }
    onClick={clickHandler}
    style={{ backgroundColor, ...style }}
  >
    <span className={styles.dropdownButtonText}>{buttonText}</span>
    <div style={caretColor ? { color: caretColor } : { color: styles.activePurple }}>
      {!disabled && <>{expanded ? <CaretUp /> : <CaretDown />}</>}
    </div>
  </div>
)

export default DropdownButton
