import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"
import { show, hide } from "../utils"
import { post } from "@rails/request.js"

export default class extends Controller {
  static targets = ["wrapper", "outerWrapper", "body", "actionButton"]

  wrapperTarget: HTMLDivElement
  outerWrapperTarget: HTMLDivElement
  bodyTarget: HTMLDivElement
  actionButtonTarget: HTMLButtonElement

  static values = {
    alertNotice: String,
    blockCloseOnFormSubmit: Boolean,
    shallowMode: { type: Boolean, default: false },
  }

  blockCloseOnFormSubmitValue: boolean
  shallowMode: boolean

  connect() {
    enter(this.wrapperTarget)
    enter(this.bodyTarget)

    if (!this.blockCloseOnFormSubmitValue) {
      document.addEventListener("turbo:submit-end", this.handleSubmit)
    }

    if (this.hasActionButtonTarget) {
      this.actionButtonTarget.focus()
    }
  }

  disconnect() {
    document.removeEventListener("turbo:submit-end", this.handleSubmit)
  }

  alert() {
    if (this.alertNoticeValue) {
      post("/flash", { body: { notice: this.alertNoticeValue }, responseKind: "turbo-stream" })
    }
  }

  close(e) {
    if (this.shallowModeValue) {
      this.hide()
    } else {
      leave(this.wrapperTarget)
      leave(this.bodyTarget).then(() => {
        // Remove the modal element after the fade out so it doesn't blanket the screen
        this.element.remove()
      })

      // Remove src reference from parent frame element
      // Without this, turbo won't re-open the modal on subsequent clicks
      this.element.closest("turbo-frame").src = undefined
    }

    this.dispatch("modal-closed")
  }

  closeAndCloseDrawer(e) {
    const drawerController = this.application.getControllerForElementAndIdentifier(
      document.getElementById("drawer-component"),
      "drawer",
    )
    drawerController.close(e)

    this.close(e)
  }

  handleKeyup(e) {
    if (e.code === "Escape") {
      this.close()
    }
  }

  handleSubmit = (e) => {
    // Some times we only want to block closing a modal on certain actions. This checks
    // for a `keep_modal_open` param and doesn't close if it's present.
    const url = e.detail.fetchResponse?.response?.url

    if (e.detail.success && url && !url.includes("keep_modal_open=true") && !url.includes("keep_modal_open%5D=true")) {
      this.dispatch("modalClosed", { detail: { url: url } })
      this.close()
    }
  }

  show() {
    show(this.outerWrapperTarget)
  }

  hide() {
    hide(this.outerWrapperTarget)
  }
}
