import { FormFieldKind, IFormField, IFormFieldPropertiesWithOptions } from "../../utils/constants"

type FieldSelector = (_: IFormField) => void

export class RequestFormFieldAutoSelector {
  private form: HTMLFormElement
  private formFields: IFormField[]

  static call = (form: HTMLFormElement, formFields: IFormField[]): void => {
    return new RequestFormFieldAutoSelector(form, formFields).call()
  }

  private call = (): void => {
    this.formFields.forEach((ff: IFormField) => {
      const selectField = this.FIELD_VALUE_SELECTORS[ff.kind]
      if (!selectField) return
      selectField(ff)
    })
  }

  private selectVisibleOption = (field: IFormField): void => {
    const input = Array.from(this.form.elements).find((e) => {
      return e.getAttribute("name") === `form[${field.key}]`
    }) as HTMLElement

    input.click()
  }

  private FIELD_VALUE_SELECTORS: { [key in FormFieldKind]: FieldSelector } = {
    RadioButton: this.selectVisibleOption,
  }

  private constructor(form: HTMLFormElement, formFields: IFormField[]) {
    this.form = form
    this.formFields = formFields
  }
}
