import { Trigger } from "./types"

export const humanFileSize = (size: number): string => {
  const i = Math.floor(Math.log(size) / Math.log(1024))
  const fixedSize = +(size / 1024 ** i).toFixed(0)

  return `${fixedSize}${["B", "kB", "MB", "GB", "TB"][i]}`
}

export const getMetaValue = (name: string) => {
  const element = findElement(document.head, `meta[name="${name}"]`)
  if (element) {
    return element.getAttribute("content")
  }
}

export const findElement = (root, selector) => {
  if (typeof root == "string") {
    selector = root
    root = document
  }
  return root.querySelector(selector)
}

export const toArray = (value) => {
  return Array.isArray(value) ? value : [value]
}

export const removeElement = (el) => {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el)
  }
}

export const insert = (el, referenceNode, insertAfter = false) => {
  const sibiling = insertAfter ? referenceNode.nextSibling : referenceNode
  return referenceNode.parentNode.insertBefore(el, sibiling)
}

export const wrapElement = (el, wrapper) => {
  el.parentNode.insertBefore(wrapper, el)
  wrapper.appendChild(el)
}

export const replaceWith = (element, replacementElement): void => {
  element.replaceWith(replacementElement)
}

export const hide = (element): void => {
  element.classList.add("hidden")
}

export const show = (element): void => {
  element.classList.remove("hidden")
}

export const isShown = (element): boolean => !isHidden(element)

export const isHidden = (element): boolean => element.classList.contains("hidden")

export const setRequired = (element: HTMLElement | Array<HTMLElement>): void => {
  let elements = Array.isArray(element) ? element : [element]

  elements.forEach((elem) => elem.setAttribute("required", ""))
}

export const removeRequired = (element: HTMLElement | Array<HTMLElement>): void => {
  let elements = Array.isArray(element) ? element : [element]

  elements.forEach((elem) => elem.removeAttribute("required"))
}

export const toggle = (element): void => {
  element.classList.toggle("hidden")
}

export const disable = (element, className = "disabled"): void => {
  element.setAttribute("disabled", "")
  element.classList.add(className)
}

export const enable = (element): void => {
  element.removeAttribute("disabled")
  element.classList.remove("disabled")
}

export const clearInput = (element: HTMLInputElement): void => {
  element.value = null
}

export const clearSelect = (element: HTMLSelectElement, customIndex = -1): void => {
  element.selectedIndex = customIndex
}

export const isEmpty = (value): boolean => {
  return value == null || value.length === 0
}

export const disableStyledSelect = (element): void => {
  element.setAttribute("disabled", "")
  element.classList.add("disabled")
  element.tomselect.disable()
  let tsWrapper = element.nextSibling
  tsWrapper.setAttribute(
    "style",
    "border-color: rgb(203 213 224/var(--tw-border-opacity)); opacity: 1; background-color: rgb(247 250 252/var(--tw-bg-opacity));",
  )
  let tsControl = tsWrapper.getElementsByClassName("ts-control")[0]
  tsControl.setAttribute("style", "cursor: not-allowed !important; opacity: 1; border-radius: 0.375rem;")
}

export const buttonAsSubmitting = (submitButtonTarget): void => {
  disable(submitButtonTarget)
  submitButtonTarget.innerHTML = "Submitting..."
}

export const buttonAsNotSubmitting = (submitButtonTarget, buttonText = "Submit"): void => {
  enable(submitButtonTarget)
  submitButtonTarget.innerHTML = buttonText
}

export const stringsMatch = (string1, string2, caseInsensitive = false): boolean => {
  return caseInsensitive ? string1.toUpperCase() === string2.toUpperCase() : string1 === string2
}

export const stringMatchInArray = (string, array, caseInsensitive = false): boolean =>
  array.some((arrayItem) => {
    return stringsMatch(arrayItem, string, caseInsensitive)
  })

export const enableStyledSelect = (element): void => {
  element.removeAttribute("disabled")
  element.classList.remove("disabled")
  element.tomselect.enable()
  let tsWrapper = element.nextSibling
  tsWrapper.setAttribute(
    "style",
    "border-color: rgb(203 213 224/var(--tw-border-opacity)); opacity: 1; background-color: inherit;",
  )
  let tsControl = tsWrapper.getElementsByClassName("ts-control")[0]
  tsControl.setAttribute("style", "cursor: pointer; opacity: 1; border-radius: 0.375rem;")
}

export const disableTooltip = (targetEl: HTMLElement) => {
  const tooltipElement = targetEl.parentNode.nextElementSibling

  if (!tooltipElement) {
    return
  }

  tooltipElement.style.display = "none"
}

export const enableTooltip = (targetEl: HTMLElement) => {
  const tooltipElement = targetEl.parentNode.nextElementSibling

  if (!tooltipElement) {
    return
  }

  tooltipElement.style.display = ""
}

export const getSessionStorageValue = (key: string = ""): any => {
  return JSON.parse(sessionStorage.getItem(key))
}

export const setSessionStorageValue = (key: string, value: any): void => {
  sessionStorage.setItem(key, JSON.stringify(value))
}

export const showShallowModeModal = (outerContainer: HTMLElement, application: any): void => {
  const modalContainer = outerContainer.querySelector("#modal-container")
  const modalController = application.getControllerForElementAndIdentifier(modalContainer, "modal")
  modalController.show()
}

export const hideShallowModeModal = (outerContainer: HTMLElement, application: any): void => {
  const modalContainer = outerContainer.querySelector("#modal-container")
  const modalController = application.getControllerForElementAndIdentifier(modalContainer, "modal")
  modalController.hide()
}

export const getVisualBuilderCardDimensions = (isWorkflow: boolean) => ({
  width: 320,
  height: isWorkflow ? 185 : 220,
})

export const getVisualBuilderHandleStyles = (position: string) => ({
  minWidth: "2px",
  minHeight: "2px",
  width: "2px",
  height: "2px",
  background: "transparent",
  border: "none",
  ...(position == "left" ? { left: 0 } : { right: 0 }),
})

export const taskTriggersText = (triggers: Trigger[]): string => {
  const firstTriggerName = triggers[0].name
  if (triggers.length === 1) {
    return firstTriggerName
  } else if (triggers.length === 2) {
    return `${firstTriggerName} + 1 Trigger`
  } else {
    return `${firstTriggerName} + ${triggers.length - 1} Triggers`
  }
}

export const isTurboPreview = () => document.documentElement.hasAttribute("data-turbo-preview")

export const capitalizeFirstLetter = (word): string => {
  const firstLetter = word.charAt(0)
  const capitalizedFirstLetter = firstLetter.toUpperCase()
  const otherLetters = word.slice(1)

  return capitalizedFirstLetter + otherLetters
}

export const parseMaskedFloat = (value: string): number => {
  const sanitizedValue = value.replace(/[^0-9.]/g, "")
  return parseFloat(sanitizedValue)
}
