import ChartController from "../chart_controller"
import { Chart } from "chart.js"

// Connects to data-controller="spend-management--engagement-chart"
export default class extends ChartController {
  static values = {
    type: String,
    datasets: String,
    granularity: String,
  }
  granularityValue: string
  predefinedColors = ["#6B63E8", "#2B285D"]

  chartOptions() {
    const timeFormat = {
      unit: "month",
      displayFormats: {
        month: "MMM",
      },
    }
    return {
      maintainAspectRatio: false,
      fill: {
        target: "origin",
        above: "rgba(107, 99, 232, 0.5)",
      },
      parsing: {
        xAxisKey: "month",
        yAxisKey: "cost",
      },
      pointStyle: "circle",
      pointRadius: 4,
      pointHoverRadius: 5,
      scales: {
        y: {
          grid: {
            color: "#1C375A29",
            drawBorder: false,
          },
          beginAtZero: true,
          ticks: {
            stepSize: 1,
            count: 6,
            maxTicksLimit: 10,
            color: "#6A778A",
            font: {
              size: 14,
            },
            callback: function (val, index) {
              return "$" + val
            },
          },
        },
        x: {
          offset: false,
          grid: {
            display: false,
          },
          type: "time",
          time: timeFormat,
          ticks: {
            color: "#6A778A",
            font: {
              size: 14,
            },
          },
        },
      },
      plugins: {
        tooltip: { enabled: true },
        legend: { position: "bottom" },
      },
    }
  }
}
