const css = `.WorkatoAuthDrawer-module__connectContainer___AGwqJ {
  background: white;
  display: flex;
  padding: 36px 40px;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
}

.WorkatoAuthDrawer-module__drawerHeader___oGBn5 {
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  margin-right: 30px;
}

.WorkatoAuthDrawer-module__subheader___zKy-j {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 20px;
}

.WorkatoAuthDrawer-module__list___Bk2e3 {
  padding-left: 20px;
}

.WorkatoAuthDrawer-module__infoContainer___bIgcf {
  background: #F5F6FA;
  display: flex;
  padding: 36px 40px;
  align-items: center;
  flex-direction: column;
}

.WorkatoAuthDrawer-module__headerRow___7v7VA {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 16px;
}

.WorkatoAuthDrawer-module__activeConnection___YxUB9 {
  color: #35B96F;
  display: flex;
  align-items: center;
}

.WorkatoAuthDrawer-module__greenCircle___I7CRM {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background: #35B96F;
  margin-right: 6px;
}

.WorkatoAuthDrawer-module__collapsedInfoBlock___teVka {
  overflow: hidden;
  position: relative;
  height: 200px;
  width: 100%;
}

.WorkatoAuthDrawer-module__collapsedInfoBlock___teVka::after {
  content: "";
  display: block;
  width: 100%;
  height: 200px;
  position: absolute;
  bottom: 0;
  background: linear-gradient(180deg, rgba(245, 246, 250, 0.3) 0%, #F5F6FA 100%);
}

.WorkatoAuthDrawer-module__openedInfoBlock___y5DPd {
  height: auto;
  width: 100%;
}

.WorkatoAuthDrawer-module__showMore___1cnrx {
  color: #148CFC;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "connectContainer": "WorkatoAuthDrawer-module__connectContainer___AGwqJ",
  "drawerHeader": "WorkatoAuthDrawer-module__drawerHeader___oGBn5",
  "subheader": "WorkatoAuthDrawer-module__subheader___zKy-j",
  "list": "WorkatoAuthDrawer-module__list___Bk2e3",
  "infoContainer": "WorkatoAuthDrawer-module__infoContainer___bIgcf",
  "headerRow": "WorkatoAuthDrawer-module__headerRow___7v7VA",
  "activeConnection": "WorkatoAuthDrawer-module__activeConnection___YxUB9",
  "greenCircle": "WorkatoAuthDrawer-module__greenCircle___I7CRM",
  "collapsedInfoBlock": "WorkatoAuthDrawer-module__collapsedInfoBlock___teVka",
  "openedInfoBlock": "WorkatoAuthDrawer-module__openedInfoBlock___y5DPd",
  "showMore": "WorkatoAuthDrawer-module__showMore___1cnrx"
};