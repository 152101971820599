import React from "react"
import { Handle, Position, useNodes } from "reactflow"
import { getVisualBuilderHandleStyles } from "../../../../utils"
import { useWorkflow } from "./workflowContext"
import { AddFirstTaskButton } from "./addFirstTaskButton"

const noTasks = (nodes) => nodes.filter((node) => node.type === "workflowTaskCard").length === 0

export const subworkflowInitiatedTaskCardNode = ({ data }) => {
  const nodes = useNodes()

  const { cardDimensions, editable } = useWorkflow()
  const containerStyles = {
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.08)",
    width: `${cardDimensions.width}px`,
    height: `${cardDimensions.height}px`,
  }
  const handleStyles = getVisualBuilderHandleStyles("right")

  return (
    <div
      id={`workflow-task-card-${data.taskId}`}
      className="pointer-events-auto z-2 p-4 border border-gray-300 rounded-md bg-gray-50"
      style={containerStyles}
    >
      <>
        <div className="flex flex-col space-y-2">
          <div className="space-y-1">
            <>
              <div className="flex flex-row">
                <div className="truncate small-heading">Subworkflow Initiated</div>
              </div>
              <div className="flex-row pt-2">
                <div className="text-sm font-normal text-gray-600">
                  Subworkflows will be initiated based on their placement in the parent workflow.
                </div>
              </div>
            </>
          </div>
        </div>
        <Handle type="source" position={Position.Right} style={handleStyles} />
        {editable && data.addTaskPathRight && noTasks(nodes) && (
          <AddFirstTaskButton actionPath={data.addTaskPathRight} subworkflow={true} />
        )}
      </>
    </div>
  )
}

export default subworkflowInitiatedTaskCardNode
