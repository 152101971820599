import { FormFieldKind, IFormField, IFormFieldPropertiesWithOptions } from "../../utils/constants"

type ConditionChecker = (f: IFormField) => boolean

const onlyOneOptionIsVisible = (field: IFormField): boolean => {
  const {
    properties: { options },
  } = field as IFormField<IFormFieldPropertiesWithOptions>
  const visibleOptions = options.filter((o) => !o.hidden)

  return visibleOptions.length === 1
}

export class RequestFormAutoSubmitConditionChecker {
  private AUTO_SUBMITABLE_FORM_FIELD_CHECKERS: { [key in FormFieldKind | "unknown_field"]: ConditionChecker } = {
    RadioButton: onlyOneOptionIsVisible,
    unknown_field: (_: IFormField) => false,
  }

  private formFields: IFormField[]

  static canSubmit = (formFields: IFormField[]): boolean => {
    return new RequestFormAutoSubmitConditionChecker(formFields).canSubmit()
  }

  private canSubmit = (): boolean => {
    return this.formFields.every((ff: IFormField) => {
      const canSubmitField =
        this.AUTO_SUBMITABLE_FORM_FIELD_CHECKERS[ff.kind] ?? this.AUTO_SUBMITABLE_FORM_FIELD_CHECKERS.unknown_field

      return canSubmitField(ff)
    })
  }

  private constructor(formFields: IFormField[]) {
    this.formFields = formFields
  }
}
