const css = `.drawer-module__sideDrawer___qvNsl {
  height: 100%;
  background: white;
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  width: 40%;
  z-index: 200;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  transition: all 0.3s ease-out;
}

.drawer-module__open___SMBVY {
  transform: translateX(0);
}

.drawer-module__backdrop___t4HNY {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 21;
  transition: opacity 0.3s;
  opacity: 1;
}

.drawer-module__hideBackdrop___b9oFS {
  opacity: 0;
  transition: all 0.3s;
  visibility: hidden;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "sideDrawer": "drawer-module__sideDrawer___qvNsl",
  "open": "drawer-module__open___SMBVY",
  "backdrop": "drawer-module__backdrop___t4HNY",
  "hideBackdrop": "drawer-module__hideBackdrop___b9oFS"
};