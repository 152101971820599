import * as Sentry from "@sentry/browser"
import "@hotwired/turbo-rails"
import "./controllers"
import "./react"
import "vanilla-nested"
import "./trix"
import "@rails/actiontext"
import Chart from "chart.js/auto"
import "chartjs-adapter-date-fns"
import "./src/new_tab_handler"
import "./src/missing_frame_error"

import LocalTime from "local-time"
LocalTime.config.i18n["en"]["date"]["on"] = "{date}" // modifies default - on: "on {date}"
LocalTime.start()

import jstz from "jstimezonedetect"
document.cookie = "tropic_time_zone=" + jstz.determine().name() + ";SameSite=Lax; path=/"

import { startTestBox } from "@testboxlab/browser"
if (window.ENV && ["demo", "development"].includes(window.ENV)) {
  startTestBox({ allowFullStory: false })
}

Sentry.init({
  enabled: window.ENV && !["development", "test"].includes(window.ENV),
  environment: window.ENV,
  release: window.RELEASE,
  dsn: "https://dd0272aa61ab440f8959957f1256c261@o355317.ingest.sentry.io/6044355",
  replaysSessionSampleRate: 0.0, // Percent of replays collected outside of errors
  replaysOnErrorSampleRate: 0.1, // Percent of replays collected when a frontend error occurs
  ignoreErrors: ["web_widget"],
  integrations: [
    Sentry.replayIntegration({
      // blockAllMedia: true,
    }),
  ],
})
