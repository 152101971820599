export type SpendControlProps = {
  supplier: Supplier
  organization: Organization
  supplierDescription: string
  directLinkToken: string
  workatoConnection: WorkatoConnection
  contract: Contract
  employeeProfilesActivityPath: String
  openEmployeesViewDrawerOnRender: boolean
  ssoSupplier: PresentMetricAttrs
}

export type ActiveUsers = {
  activeUsers: number
  date: Date
  userType: number
  groupedUsage: number
  dateString: string
}
export type GraphFilters = {
  granularity: "week" | "month"
  daysBack: number | null
}

export type PresentMetricAttrs = {
  isDirect: boolean
  supplierName: string
  supplierSlug: string
}

export type PresentMetric = {
  is_direct: boolean
  supplier_name: string
  supplier_slug: string
}

export type ColorMap = Record<string, string>
export type DataKeys = Record<string, string>
export type BarsOrder = Record<string, number>
export type EmployeeTableColumnList = Array<{ displayText: string; key: string; tooltipText: string }>

export type UsageMetricsState = {
  activeUsers: ActiveUsers[]
  barsOrder: BarsOrder
  dataKeys: DataKeys
  colorMap: ColorMap
  title: string
  ssoProvider: string | null
  presentMetrics: PresentMetric[]
}

export type WorkatoConnection = {
  id: string | undefined
  active: boolean
}

export type Organization = {
  id: string
  name: string
  slug: string
  display_name: string
}

export type Supplier = {
  id: string
  name: string
  display_name: string
  spend_control_description: string
  slug: string
}

export type Contract = {
  id: string
}

export type Employee = {
  name: string
  email: string
  lastActive: string
  status: string
  userType: number
  employeeID: string
}

export const FEATURE_URL = "/orgs/tropic/feature_requests" // TODO change vendors to suppliers

export const getWorkatoConnectionURL = ({ organizationSlug, connectionId }) =>
  `/orgs/${organizationSlug}/workato_connections/${connectionId}`

type MetricUrlType = "aggregated" | "employees"
export const getMetricDataURL = (organizationSlug, supplierSlug, metricType: MetricUrlType) =>
  `/orgs/${organizationSlug}/supplier/${supplierSlug}/metrics/activity/${metricType}`

export const getFeatureRequestURL = (contractId: string, organizationSlug: string) =>
  `/orgs/${organizationSlug}/feature_requests/spend_control.json?contract_id=${contractId}`

export const postFeatureRequestURL = (organizationSlug: string) => `/orgs/${organizationSlug}/feature_requests.json`
