import React, { Dispatch, SetStateAction } from "react"
import PlusIcon from "../../../../../assets/images/plus_icon.svg"
import CheckMark from "../../../../../assets/images/checkmark_green.svg"
import styles from "./DataUnavailable.module.scss"

interface IProps {
  isAvailableToConnect: boolean
  setIsOpenDrawer: Dispatch<SetStateAction<boolean>>
  isSubscribed: boolean
  supplierID: string
  supplierName: string
  onSubscribeClick: () => void
}

const DataUnavailable: React.FC<IProps> = ({
  isSubscribed,
  isAvailableToConnect,
  setIsOpenDrawer,
  supplierName,
  onSubscribeClick,
}) => {
  const getContent = () => {
    if (isAvailableToConnect) {
      return (
        <>
          <div className={styles.phrase}>
            See how much <span className={styles.strong}>{supplierName}</span> is being used by your company
          </div>

          <div className={styles.buttonWrapper}>
            <button type="button" onClick={() => setIsOpenDrawer(true)} className={styles.button}>
              <PlusIcon />
              <div className={styles.paddingLeft}>Connect</div>
            </button>
          </div>
        </>
      )
    }

    if (!isSubscribed) {
      return (
        <>
          <div className={styles.phrase}>
            See how much <span className={styles.strong}>{supplierName}</span> is being used by your company
          </div>

          <div className={styles.buttonWrapper}>
            <button onClick={onSubscribeClick} type="button" className={styles.button}>
              <PlusIcon />
              <div className={styles.paddingLeft}>Notify me when available</div>
            </button>
          </div>
        </>
      )
    }

    if (isSubscribed) {
      return (
        <div className={styles.confirmationMessage}>
          <CheckMark />
          <div className={styles.paddingLeft}>
            You will be notified when the <span className={styles.strong}>{supplierName}</span> integration is available
          </div>
        </div>
      )
    }
    return null
  }

  return (
    <div className={styles.graph}>
      <div className={styles.absoluteCentered}>{getContent()}</div>
    </div>
  )
}

export default DataUnavailable
