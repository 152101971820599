import React, { useMemo, useState, useEffect, useCallback } from "react"
import { Puff as Loader } from "react-loader-spinner"
import { PresentMetric, SpendControlProps, WorkatoConnection } from "../../../utils/spendControlConstants"
import DataAvailable from "./DataAvailable/DataAvailable"
import DataUnavailable from "./DataUnavailable/DataUnavailable"
import useGraphData from "../../hooks/useGraphData"
import styles from "./spendControl.module.scss"
import WorkatoAuthDrawer from "./WorkatoAuthDrawer/WorkatoAuthDrawer"
import useFeatureRequest from "../../hooks/useFeatureRequest"
import GoogleSocialUsersDrawer from "./GoogleSocialUsersDrawer/GoogleSocialUsersDrawer"
import Dropdown from "../dropdown/dropdown"

const SpendControl = ({
  supplier,
  supplierDescription,
  organization,
  directLinkToken,
  workatoConnection,
  contract,
  employeeProfilesActivityPath,
  openEmployeesViewDrawerOnRender,
  ssoSupplier,
}: SpendControlProps) => {
  const [isOpenConnectionDrawer, setIsOpenConnectionDrawer] = useState(false)
  const [isOpenUsersDrawer, setIsOpenUsersDrawer] = useState(false)
  const [isOpenGoogleSocialUsersDrawer, setIsOpenGoogleSocialUsersDrawer] = useState(false)
  const defaultSsoSupplier: PresentMetric = ssoSupplier
    ? {
        is_direct: ssoSupplier.isDirect,
        supplier_name: ssoSupplier.supplierName,
        supplier_slug: ssoSupplier.supplierSlug,
      }
    : null
  const {
    usageMetricsState,
    updateGraphData,
    graphFilters,
    isLoading,
    googleSocialData,
    googleDataLoading,
    selectedDataSource,
    setSelectedDataSource,
  } = useGraphData({ supplier, organization, defaultSsoSupplier })
  const [connection, setConnection] = useState<WorkatoConnection>(workatoConnection)

  const {
    isSubscribed,
    isLoading: localLoading,
    onSubscribeClick,
  } = useFeatureRequest({ contractId: contract.id, organization })

  const showUsers = useMemo(() => {
    if (connection?.active && usageMetricsState.activeUsers.length > 0) {
      return true
    }
    return !connection?.active && usageMetricsState.ssoProvider && usageMetricsState.activeUsers?.length > 0
  }, [connection, usageMetricsState])

  const handleOpenUsersDrawer = () => {
    if (usageMetricsState.ssoProvider === "google") {
      setIsOpenGoogleSocialUsersDrawer(true)
    } else {
      setIsOpenUsersDrawer(true)
    }
  }

  const isAvailableToConnect = Boolean(connection?.id)

  const viewEmployeesRefCallback = useCallback((node) => {
    if (node !== null && openEmployeesViewDrawerOnRender) {
      node.click()
    }
  }, [])

  const getContent = () => {
    if (showUsers || connection?.active) {
      return (
        <DataAvailable
          {...usageMetricsState}
          setIsOpenConnectionDrawer={setIsOpenConnectionDrawer}
          graphFilters={graphFilters}
          updateGraphData={updateGraphData}
          isAvailableToConnect={isAvailableToConnect}
          googleSocialData={googleSocialData}
          setIsOpenGoogleSocialUsersDrawer={setIsOpenGoogleSocialUsersDrawer}
          workatoConnection={workatoConnection}
        />
      )
    }

    return (
      <DataUnavailable
        isSubscribed={isSubscribed}
        setIsOpenDrawer={setIsOpenConnectionDrawer}
        isAvailableToConnect={isAvailableToConnect}
        supplierName={supplier.name}
        supplierID={supplier.id}
        onSubscribeClick={onSubscribeClick}
      />
    )
  }

  return (
    <div className={styles.container} id={"spendControlContainer"}>
      {googleDataLoading || localLoading || isLoading ? (
        <div className={styles.loader}>
          <Loader type="Puff" color="#148cfc" height={100} width={100} />
        </div>
      ) : (
        <>
          <WorkatoAuthDrawer
            isOpen={isOpenConnectionDrawer}
            onClose={() => setIsOpenConnectionDrawer(false)}
            token={directLinkToken}
            connection={connection}
            setConnection={setConnection}
            supplier={supplier}
            supplierDescription={supplierDescription}
            organization={organization}
          />

          <GoogleSocialUsersDrawer
            googleSocialData={googleSocialData}
            supplier={supplier}
            isOpen={isOpenGoogleSocialUsersDrawer}
            onClose={() => setIsOpenGoogleSocialUsersDrawer(false)}
          />

          <div className={styles.header}>
            <div className={styles.title}>Active Usage Over Time</div>
            {showUsers && (
              <div className={"flex items-center"}>
                {usageMetricsState.presentMetrics.length > 0 && (
                  <div className={"flex"}>
                    <div className={"flex items-center"}>Data source</div>
                    <Dropdown
                      displayText={`${selectedDataSource?.supplier_name} ${
                        selectedDataSource?.is_direct ? "(Direct)" : ""
                      }`}
                      contentStyle={{ width: 200 }}
                      style={{ minWidth: 120 }}
                      buttonClassName={"button-secondary h-7"}
                      className={"mr-4 ml-2"}
                      dropdownClassName={"border border-gray-300 bg-white shadow-lg rounded-md"}
                    >
                      {usageMetricsState.presentMetrics.map((item) => (
                        <div
                          key={item.supplier_slug}
                          onClick={() => setSelectedDataSource(item)}
                          style={{ fontWeight: selectedDataSource.supplier_slug === item.supplier_slug ? 500 : 400 }}
                        >
                          {item.supplier_name} {item.is_direct ? "(Direct)" : ""}
                        </div>
                      ))}
                    </Dropdown>
                  </div>
                )}
                <a
                  data-turbo-frame="drawer"
                  ref={viewEmployeesRefCallback}
                  href={
                    selectedDataSource && selectedDataSource.is_direct === false
                      ? employeeProfilesActivityPath + "?sso_supplier_slug=" + selectedDataSource.supplier_slug
                      : employeeProfilesActivityPath
                  }
                  className={styles.manageConnection}
                >
                  View employees
                </a>
              </div>
            )}
          </div>
          {getContent()}
        </>
      )}
    </div>
  )
}

export default SpendControl
