import { Controller } from "@hotwired/stimulus"
import { isEmpty, setSessionStorageValue, getSessionStorageValue } from "../utils"

// Connects to data-controller="bulk-actions"
export default class extends Controller {
  static targets = ["form", "bulkActionBtn"]

  static values = {
    selectionHeaderId: String,
    preselectFromUrl: { type: Boolean, default: false },
    isSingleSelect: { type: Boolean, default: false },
    highlightAvatar: { type: Boolean, default: false },
    rowColor: { type: String, default: "bg-purple-100" },
    recordIdParam: { type: String, default: "record_id" },
    recordIdsSetYetParam: { type: String, default: "is_bulk_records_set" },
  }

  formTarget: HTMLFormElement
  bulkActionBtnTarget: HTMLLinkElement

  hasBulkActionBtnTarget: boolean
  preselectFromUrlValue: boolean
  isSingleSelectValue: boolean
  rowColorValue: string
  highlightAvatarValue: boolean
  formChangeUrlValue: string
  recordIdParamValue: string
  recordIdsSetYetParamValue: string

  checkToggleStatusFunc: (e) => void

  connect() {
    this.checkToggleStatusFunc = this.checkToggleStatus.bind(this)
    this.allToggles.forEach((toggle) => toggle.addEventListener("change", this.checkToggleStatusFunc))

    if (isEmpty(getSessionStorageValue(this.recordIdsSetYetParamValue))) {
      setSessionStorageValue(this.recordIdsSetYetParamValue, "true")
      if (this.preselectFromUrlValue) {
        let queryParams = new URLSearchParams(new URL(window.location.href).search)
        setSessionStorageValue(this.recordIdParamValue, Array.from(queryParams.getAll("record_id[]")))
      } else {
        setSessionStorageValue(this.recordIdParamValue, [])
      }
    }

    if (this.preselectFromUrlValue && this.togglesInThisTable().length > 0) {
      this.preselectTogglesAndShowHeader()
    }

    this.checkStatus()
    this.updateRows()
  }

  disconnect() {
    this.allToggles.forEach((toggle) => toggle.removeEventListener("change", this.checkToggleStatusFunc))
  }

  preselectTogglesAndShowHeader(): void {
    this.togglesInThisTable().forEach((toggle: HTMLInputElement) => {
      if (getSessionStorageValue(this.recordIdParamValue).includes(this.getRecordId(toggle))) {
        toggle.checked = true
        this.updateRows()
      }
    })

    this.showOrHideHeader(this.totalSelectionFromSession())
  }

  totalSelectionFromSession(): number {
    return getSessionStorageValue(this.recordIdParamValue).length
  }

  toggleAllRecordsInGroup(e): void {
    const { allUnchecked, someChecked } = this.checkedToggleStatusInGroup(e.target.id)
    const shouldCheckToggle = allUnchecked || someChecked
    e.target.checked = shouldCheckToggle
    this.togglesInThisGroup(e.target.id).forEach((toggle) => {
      toggle.checked = shouldCheckToggle
    })
    if (shouldCheckToggle) {
      e.target.indeterminate = false
    }
    this.checkToggleStatus(e)
    this.selectRecord(e)
  }

  toggleAllRecords(e): void {
    const { allUnchecked, someChecked } = this.checkedToggleStatus()

    const shouldCheckToggle = allUnchecked || someChecked

    this.togglesInThisTable().forEach((toggle) => {
      toggle.checked = shouldCheckToggle
    })
    this.allGroupToggles().forEach((toggle) => {
      toggle.indeterminate = false
      toggle.checked = shouldCheckToggle
    })

    this.checkToggleStatus(e)
    this.selectRecord(e)
  }

  checkToggleStatus(e): void {
    if (!this.element.contains(e.srcElement)) {
      this.uncheckAllToggles()
    } else {
      // set select-all toggle state if one is present
      this.checkStatus()
      if (e.params.group) {
        this.checkStatusInGroup(e.params.group)
      }
    }
  }

  checkStatus() {
    const selectAllToggle = this.selectAllToggleInThisTable()
    if (selectAllToggle) {
      const { allChecked, someChecked } = this.checkedToggleStatus()

      if (someChecked) {
        selectAllToggle.indeterminate = true
      } else {
        selectAllToggle.indeterminate = false
        selectAllToggle.checked = allChecked
      }
    }
  }

  checkStatusInGroup(group) {
    const selectAllToggle = this.selectAllToggleInThisGroup(group)
    if (selectAllToggle) {
      const { allChecked, someChecked } = this.checkedToggleStatusInGroup(group)

      if (someChecked) {
        selectAllToggle.indeterminate = true
      } else {
        selectAllToggle.indeterminate = false
        selectAllToggle.checked = allChecked
      }
    }
  }

  checkedToggleStatus() {
    const toggles = this.togglesInThisTable()
    const checkedCount = this.allCheckedTogglesInThisTable().length
    const allChecked = toggles.length !== 0 && checkedCount === toggles.length
    const allUnchecked = checkedCount === 0
    const someChecked = !allChecked && !allUnchecked

    return {
      allChecked,
      allUnchecked,
      someChecked,
    }
  }

  checkedToggleStatusInGroup(group) {
    const toggles = this.togglesInThisGroup(group)
    const checkedCount = this.allCheckedTogglesInThisGroup(group).length
    const allChecked = toggles.length !== 0 && checkedCount === toggles.length
    const allUnchecked = checkedCount === 0
    const someChecked = !allChecked && !allUnchecked

    return {
      allChecked,
      allUnchecked,
      someChecked,
    }
  }

  uncheckAllToggles(): void {
    const selectAllToggle = this.selectAllToggleInThisTable()
    if (selectAllToggle == null) {
      return
    }

    selectAllToggle.indeterminate = false
    selectAllToggle.checked = false

    const bulkSelectionHeader = this.selectionHeaderOverride() || this.selectionHeaderInThisTable()
    const tableHeader = document.querySelector("#table-header")
    const scopeBar = document.querySelector("#table-scope-bar")
    if (bulkSelectionHeader != null) {
      bulkSelectionHeader.classList.add("hidden")
      tableHeader?.classList.remove("hidden")
      scopeBar?.classList.remove("hidden")
    }

    this.togglesInThisTable().forEach((toggle: HTMLInputElement) => {
      toggle.checked = false
      if (!isEmpty(getSessionStorageValue(this.recordIdsSetYetParamValue))) {
        let newIds = getSessionStorageValue(this.recordIdParamValue).filter((id) => id != this.getRecordId(toggle))
        setSessionStorageValue(this.recordIdParamValue, newIds)
      }
      this.removeRowColor(toggle)
    })
    this.allGroupToggles().forEach((toggle) => {
      toggle.checked = false
      toggle.indeterminate = false
    })
  }

  selectRecord(e): void {
    this.checkToggleStatus(e)
    this.showOrHideHeader()
    this.updateRows()

    if (!isEmpty(getSessionStorageValue(this.recordIdsSetYetParamValue))) {
      let selectedIds = getSessionStorageValue(this.recordIdParamValue)
      if (e.target.checked) {
        selectedIds.push(this.getRecordId(e.target))
        setSessionStorageValue(this.recordIdParamValue, selectedIds)
      } else {
        let newIds = selectedIds.filter((id) => id != this.getRecordId(e.target))
        setSessionStorageValue(this.recordIdParamValue, newIds)
      }
    }

    this.updateBulkActionUrl()

    document.dispatchEvent(
      new CustomEvent("BulkActions:recordSelected", {
        detail: { recordId: this.getRecordId(e.target), isSelected: e.target.checked },
      }),
    )
  }

  selectGroup(): void {
    const allUncheckedGroupToggles = this.allUncheckedGroupToggles()
    allUncheckedGroupToggles.forEach((toggle) => {
      toggle.checked = true
    })
  }

  allGroupToggles(): HTMLInputElement[] {
    return Array.from(this.element.querySelectorAll(".group-select-all")).filter((el) => !el.disabled)
  }

  allUncheckedGroupToggles(): HTMLInputElement[] {
    return this.allGroupToggles().filter((toggle) => !toggle.checked)
  }

  showOrHideHeader(totalSelected = null): void {
    const bulkSelectionHeader = this.selectionHeaderOverride() || this.selectionHeaderInThisTable()
    const tableHeader = document.querySelector("#table-header")
    const scopeBar = document.querySelector("#table-scope-bar")
    if (bulkSelectionHeader == null) {
      return
    }

    if (isEmpty(totalSelected)) {
      if (this.isSingleSelectValue) {
        totalSelected = this.totalSelectionFromSession()
      } else {
        totalSelected = this.allCheckedTogglesInThisTable().length
      }
    }

    if (totalSelected > 0) {
      bulkSelectionHeader.classList.remove("hidden")
      tableHeader?.classList.add("hidden")
      scopeBar?.classList.add("hidden")
      this.updateRecordCount(bulkSelectionHeader, totalSelected)
    } else {
      bulkSelectionHeader.classList.add("hidden")
      tableHeader?.classList.remove("hidden")
      scopeBar?.classList.remove("hidden")
    }
  }

  updateRecordCount(header, length): void {
    const noun = length > 1 ? "items" : "item"
    header.getElementsByTagName("p")[0].innerHTML = `${length} ${noun} selected`
  }

  updateRows(): void {
    this.togglesInThisTable().forEach((toggle: HTMLInputElement) => {
      if (toggle.checked) {
        this.setRowColor(toggle)
      } else {
        this.removeRowColor(toggle)
      }
    })
  }

  updateBulkActionUrl(): void {
    if (this.hasBulkActionBtnTarget) {
      const url = new URL(this.bulkActionBtnTarget.href)
      const queryParams = new URLSearchParams(url.search)

      queryParams.delete("record_id[]")
      this.selectedRecordIds.forEach((recordId) => {
        if (!queryParams.has("record_id[]", recordId)) {
          queryParams.append("record_id[]", recordId)
        }
      })

      const baseUrl = url.origin + url.pathname
      this.bulkActionBtnTarget.href = `${baseUrl}?${queryParams.toString()}`
    }
  }

  submitBulkAction(ev): void {
    ev.preventDefault()
    const recordIds = this.selectedRecordIds

    const input = document.createElement("input")
    input.type = "hidden"
    input.name = "ids"
    input.value = recordIds
    this.formTarget.appendChild(input)

    setSessionStorageValue(this.recordIdParamValue, [])
    setSessionStorageValue(this.recordIdsSetYetParamValue, null)

    this.formTarget.requestSubmit()
  }

  setRowColor(toggle): void {
    const row = toggle.closest(".table-row")
    const bulkBackground = toggle.closest(".bulk-actions-checkbox-background")
    const docViewer = document.getElementById("document_viewer")
    if (bulkBackground != null) {
      bulkBackground.classList.remove("document-thumbnail-container-unselected")
      bulkBackground.classList.add("document-thumbnail-container-selected")
    } else if (docViewer != null) {
      toggle.closest("div").classList.add(this.rowColorValue)
      docViewer.classList.add(this.rowColorValue)
    } else if (this.highlightAvatarValue) {
      const initialsAvatar = row.querySelector(".initials-avatar")
      initialsAvatar?.classList?.remove("bg-gray-300")
      initialsAvatar?.classList?.add("bg-purple-500", "text-white")
    } else {
      row.classList.add(this.rowColorValue)
    }
  }

  removeRowColor(toggle): void {
    const row = toggle.closest(".table-row")
    const bulkBackground = toggle.closest(".bulk-actions-checkbox-background")
    const docViewer = document.getElementById("document_viewer")
    if (bulkBackground != null) {
      bulkBackground.classList.add("document-thumbnail-container-unselected")
      bulkBackground.classList.remove("document-thumbnail-container-selected")
    } else if (docViewer != null) {
      toggle.closest("div").classList.remove(this.rowColorValue)
      docViewer.classList.remove(this.rowColorValue)
    } else if (this.highlightAvatarValue) {
      const initialsAvatar = row.querySelector(".initials-avatar")
      initialsAvatar?.classList?.remove("bg-purple-500", "text-white")
      initialsAvatar?.classList?.add("bg-gray-300")
    } else {
      row.classList.remove(this.rowColorValue)
    }
  }

  get allSelectAllToggles(): NodeListOf<Element> {
    return document.querySelectorAll("#select_all")
  }

  selectAllToggleInThisTable(): HTMLInputElement {
    return this.element.querySelector("#select_all") as HTMLInputElement
  }

  selectAllToggleInThisGroup(group): HTMLInputElement {
    return this.element.querySelector(`#${group}`) as HTMLInputElement
  }

  togglesInThisTable(): HTMLInputElement[] {
    return Array.from(this.element.querySelectorAll(".select-record")).filter((el) => !el.disabled)
  }

  togglesInThisGroup(group): HTMLInputElement[] {
    return Array.from(this.element.querySelectorAll(`.${group}-record`)).filter((el) => !el.disabled)
  }

  get allToggles(): HTMLInputElement[] {
    return Array.from(document.querySelectorAll(".select-record"))
  }

  allCheckedTogglesInThisTable(): HTMLInputElement[] {
    return this.togglesInThisTable().filter((toggle) => toggle.checked)
  }

  allCheckedTogglesInThisGroup(group): HTMLInputElement[] {
    return this.togglesInThisGroup(group).filter((toggle) => toggle.checked)
  }

  get allCheckedToggles(): HTMLInputElement[] {
    return this.allToggles.filter((toggle) => toggle.checked)
  }

  selectionHeaderInThisTable(): HTMLInputElement {
    return this.element.querySelector(".bulk-selection-header")
  }

  selectionHeaderOverride(): HTMLElement | null {
    return document.getElementById(this.selectionHeaderIdValue)
  }

  get allSelectionHeaders(): HTMLCollectionOf<Element> {
    return document.getElementsByClassName("bulk-selection-header")
  }

  get selectedRecordIds(): string[] {
    return this.allCheckedToggles.map((cell: HTMLElement) => {
      return this.getRecordId(cell)
    })
  }

  getRecordId(cell: HTMLElement): string {
    let tableRow = cell.closest(".table-row")

    if (tableRow != null) {
      cell = tableRow
    }
    if (cell) {
      return cell.id.split("_").at(-1)
    }
    return ""
  }
}
