import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="styled-select"
export default class extends Controller {
  static targets = ["applyButton", "dateDisplay", "dateField", "radioButtonField", "valueField"]

  applyButtonTarget: HTMLButtonElement
  dateDisplayTarget: HTMLInputElement
  dateFieldTarget: HTMLInputElement
  radioButtonFieldTargets: HTMLInputElement[]
  valueFieldTarget: HTMLInputElement
  currentRadioButton: HTMLInputElement | undefined
  currentValue: string | undefined
  customRadioButton: HTMLInputElement

  connect = () => {
    this.applyButtonTarget.addEventListener("click", this.setCurrentValueOnValueField)
    this.dateFieldTarget.addEventListener("change", this.storeDateFieldValue)
    this.radioButtonFieldTargets.forEach((radioButton) => {
      radioButton.addEventListener("change", this.storeRadioButtonValue)

      if (radioButton.value === "custom") {
        this.customRadioButton = radioButton
        return
      }

      this.updateRadioButtonDateText(radioButton, radioButton.value)
    })

    this.setCurrentValue(this.radioButtonFieldTargets[0].value)
  }

  disableApplyButton = () => {
    this.applyButtonTarget.classList.add("disabled")
    this.applyButtonTarget.setAttribute("disabled", "disabled")
  }

  disconnect = () => {
    this.applyButtonTarget.removeEventListener("click", this.setCurrentValueOnValueField)
    this.dateFieldTarget.removeEventListener("change", this.storeDateFieldValue)
    this.radioButtonFieldTargets.forEach((radioButton) => {
      radioButton.removeEventListener("change", this.storeRadioButtonValue)
    })
  }

  enableApplyButton = () => {
    this.applyButtonTarget.classList.remove("disabled")
    this.applyButtonTarget.removeAttribute("disabled")
  }

  formatDisplayDate = (date: string): string => new Date(date).toLocaleDateString("en-us", { timeZone: "UTC" })

  formatRadioFieldDate = (date: string): string =>
    new Date(date).toLocaleDateString("en-us", { month: "short", day: "numeric", weekday: "short", timeZone: "UTC" })

  setCurrentValueOnValueField = () => {
    this.valueFieldTarget.value = this.currentValue
    this.dateDisplayTarget.value = this.formatDisplayDate(this.currentValue)
    this.dateDisplayTarget.click()
    this.valueFieldTarget.dispatchEvent(new Event("change"))
  }

  setCurrentValue = (val: string) => {
    this.currentValue = val
    this.toggleApplyButtonVisibility()
  }

  settingCustomDate = () => this.currentRadioButton?.value === "custom"

  storeDateFieldValue = () => {
    this.updateRadioButtonDateText(this.customRadioButton, this.dateFieldTarget.value)

    if (!this.settingCustomDate()) return
    this.setCurrentValue(this.dateFieldTarget.value)
  }

  storeRadioButtonValue = (e: InputEvent) => {
    const radioButton = e.target as HTMLInputElement
    this.currentRadioButton = radioButton

    if (this.settingCustomDate()) {
      this.storeDateFieldValue()
      return
    }

    this.setCurrentValue(radioButton.value)
  }

  toggleApplyButtonVisibility = () => {
    !this.currentValue ? this.disableApplyButton() : this.enableApplyButton()
  }

  updateRadioButtonDateText = (element: HTMLInputElement, date: string) => {
    const subtitle = element.labels[0].querySelector(".suggested-date-field-subtitle") as HTMLSpanElement

    subtitle.innerText = this.formatRadioFieldDate(date)
  }
}
