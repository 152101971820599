import { Controller } from "@hotwired/stimulus"

export default class EditSidebar extends Controller {
  static classes = ["activeSection"]
  static targets = ["sectionDot", "sectionBody"]

  sectionDotTargets: HTMLElement[]
  sectionBodyTargets: HTMLElement[]
  observer: IntersectionObserver
  activeSectionClasses: string[]

  connect() {
    this.observer = new IntersectionObserver(this.onIntersection.bind(this), {
      threshold: 0.1,
    })

    this.sectionDotTargets.forEach((dot: HTMLElement) => {
      const menuItemContainer = dot.parentElement.parentElement
      const sectionElement = document.getElementById(menuItemContainer.dataset.scrollSection)

      this.observer.observe(sectionElement)
    })
  }

  disconnect() {
    this.observer.disconnect()
  }

  smoothScroll(event) {
    event.preventDefault()

    const menuItemContainer = event.target.parentElement.parentElement

    if (!menuItemContainer.dataset.scrollSection) {
      return
    }

    const targetElement = this.sectionBodyTargets.find(
      (section) => section.id === menuItemContainer.dataset.scrollSection,
    )
    this.addActiveClass(event.target.parentElement)
    targetElement.scrollIntoView({ behavior: "smooth" })
  }

  onIntersection(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.sectionDotTargets.forEach((dot: HTMLElement) => {
          dot.parentElement.parentElement.dataset.scrollSection === entry.target.id
            ? this.addActiveClass(dot.parentElement)
            : null
        })
      }
    })
  }

  addActiveClass(target: HTMLElement) {
    const dotElement = target.firstElementChild

    if (dotElement.nodeName !== "SPAN") {
      return
    }

    this.activeSectionClasses.forEach((activeClass: string) => {
      dotElement.classList.add(activeClass)
      dotElement.dataset.activeSection = true
      this.removeActiveClass(dotElement)
    })
  }

  removeActiveClass(activeElement: HTMLElement) {
    this.sectionDotTargets.forEach((dot: HTMLElement) => {
      if (dot !== activeElement) {
        this.activeSectionClasses.forEach((activeClass: string) => {
          dot.classList.remove(activeClass)
          dot.dataset.activeSection = false
        })
      }
    })
  }
}
