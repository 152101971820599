import React, { useState } from "react"
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts"
import styles from "./ActiveUsersGraph.module.scss"

export type ActiveUsers = {
  activeUsers: number
  date: Date
  userType: number
  groupedUsage: number
  dateString: string
}

export type ColorMap = Record<string, string>
export type DataKeys = Record<string, string>
export type BarsOrder = Record<string, number>
interface IProps {
  data: Array<ActiveUsers>
  dataKeys: DataKeys
  barsOrder: BarsOrder
  colorMap: ColorMap
}

const CustomTooltip = ({
  active,
  payload,
  label,
  labelClassName,
  barsOrder,
}: TooltipProps<any, any> & { barsOrder: IProps["barsOrder"] }) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.tooltip}>
        <div className={labelClassName}>{label}</div>
        {payload
          .sort((a, b) => Math.sign(barsOrder[a.dataKey || a.name] - barsOrder[b.dataKey || b.name]))
          .map(({ dataKey, value, color }) => (
            <div key={dataKey} className={styles.tooltipRow}>
              <div className={styles.legendSquare} style={{ backgroundColor: color }} />
              <div>{dataKey}:</div> &nbsp; <span className={styles.tooltipBold}>{value}</span>
            </div>
          ))}
      </div>
    )
  }

  return null
}

const ActiveUsersGraph: React.FC<IProps> = ({ data, dataKeys, barsOrder, colorMap }) => {
  const [disabled, setDisabled] = useState<string[]>([])
  const handleLegendClick = (element: string) => {
    if (disabled.includes(element)) {
      setDisabled(disabled.filter((item) => item !== element))
    } else {
      setDisabled([...disabled, element])
    }
  }

  return (
    <ResponsiveContainer width="100%" className={styles.responsiveContainer}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          left: -35,
          bottom: 30,
        }}
        maxBarSize={45}
      >
        <CartesianGrid stroke="#ECE9F1" vertical={false} />
        <XAxis
          dataKey="datestring"
          tick={{ fontSize: 14, fill: "#B9BDC6", fontWeight: 400 }}
          strokeOpacity={0}
          tickMargin={10}
        />

        <YAxis
          allowDecimals={false}
          axisLine={false}
          tick={{ fontSize: 14, fill: "#B9BDC6", fontWeight: 400 }}
          strokeOpacity={0}
          width={85}
        />
        <Tooltip
          isAnimationActive={false}
          cursor={{ fill: "#F5F4F8" }}
          content={(props) => <CustomTooltip {...props} barsOrder={barsOrder} />}
        />
        <Legend
          wrapperStyle={{
            bottom: 5,
            fontSize: 14,
            color: "#1C1C1C",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            left: 0,
            marginLeft: 16,
          }}
          iconSize={10}
          content={(props) => {
            const { payload } = props
            return (
              <div className={styles.legendRow} style={{ maxWidth: Number(props?.width) - 30 }}>
                {payload
                  ?.sort((a, b) => Math.sign(barsOrder[a.value] - barsOrder[b.value]))
                  .map((entry, index) => (
                    <div
                      className={styles.legendItem}
                      key={`item-${index}`}
                      onClick={() => handleLegendClick(entry.value)}
                    >
                      <div
                        className={styles.legendSquare}
                        style={{ backgroundColor: disabled.includes(entry.value) ? "#DEDEDE" : entry.color }}
                      />
                      <div
                        className={`${styles.legendText} ${disabled.includes(entry.value) && styles.disabledLegend}`}
                      >
                        {entry.value}
                      </div>
                    </div>
                  ))}
              </div>
            )
          }}
        />
        {Object.values(dataKeys)
          .sort((a, b) => Math.sign(barsOrder[b] - barsOrder[a]))
          .map((value) => (
            <Bar key={value} hide={disabled.includes(value)} dataKey={value} stackId="a" fill={colorMap[value]} />
          ))}
      </BarChart>
    </ResponsiveContainer>
  )
}

export default ActiveUsersGraph
