import { Controller } from "@hotwired/stimulus"
import { disable, enable } from "../utils"

// Connects to data-controller="full-screen"
export default class extends Controller {
  static targets = ["fullScreenComponent", "submitButton"]
  COLLAPSED_WIDTH = 72
  EXPANDED_WIDTH = 250

  fullScreenComponentTarget: HTMLElement
  submitButtonTarget: HTMLButtonElement
  toggleButton: HTMLElement | null

  static values = {
    blockCloseOnFormSubmit: { type: Boolean, default: true },
  }

  blockCloseOnFormSubmitValue: boolean
  hasSubmitButtonTarget: boolean

  handleSubmitFunc: (_event: Event) => void
  enableOrDisableSubmitFunc = this.enableOrDisableSubmit.bind(this)

  connect() {
    this.toggleButton = document.getElementById("full-screen-menu-toggle")
    if (this.toggleButton) {
      this.toggleButton.addEventListener("click", this.toggle)
    }

    if (!this.blockCloseOnFormSubmitValue) {
      this.handleSubmitFunc = this.handleSubmit.bind(this)
      document.addEventListener("modal:modalClosed", this.handleSubmitFunc)
    }

    window.addEventListener("FullScreen:submitButtonStatusChanged", this.enableOrDisableSubmitFunc)

    // To avoid the main container spilling outside of a smaller browser window
    // we toggle the min width while the FullScreenComponent is open.
    const mainElement = document.getElementById("main-container")
    if (mainElement == null) return
    mainElement.classList.add("!min-w-min")

    // Hides the canopy header when the FullScreenComponent is open
    const canopyHeader = document.getElementById("canopy-top-header")
    if (canopyHeader == null) return
    canopyHeader.classList.add("hidden")
  }

  disconnect() {
    const mainElement = document.getElementById("main-container")
    if (mainElement == null) return
    mainElement.classList.remove("!min-w-min")

    const canopyHeader = document.getElementById("canopy-top-header")
    if (canopyHeader == null) return
    canopyHeader.classList.remove("hidden")

    if (this.toggleButton) {
      this.toggleButton.removeEventListener("click", this.toggle)
    }

    document.removeEventListener("modal:modalClosed", this.handleSubmit)
    window.removeEventListener("FullScreen:submitButtonStatusChanged", this.enableOrDisableSubmitFunc)
  }

  toggle = () => {
    if (this.toggleButton == null) return

    // Targets sidebar directly as it may not be loaded immediately
    const sideNav = document.getElementById("canopy-sidebar")
    if (sideNav == null) return
    // Checks if the sidebar is collapsed and sets the width of the sidebar
    const isSideNavCollapsed = sideNav.classList.contains("collapsed-canopy-sidebar")
    const sidebarWidth = isSideNavCollapsed ? this.COLLAPSED_WIDTH : this.EXPANDED_WIDTH

    if (this.toggleButton.classList.contains("active")) {
      this.toggleButton.classList.remove("active")
      this.fullScreenComponentTarget.classList.add("z-fullscreen")
      this.fullScreenComponentTarget.style.marginLeft = "0"
      this.fullScreenComponentTarget.style.width = "100%"
    } else {
      this.toggleButton.classList.add("active")

      // Transition Time = .15s This makes the transition smoother
      setTimeout(() => {
        this.fullScreenComponentTarget.classList.remove("z-fullscreen")
        this.fullScreenComponentTarget.style.marginLeft = `${sidebarWidth}px`
        this.fullScreenComponentTarget.style.width = `calc(100% - ${sidebarWidth}px)`
      }, 300)
    }
  }

  close() {
    this.element.remove()
  }

  submit(ev: Event) {
    const form = this.element.closest("form") || this.element.querySelector("form")
    if (form) {
      ev.preventDefault()
      form.requestSubmit()
    }
  }

  enableOrDisableSubmit(e: Event) {
    if (this.hasSubmitButtonTarget) {
      const shouldEnable = e.detail.enable
      if (shouldEnable) {
        enable(this.submitButtonTarget)
        this.submitButtonTarget.classList.remove("disabled-link-button")
      } else {
        disable(this.submitButtonTarget)
        this.submitButtonTarget.classList.add("disabled-link-button")
      }
    }
  }

  handleSubmit = (ev: CustomEvent) => {
    const url = ev.detail.url

    if (!url.includes("keep_full_screen_open=true") && !url.includes("keep_full_screen_open%5D=true")) {
      this.close()
    }
  }
}
