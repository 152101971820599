import { Controller } from "@hotwired/stimulus"
import { debounce } from "lodash"
import { isTextBasedInput } from "../utils/forms"

/*
  Connects to data-controller="form-rerender"

  Essentially, this controller submits a form whenever inputs on that form change so it can be
  re-rendered by the server in some way. Submissions are debounced to prevent them from firing on
  _every_ change if someone is typing quickly. The intent is to handle that button press with a
  server-side re-render of parts of the page (using turbo stream directives) to give a dynamic
  experience using server-side rendering. This can be used to update elements on a form based on
  other values in the form -- to calculate values or replace drop-downs contents.

  The button to be clicked is the one marked as the "btn" target for the controller. If no "btn"
  target is found, the form will be submitted directly -- this will appear in the controller
  as a submit with no "commit" param. Using a "btn" target on a button (hidden or otherwise) gives
  some more control over how the form submits via formaction or formmethod attributs.

  If any elements are declared as "trigger" targets for the controller, only events that target to those
  elements will be considered relevant.

  If an input needs to trigger a rerender manually because it shouldn't be triggered on every keyup and/or
  change event, it's also possible to use the manuallyTriggerRerender function with a specific event trigger.

  The behavior can be triggered in two ways:
  * the form declared as the "form" target for the controller that will automatically have
    triggerRerender to the "input" event on that form
  * by directly invoking the triggerRerender function, by, say by setting the data-action on
    an input to include "blur->form-rerender#triggerRerender"
  * manually triggering a rerender by calling the manuallyTriggerRerender function with a specific event
    trigger

  This controller supports forms that use morphing in their turbo stream responses. The element that is
  currently in focus when the trigger is fired will be marked as "data-turbo-permanent", if it is a text-based
  input, to prevent it from being morphed while the user is interacting with it. Non-text-based inputs will
  be morphed since they won't disrupt a user's interaction with the form and may need adjustments, like being
  disabled / enabled, after interacting with them.

 */
export default class extends Controller {
  // targets
  static targets = ["btn", "trigger", "form"]

  btnTarget: HTMLButtonElement
  hasBtnTarget: boolean
  triggerTargets: Array<HTMLInputElement>
  hasTriggerTarget: boolean
  formTarget: HTMLFormElement
  hasFormTarget: boolean

  connect() {
    if (this.hasFormTarget) {
      this.formTarget.addEventListener("change", this.onChangeHandler)
      this.formTarget.addEventListener("keyup", this.onChangeHandler)
      this.formTarget.addEventListener("turbo:submit-end", this.onSubmitEnd)
      this.formTarget.addEventListener("focusin", this.onFocusInHandler)
      this.formTarget.addEventListener("focusout", this.onFocusOutHandler)
    }
  }

  disconnect() {
    if (this.hasFormTarget) {
      this.formTarget.removeEventListener("change", this.onChangeHandler)
      this.formTarget.removeEventListener("keyup", this.onChangeHandler)
      this.formTarget.removeEventListener("turbo:submit-end", this.onSubmitEnd)
      this.formTarget.removeEventListener("focusin", this.onFocusInHandler)
      this.formTarget.removeEventListener("focusout", this.onFocusOutHandler)
    }
  }

  triggerRerender(e: InputEvent) {
    if (this.allowTriggerRerender(e)) {
      this.setPendingFlag()
      this.debouncedSubmitForm(e.target)
    }
  }
  // pre-generate a bound version, since bind() can't be used in removeEventListener calls as it returns a new func
  onChangeHandler = this.triggerRerender.bind(this)

  manuallyTriggerRerender(e: InputEvent) {
    this.setPendingFlag()
    this.debouncedSubmitForm(e.target)
  }

  // see initialize() for the debounce wrapper config
  submitForm(triggeringInput: HTMLInputElement) {
    if (this.hasBtnTarget) {
      this.btnTarget.value = triggeringInput.name
      this.btnTarget.click()
    } else if (this.hasFormTarget) {
      this.formTarget.requestSubmit()
    }
  }
  // form submission should be de-bounced to limit the number of rapid sends
  debouncedSubmitForm = debounce(this.submitForm.bind(this), 200)

  toggleUnmorphableFlag(force: boolean, e: Event) {
    if (this.allowTriggerRerender(e) && isTextBasedInput(<HTMLElement>e.target)) {
      e.target.toggleAttribute("data-turbo-permanent", force)
    }
  }
  // pre-generate a bound version, since bind() can't be used in removeEventListener calls as it returns a new func
  onFocusInHandler = this.toggleUnmorphableFlag.bind(this, true) // apply true to force param
  onFocusOutHandler = this.toggleUnmorphableFlag.bind(this, false) // apply false to force param

  // this creates a test-visible indication that a rerender is still pending
  togglePendingFlag(force: boolean) {
    if (this.hasFormTarget) {
      this.formTarget.toggleAttribute("data-form-rerender-pending", force)
    }
  }
  // pre-generate a bound version, since bind() can't be used in removeEventListener calls as it returns a new func
  setPendingFlag = this.togglePendingFlag.bind(this, true) // apply true to force param
  onSubmitEnd = this.togglePendingFlag.bind(this, false) // apply false to force param

  allowTriggerRerender(e: InputEvent) {
    return !this.hasTriggerTarget || this.triggerTargets.indexOf(<HTMLInputElement>e.target) >= 0
  }
}
