import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../utils"

// Connects to data-controller="assistant"
export default class extends Controller {
  static targets = ["form", "initialAiMessage", "messageInput", "msgField"]
  formTarget: HTMLFormElement
  initialAiMessageTarget: HTMLDivElement
  messageInputTarget: HTMLInputElement
  msgFieldTarget: HTMLInputElement

  connect() {
    this.formTarget.addEventListener("submit", this.onSubmit)

    document.addEventListener("turbo:render", () => {
      this.messageInputTarget.value = ""
    })
  }

  onSubmit = (event) => {
    event.preventDefault()
    hide(this.initialAiMessageTarget)
    const message = this.messageInputTarget.value.trim()

    if (message) {
      this.messageInputTarget.value = ""
      this.msgFieldTarget.value = message
      this.formTarget.submit()
    }
  }

  triggerExampleQuestion = (event) => {
    event.preventDefault()
    const questionText = event.currentTarget.lastElementChild.innerText
    this.messageInputTarget.value = questionText
    this.formTarget.dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }))
  }
}
