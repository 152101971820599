import { Contract, getFeatureRequestURL, Organization, postFeatureRequestURL } from "../../utils/spendControlConstants"
import { useEffect, useState } from "react"
import { get, post } from "@rails/request.js"

export type Parameter = {
  contractId: Contract["id"]
  organization: Organization
}

const useFeatureRequest = ({ contractId, organization }: Parameter) => {
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const onSubscribeClick = async () => {
    post(postFeatureRequestURL(organization.slug), {
      body: {
        contract_id: contractId,
        feature_request: {
          feature_name: "spend_control",
        },
      },
      contentType: "application/json",
    })
    setIsSubscribed(true)
  }

  const getFeatureRequest = async () => {
    try {
      const response = await get(getFeatureRequestURL(contractId, organization.slug))
      if (response.ok && response.statusCode < 400) {
        const result = await response.json
        setIsSubscribed(Boolean(result?.request_exists))
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    getFeatureRequest().then(() => setIsLoading(false))
  }, [])

  return { isSubscribed, setIsSubscribed, isLoading, onSubscribeClick }
}

export default useFeatureRequest
