import React, { useMemo, useState } from "react"
import styles from "./GoogleSocialUsersDrawer.module.scss"
import CaretDown from "../../../../../assets/images/caret_down.svg"
import Drawer from "../../drawer/drawer"
import { Employee, Supplier } from "../../../../utils/spendControlConstants"
import { compareFunction } from "../../../hooks/useEmployeeActivityData"

interface IProps {
  isOpen: boolean
  onClose: () => void
  googleSocialData: Employee[]
  supplier: Supplier
}

const GoogleSocialUsersDrawer: React.FC<IProps> = ({ isOpen, onClose, googleSocialData, supplier }) => {
  const { data } = googleSocialData
  const [sort, setSort] = useState<{ field: string; direction: "ASC" | "DESC" } | null>({
    field: "email",
    direction: "ASC",
  })

  const updateSort = (field: string) => {
    if (field === sort?.field && sort.direction === "ASC") {
      setSort({ field, direction: "DESC" })
      return
    }
    setSort({ field, direction: "ASC" })
  }
  const sortedData = useMemo(() => {
    if (sort) {
      return [...data].sort((a, b) => {
        if (!a[sort.field]) {
          return sort.direction === "DESC" ? 1 : -1
        }
        if (!b[sort.field]) {
          return sort.direction === "DESC" ? -1 : 1
        }
        return compareFunction(a, b, sort.field, sort.direction)
      })
    }
    return [...data]
  }, [data, sort])

  return (
    <Drawer visible={isOpen} width="750px" drawerStyle={{ overflowX: "hidden" }} onClose={onClose}>
      <div className={styles.infoContainer}>
        <div className={styles.headingRow}>
          <div className={styles.heading}>Employees using {supplier.name}</div>
        </div>
        <div className={styles.numbersRow}>
          <div className={styles.numberColumn}>
            <div className={styles.label}>Total</div>
            <div className={styles.bigNumber}>{googleSocialData.data.length}</div>
          </div>
        </div>
      </div>

      <div className={styles.tableContainer}>
        <div className={styles.tableHeader}>
          <div className={`${styles.flex} ${styles.cursorPointer}`} onClick={() => updateSort("email")}>
            <div className={styles.headerLabel}>Email address</div>
            <div
              className={`${styles.caretWrapper} ${
                sort?.field === "email" && sort?.direction === "DESC" && styles.flipped
              } ${sort?.field === "email" && styles.active}`}
            >
              <CaretDown />
            </div>
          </div>
          <div className={`${styles.flex} ${styles.cursorPointer}`} onClick={() => updateSort("lastActive")}>
            <div className={styles.headerLabel}>Last Logged Activity</div>
            <div
              className={`${styles.caretWrapper} ${
                sort?.field === "lastActive" && sort?.direction === "DESC" && styles.flipped
              } ${sort?.field === "lastActive" && styles.active}`}
            >
              <CaretDown />
            </div>
          </div>
        </div>
        {sortedData.map((employee) => (
          <div key={employee.employeeID + employee.userType} className={styles.userRowWrapper}>
            <div className={styles.userRow}>
              <div className={`${styles.userInfoColumn} ${styles.grow}`}>
                <div className={`${styles.name} ${styles.ellipsis}`}>{employee.name}</div>
                <div className={styles.ellipsis}>{employee.email}</div>
              </div>
              <div className={`${styles.userInfoColumn}`}>
                <div className={styles.ellipsis}>{employee.lastActive}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Drawer>
  )
}

export default GoogleSocialUsersDrawer
