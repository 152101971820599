import { Controller } from "@hotwired/stimulus"
import { show, hide, buttonAsSubmitting, stringMatchInArray } from "../../utils"

// Connects to data-controller="workflows--duplicate-workflow-form"
export default class extends Controller {
  static values = {
    categoryId: String,
    existingWorkflowNames: Array<String>,
    questionRulesQuantity: Number,
    workflowManagedBy: String,
  }
  categoryIdValue: string
  existingWorkflowNamesValue: string[]
  questionRulesQuantityValue: number
  workflowManagedByValue: string

  static targets = [
    "duplicateWorkflowForm",
    "nameField",
    "nameFieldMissingError",
    "nameFieldTakenError",
    "managedByTropic",
    "managedByOrganization",
    "submitButton",
    "requestCategorySelect",
    "workflowTypeAlert",
    "workflowRuleAlert",
  ]

  requestCategorySelectTarget: HTMLSelectElement
  duplicateWorkflowFormTarget: HTMLFormElement
  nameFieldTarget: HTMLInputElement
  nameFieldMissingErrorTarget: HTMLDivElement
  nameFieldTakenErrorTarget: HTMLDivElement
  managedByTropicTarget: HTMLInputElement
  hasManagedByTropicTarget: Boolean
  managedByOrganizationTarget: HTMLInputElement
  hasManagedByOrganizationTarget: Boolean
  submitButtonTarget: HTMLButtonElement
  workflowTypeAlertTarget: HTMLDivElement
  workflowRuleAlertTarget: HTMLDivElement

  connect() {
    if (!this.hasManagedByTropicTarget && this.hasManagedByOrganizationTarget) {
      this.managedByOrganizationTarget.checked = true
      this.activateRadioContainer(this.managedByOrganizationTarget.parentElement)
    }
  }

  onManagedByChange(): void {
    if (this.managedBySelectedValue() === "tropic") {
      this.activateRadioContainer(this.managedByTropicTarget.parentElement)
      this.deactivateRadioContainer(this.managedByOrganizationTarget.parentElement)
    } else {
      this.deactivateRadioContainer(this.managedByTropicTarget.parentElement)
      this.activateRadioContainer(this.managedByOrganizationTarget.parentElement)
    }
    this.toggleWorkflowTypeAlert()
  }

  onRequestCategoryChange(): void {
    this.toggleWorkflowRuleAlert()
  }

  toggleWorkflowRuleAlert(): void {
    if (this.shouldShowWorkflowRuleAlert()) {
      show(this.workflowRuleAlertTarget)
    } else {
      hide(this.workflowRuleAlertTarget)
    }
  }

  shouldShowWorkflowRuleAlert(): boolean {
    return this.categoryIdValue != this.requestCategorySelectTarget.value && this.questionRulesQuantityValue > 0
  }

  managedBySelectedValue() {
    if (this.managedByTropicTarget.checked) {
      return "tropic"
    } else {
      return "organization"
    }
  }

  toggleWorkflowTypeAlert(): void {
    if (this.shouldShowWorkflowTypeAlert()) {
      show(this.workflowTypeAlertTarget)
    } else {
      hide(this.workflowTypeAlertTarget)
    }
  }

  shouldShowWorkflowTypeAlert(): boolean {
    return this.managedBySelectedValue() === "organization" && this.workflowManagedByValue === "tropic"
  }

  submitForm() {
    if (this.duplicateWorkflowFormTarget && this.isFormValid()) {
      buttonAsSubmitting(this.submitButtonTarget)
      this.duplicateWorkflowFormTarget.submit()
    }
  }

  isFormValid() {
    let isValid = true
    this.clearErrors()

    if (this.nameFieldTarget.value === "") {
      this.showError(this.nameFieldMissingErrorTarget, this.nameFieldTarget)
      isValid = false
    }

    if (stringMatchInArray(this.nameFieldTarget.value, this.existingWorkflowNamesValue, true)) {
      this.showError(this.nameFieldTakenErrorTarget, this.nameFieldTarget)
      isValid = false
    }

    return isValid
  }

  clearErrors() {
    this.hideError(this.nameFieldMissingErrorTarget, this.nameFieldTarget)
    this.hideError(this.nameFieldTakenErrorTarget, this.nameFieldTarget)
  }

  showError(errorElement, formElement, borderColor = null) {
    show(errorElement)
    if (borderColor) {
      formElement.setAttribute("style", `border-color: ${borderColor}`)
    } else {
      formElement.classList.add("border-red-500")
    }
  }

  hideError(errorElement, formElement, borderColor = null) {
    hide(errorElement)
    if (borderColor) {
      formElement.setAttribute("style", `border-color: ${borderColor}`)
    } else {
      formElement.classList.remove("border-red-500")
    }
  }

  activateRadioContainer(radioContainer) {
    radioContainer.classList.remove("border-gray-300")

    radioContainer.classList.add("border-purple-500")
    radioContainer.classList.add("bg-purple-100")
  }

  deactivateRadioContainer(radioContainer) {
    radioContainer.classList.add("border-gray-300")

    radioContainer.classList.remove("border-purple-500")
    radioContainer.classList.remove("bg-purple-100")
  }
}
