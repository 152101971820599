const css = `.backdrop-module__backdrop___LDMwj {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: transparent;
  top: 0;
  left: 0;
  z-index: 50;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "backdrop": "backdrop-module__backdrop___LDMwj"
};