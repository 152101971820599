import React, { useEffect } from "react"
import ReactDOM from "react-dom/client"
import { Handle, Position } from "reactflow"
import { AddTaskButton } from "./addTaskButton"
import { EditTaskButton } from "./editTaskButton"
import { getVisualBuilderHandleStyles, taskTriggersText } from "../../../../utils"
import { useWorkflow } from "./workflowContext"
import { Trigger } from "../../../../utils/types"
import { taskState } from "./cardSelectedService"
import clsx from "clsx"
import { get } from "@rails/request.js"

interface SubworkflowTaskCardNodeProps {
  taskId: string
  editPath: string
  taskNameAndTooltip: string | null
  assigneeName: string | null
  assignmentType: string
  triggerCondition: string
  triggers: Trigger[]
  targets: string[]
  ruleBadge: string
  addTaskPathBottom?: string
  addTaskPathRight?: string
  groupLabelName: string | null
  requestStage: string
  subworkflowPlaceholder: boolean | null
  subworkflowToApply: string | null
  creditType: string | null
}

export const SubworkflowTaskCardNode = ({ data }) => {
  const { cardDimensions, editable, selectedTaskId, drawerOpened } = useWorkflow()
  const containerStyles = {
    width: `${cardDimensions.width - 4}px`,
    height: `${cardDimensions.height - 4}px`,
  }
  const iconStyles = {
    color: "white",
  }
  const leftHandleStyles = getVisualBuilderHandleStyles("left")
  const rightHandleStyles = getVisualBuilderHandleStyles("right")

  const isSelected = selectedTaskId === data.taskId

  useEffect(() => {
    if (!isSelected) {
      const workflowTaskCardContainer = document.querySelector(`[data-id="${data.taskId}"]`)
      if (drawerOpened) {
        workflowTaskCardContainer?.classList.add("!pointer-events-none")
      } else {
        workflowTaskCardContainer?.classList.remove("!pointer-events-none")
      }
    }
  }, [drawerOpened])

  const { isEditing, rightButtonSelected, bottomButtonSelected } = taskState({ data })

  const viewTasks = (e) => {
    e.stopPropagation()
    fetch(data.subworkflowVisualizationUrl, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html))
  }

  return (
    <div
      id={`workflow-task-card-${data.taskId}`}
      className={`mt-4 relative rounded-md shadow-md bg-white border border-gray-300 p-4 ${
        isSelected ? "bg-purple-100 border-purple-500 border-2" : ""
      }`}
      style={containerStyles}
    >
      <div
        className={`absolute -top-2 -left-2 rounded-md shadow-md bg-white border border-gray-300 p-4 ${
          isSelected ? "bg-purple-100 border-purple-500 border-2" : ""
        }`}
        style={containerStyles}
      >
        <div
          className={`absolute -top-2 -left-2 rounded-md shadow-md bg-white border border-gray-300 p-4 ${
            isSelected ? "bg-purple-100 border-purple-500 border-2" : ""
          }`}
          style={containerStyles}
        >
          {isSelected && data.editPath && editable && (
            <div>
              <EditTaskButton editPath={data.editPath} taskId={data.taskId} isEditing={isEditing} />
            </div>
          )}
          <Handle type="target" position={Position.Left} style={leftHandleStyles} />
          <div className="flex flex-col">
            <div className="space-y-4">
              <div className="flex flex-row">
                <div className="small-heading truncate" dangerouslySetInnerHTML={{ __html: data.taskNameAndTooltip }} />
              </div>
              <>
                <div className="flex flex-row items-center space-x-2">
                  <div>
                    <div className="flex items-center justify-center w-6 h-6 bg-gray-400 rounded-full">
                      <i className="fa-solid fa-play fa-2xs" style={iconStyles}></i>
                    </div>
                  </div>
                  <div className="text-sm font-normal text-gray-600 truncate">{taskTriggersText(data.triggers)}</div>
                </div>
              </>
            </div>
          </div>
          <div className="flex flex-row items-center text-purple-500 mt-4">
            <i className="fa-solid fa-diagram-subtask fa-sm"></i>
            <div className="pl-1 link-text">
              <a href="#" onClick={viewTasks}>
                View Tasks
              </a>
            </div>
          </div>
          {editable && data.addTaskPathRight && isSelected && (
            <AddTaskButton
              position="right"
              selected={rightButtonSelected}
              actionPath={data.addTaskPathRight}
              taskId={data.taskId}
            />
          )}
          {editable && data.addTaskPathBottom && isSelected && (
            <AddTaskButton
              selected={bottomButtonSelected}
              position="bottom"
              actionPath={data.addTaskPathBottom}
              taskId={data.taskId}
            />
          )}
          <Handle type="source" position={Position.Right} style={rightHandleStyles} />
        </div>
      </div>
    </div>
  )
}
