import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="components--currency-conversion-pill"
export default class extends Controller {
  static values = {
    amount: String,
    baseCurrency: String,
    conversionPath: String,
    effectiveDate: String,
    effectiveDateAttr: String,
    listenEvent: String,
    frameId: String,
    sourceCurrency: String,
  }
  amountValue: string
  baseCurrencyValue: string
  conversionPathValue: string
  effectiveDateValue: string
  effectiveDateAttrValue: string
  frameIdValue: string
  listenEventValue: string
  sourceCurrencyValue: string

  connect() {
    document.addEventListener(this.listenEventValue, this.calculateConversionAmount)

    this.calculateConversionAmount()
  }

  disconnect() {
    document.removeEventListener(this.listenEventValue, this.calculateConversionAmount)
  }

  calculateConversionAmount = (event?: Event) => {
    const amount = event ? event.detail.amount : this.amountValue
    const sourceCurrency = event ? event.detail.currency : this.sourceCurrencyValue
    const query = new URLSearchParams({
      amount,
      base_currency: this.baseCurrencyValue,
      source_currency: sourceCurrency,
      effective_date: this.effectiveDateValue,
      effective_date_attr: this.effectiveDateAttrValue,
      frame_id: this.frameIdValue,
    }).toString()

    get(`${this.conversionPathValue}?${query}`, {
      responseKind: "turbo-stream",
    })
  }
}
