export const CURRENCY_MASK_CONFIG = {
  DEFAULT: { mask: Number, scale: 2, thousandsSeparator: ",", padFractionalZeros: true, radix: "." },
  CLP: { mask: Number, scale: 0, thousandsSeparator: "," },
  HUF: { mask: Number, scale: 0, thousandsSeparator: "," },
  JPY: { mask: Number, scale: 0, thousandsSeparator: "," },
  KRW: { mask: Number, scale: 0, thousandsSeparator: "," },
  VND: { mask: Number, scale: 0, thousandsSeparator: "," },
}

export const currencyMaskConfigFor = (currency) => CURRENCY_MASK_CONFIG[currency] || CURRENCY_MASK_CONFIG["DEFAULT"]

// When setting the value of a masked field, we need to dispatch a "blur"
// event so the mask gets re-applied.
export const updateMaskedInputValue = (el, value) => {
  el.value = value
  el.dispatchEvent(new Event("blur", { bubbles: false }))
}
