import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="radio-button-group-boxed"
export default class extends Controller {
  static targets = ["radioCheckbox"]
  radioCheckboxTargets: HTMLInputElement[]

  onChange(e: Event): void {
    this.radioCheckboxTargets.forEach((target) => this.deactivateRadioContainer(target.parentElement))
    this.activateRadioContainer((e.target as Element).parentElement)
  }

  activateRadioContainer(radioContainer: HTMLElement): void {
    radioContainer.classList.remove("border-gray-300")
    radioContainer.classList.add("border-purple-500", "bg-purple-100")
  }

  deactivateRadioContainer(radioContainer: HTMLElement): void {
    radioContainer.classList.add("border-gray-300")
    radioContainer.classList.remove("border-purple-500", "bg-purple-100")
  }
}
