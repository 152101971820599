import {
  Employee,
  EmployeeTableColumnList,
  getMetricDataURL,
  Organization,
  PresentMetric,
  Supplier,
} from "../../utils/spendControlConstants"
import { useEffect, useMemo, useState } from "react"
import { get } from "@rails/request.js"

export const compareFunction = (a, b, field, direction: "ASC" | "DESC") => {
  if (a[field] === b[field]) {
    return 0
  }
  const sign = direction === "DESC" ? -1 : 1
  if (field === "lastActive") {
    const dateA = new Date(a[field])
    const dateB = new Date(b[field])
    if (dateA.toString() === "Invalid Date") {
      return sign * -1
    }
    if (dateB.toString() === "Invalid Date") {
      return sign
    }
    return dateA > dateB ? sign : sign * -1
  }
  return a[field] > b[field] ? sign : sign * -1
}

export type Params = {
  organization: Organization
  supplier: Supplier
  selectedDataSource: PresentMetric
}

const useEmployeeActivityData = ({ organization, supplier, selectedDataSource }) => {
  const [data, setData] = useState<Employee[]>([])
  const [columnList, setColumnList] = useState<EmployeeTableColumnList>([])
  const [isLoading, setIsLoading] = useState(true)
  const [filters, setFilters] = useState({ daysBack: 30 })
  const [sort, setSort] = useState<{ field: string; direction: "ASC" | "DESC" } | null>({
    field: "lastActive",
    direction: "ASC",
  })

  const [activeUsersCount, inactiveUsersCount] = useMemo(() => {
    return [
      data.filter(({ calculated_status }) => calculated_status === "Active").length,
      data.filter(({ calculated_status }) => calculated_status === "Inactive").length,
    ]
  }, [data])

  const sortedData = useMemo(() => {
    if (sort) {
      return [...data].sort((a, b) => {
        if (!a[sort.field]) {
          return sort.direction === "DESC" ? 1 : -1
        }
        if (!b[sort.field]) {
          return sort.direction === "DESC" ? -1 : 1
        }
        return compareFunction(a, b, sort.field, sort.direction)
      })
    }
    return [...data]
  }, [data, sort])

  const updateFilters = async (time: number) => {
    setFilters({ ...filters, daysBack: time })
  }

  const updateSort = (field: string) => {
    if (field === sort?.field && sort.direction === "ASC") {
      setSort({ field, direction: "DESC" })
      return
    }
    setSort({ field, direction: "ASC" })
  }

  return {
    updateSort,
    sortedData,
    filters,
    updateFilters,
    activeUsersCount,
    inactiveUsersCount,
    columnList,
    isLoading,
    sort,
  }
}

export default useEmployeeActivityData
