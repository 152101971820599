import { useEffect, useState, useRef } from "react"
import {
  getMetricDataURL,
  GraphFilters,
  Organization,
  PresentMetric,
  Supplier,
  UsageMetricsState,
} from "../../utils/spendControlConstants"
import { get, put } from "@rails/request.js"

const initialState = {
  employeeData: [],
  activeUsers: [],
  barsOrder: [],
  dataKeys: [],
  colorMap: [],
  title: "Usage over time",
  presentMetrics: [],
}

const useGraphData = ({
  organization,
  supplier,
  defaultSsoSupplier,
}: {
  organization: Organization
  supplier: Supplier
  defaultSsoSupplier: PresentMetric
}) => {
  const [usageMetricsState, setUsageMetricsState] = useState<UsageMetricsState>(initialState)
  const [graphFilters, setGraphFilters] = useState<GraphFilters>({ granularity: "week" as const })
  const [googleSocialData, setGoogleSocialData] = useState({ data: [] })
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [googleDataLoading, setGoogleDataLoading] = useState<boolean>(true)
  const [selectedDataSource, setSelectedDataSource] = useState<PresentMetric>(defaultSsoSupplier)
  const notInitialRender = useRef(false)
  const notInitialRenderForSelectedDataSource = useRef(false)

  const getData = async () => {
    const response = await get(
      `${getMetricDataURL(organization.slug, supplier.slug, "aggregated")}?granularity=${graphFilters.granularity}` +
        `${
          selectedDataSource && selectedDataSource?.is_direct === false
            ? `&sso_supplier_slug=${selectedDataSource.supplier_slug}`
            : ""
        }`,
    )

    if (response.ok && response.statusCode === 200) {
      const jsonBody = await response.json
      if (jsonBody?.result) {
        const {
          activeUsers = [],
          ssoProvider,
          barsOrder = {},
          dataKeys = {},
          colorMap = {},
          title,
          presentMetrics = [],
        } = jsonBody.result
        await setUsageMetricsState({
          ...usageMetricsState,
          activeUsers,
          ssoProvider,
          barsOrder,
          dataKeys,
          colorMap,
          title,
          presentMetrics,
        })
        if (!selectedDataSource && presentMetrics.length > 0) {
          setSelectedDataSource(presentMetrics[0])
        }
        setIsLoading(false)
        if (ssoProvider !== "google") {
          setGoogleDataLoading(false)
        }
      }
    }
  }

  const updateGraphData = (newFilters: GraphFilters) => {
    setGraphFilters({
      ...graphFilters,
      ...newFilters,
    })
  }

  useEffect(() => {
    if (notInitialRender.current) {
      getData()
    } else {
      notInitialRender.current = true
    }
  }, [graphFilters])

  useEffect(() => {
    if (usageMetricsState.presentMetrics.length > 0) {
      if (notInitialRenderForSelectedDataSource.current) {
        getData()
      } else {
        notInitialRenderForSelectedDataSource.current = true
      }
    }
  }, [selectedDataSource])

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    const exec = async () => {
      const response = await get(`${getMetricDataURL(organization.slug, supplier.slug, "employees")}?days_back=90`)

      if (response.ok && response.statusCode < 400) {
        const jsonBody = await response.json
        if (jsonBody.result) {
          setGoogleSocialData(jsonBody.result)
        }
      }
    }
    exec().finally(() => setGoogleDataLoading(false))
  }, [])

  return {
    usageMetricsState,
    updateGraphData,
    graphFilters,
    isLoading,
    googleSocialData,
    googleDataLoading,
    selectedDataSource,
    setSelectedDataSource,
  }
}

export default useGraphData
