const css = `.GoogleSocialUsersDrawer-module__infoContainer___qzOVC {
  background: #F5F6FA;
  display: flex;
  padding: 36px 40px;
  flex-direction: column;
}

.GoogleSocialUsersDrawer-module__tableContainer___1ls5o {
  display: flex;
  padding: 36px 40px;
  flex-direction: column;
  color: #1c1c1c;
  position: relative;
}

.GoogleSocialUsersDrawer-module__listContainer___2iPne {
  display: flex;
  padding: 36px 40px;
  align-items: center;
  flex-direction: column;
}

.GoogleSocialUsersDrawer-module__heading___BMqU4 {
  color: #1c1c1c;
  font-weight: 500;
  font-size: 28px;
  padding-right: 40px;
}

.GoogleSocialUsersDrawer-module__headingRow___L--0r {
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;
}

.GoogleSocialUsersDrawer-module__numbersRow___FuQrn {
  display: flex;
  margin: 0 -30px;
}

.GoogleSocialUsersDrawer-module__numberColumn___h30jt {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
}

.GoogleSocialUsersDrawer-module__label___D68iD, .GoogleSocialUsersDrawer-module__headerLabel___jIRsq {
  color: #8798AD;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 10px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

.GoogleSocialUsersDrawer-module__bigNumber___9k8uK {
  font-size: 48px;
  color: #1c1c1c;
  line-height: 57px;
  font-weight: 300;
}

.GoogleSocialUsersDrawer-module__tableHeader___DOxnL {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
}

.GoogleSocialUsersDrawer-module__userRowWrapper___Z6Yr- {
  border-bottom: 1px solid rgba(188, 221, 230, 0.4);
}

.GoogleSocialUsersDrawer-module__userRow___Hg7Pm {
  display: flex;
  margin: 0 -16px;
  align-items: center;
  padding: 12px 0;
}

.GoogleSocialUsersDrawer-module__headerLabel___jIRsq {
  margin: 0;
  opacity: 0.6;
  text-transform: uppercase;
  color: #6A778A;
  font-weight: 500;
  font-size: 14px;
}

.GoogleSocialUsersDrawer-module__rowHeader___SDytL, .GoogleSocialUsersDrawer-module__rowHeaderExtended___i-VQW {
  width: 95px;
  padding: 0 16px;
  box-sizing: content-box;
  line-height: 15px;
}

.GoogleSocialUsersDrawer-module__rowHeaderExtended___i-VQW {
  width: 116px;
}

.GoogleSocialUsersDrawer-module__grow___OGUj5 {
  flex-grow: 1;
}

.GoogleSocialUsersDrawer-module__userInfoColumn___abhbK {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.GoogleSocialUsersDrawer-module__rowItem___id1yh, .GoogleSocialUsersDrawer-module__rowItemExtended___DcCu0 {
  min-width: 95px;
  max-width: 95px;
  padding: 0 16px;
  box-sizing: content-box;
}

.GoogleSocialUsersDrawer-module__rowItemExtended___DcCu0 {
  min-width: 115px;
  max-width: 115px;
}

.GoogleSocialUsersDrawer-module__name___eYSEC {
  font-weight: 500;
  font-size: 16px;
}

.GoogleSocialUsersDrawer-module__alignRight___bpWdp {
  text-align: right;
}

.GoogleSocialUsersDrawer-module__ellipsis___m9fLy {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.GoogleSocialUsersDrawer-module__flex___qnJO0 {
  display: flex;
  align-items: center;
}

.GoogleSocialUsersDrawer-module__tooltipContent___pwW96 {
  padding: 10px 20px;
  font-size: 15px;
  line-height: 26px;
}

.GoogleSocialUsersDrawer-module__dropdownItem___kM0Ju, .GoogleSocialUsersDrawer-module__dropDownElement___DE8gQ {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  width: 100%;
}

.GoogleSocialUsersDrawer-module__dropDownElement___DE8gQ {
  font-weight: 400 !important;
}

.GoogleSocialUsersDrawer-module__dropDownElementActive___w8ime {
  font-weight: 500 !important;
}

.GoogleSocialUsersDrawer-module__caretWrapper___6u0FO {
  color: #8798AD;
  margin-left: 6px;
  display: flex;
  align-items: center;
}

.GoogleSocialUsersDrawer-module__cursorPointer___HAma- {
  cursor: pointer;
}

.GoogleSocialUsersDrawer-module__flipped___pl-LH {
  transform: rotate(180deg);
}

.GoogleSocialUsersDrawer-module__active___pDvTw {
  color: #6B63E8;
}

.GoogleSocialUsersDrawer-module__loader___oFv6P {
  display: flex;
  justify-content: center;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "infoContainer": "GoogleSocialUsersDrawer-module__infoContainer___qzOVC",
  "tableContainer": "GoogleSocialUsersDrawer-module__tableContainer___1ls5o",
  "listContainer": "GoogleSocialUsersDrawer-module__listContainer___2iPne",
  "heading": "GoogleSocialUsersDrawer-module__heading___BMqU4",
  "headingRow": "GoogleSocialUsersDrawer-module__headingRow___L--0r",
  "numbersRow": "GoogleSocialUsersDrawer-module__numbersRow___FuQrn",
  "numberColumn": "GoogleSocialUsersDrawer-module__numberColumn___h30jt",
  "label": "GoogleSocialUsersDrawer-module__label___D68iD",
  "headerLabel": "GoogleSocialUsersDrawer-module__headerLabel___jIRsq",
  "bigNumber": "GoogleSocialUsersDrawer-module__bigNumber___9k8uK",
  "tableHeader": "GoogleSocialUsersDrawer-module__tableHeader___DOxnL",
  "userRowWrapper": "GoogleSocialUsersDrawer-module__userRowWrapper___Z6Yr-",
  "userRow": "GoogleSocialUsersDrawer-module__userRow___Hg7Pm",
  "rowHeader": "GoogleSocialUsersDrawer-module__rowHeader___SDytL",
  "rowHeaderExtended": "GoogleSocialUsersDrawer-module__rowHeaderExtended___i-VQW",
  "grow": "GoogleSocialUsersDrawer-module__grow___OGUj5",
  "userInfoColumn": "GoogleSocialUsersDrawer-module__userInfoColumn___abhbK",
  "rowItem": "GoogleSocialUsersDrawer-module__rowItem___id1yh",
  "rowItemExtended": "GoogleSocialUsersDrawer-module__rowItemExtended___DcCu0",
  "name": "GoogleSocialUsersDrawer-module__name___eYSEC",
  "alignRight": "GoogleSocialUsersDrawer-module__alignRight___bpWdp",
  "ellipsis": "GoogleSocialUsersDrawer-module__ellipsis___m9fLy",
  "flex": "GoogleSocialUsersDrawer-module__flex___qnJO0",
  "tooltipContent": "GoogleSocialUsersDrawer-module__tooltipContent___pwW96",
  "dropdownItem": "GoogleSocialUsersDrawer-module__dropdownItem___kM0Ju",
  "dropDownElement": "GoogleSocialUsersDrawer-module__dropDownElement___DE8gQ",
  "dropDownElementActive": "GoogleSocialUsersDrawer-module__dropDownElementActive___w8ime",
  "caretWrapper": "GoogleSocialUsersDrawer-module__caretWrapper___6u0FO",
  "cursorPointer": "GoogleSocialUsersDrawer-module__cursorPointer___HAma-",
  "flipped": "GoogleSocialUsersDrawer-module__flipped___pl-LH",
  "active": "GoogleSocialUsersDrawer-module__active___pDvTw",
  "loader": "GoogleSocialUsersDrawer-module__loader___oFv6P"
};