import React, { Dispatch, SetStateAction, useState } from "react"

import styles from "./WorkatoAuthDrawer.module.scss"
import usePostMessageListener from "../../../hooks/usePostMessageListener"
import Drawer from "../../drawer/drawer"
import { Organization, Supplier, WorkatoConnection } from "../../../../utils/spendControlConstants"

interface IProps {
  isOpen: boolean
  onClose: () => void
  supplier: Supplier
  supplier_description: string
  connection: WorkatoConnection
  setConnection: Dispatch<SetStateAction<WorkatoConnection>>
  token: string
  organization: Organization
}

const WorkatoAuthDrawer: React.FC<IProps> = ({
  isOpen,
  onClose,
  supplier,
  supplierDescription,
  setConnection,
  connection,
  token,
  organization,
}) => {
  const [height, setHeight] = useState("100%")
  const [showMore, setShowMore] = useState(false)

  usePostMessageListener({ setHeight, onClose, connection, setConnection, organization })

  return (
    <Drawer visible={isOpen} width="660px" drawerStyle={{ display: "flex", flexDirection: "column" }} onClose={onClose}>
      <div className={styles.infoContainer}>
        <div className={styles.headerRow}>
          <div className={styles.drawerHeader}>Connect to {supplier.name}</div>
        </div>
        <div className={styles[showMore ? "openedInfoBlock" : "collapsedInfoBlock"]}>
          <div className="trix-content" dangerouslySetInnerHTML={{ __html: supplierDescription }}></div>
        </div>
        <div className={styles.showMore} onClick={() => setShowMore(!showMore)}>
          {!showMore ? "show more" : "show less"}
        </div>
      </div>
      <div className={styles.connectContainer}>
        {token && connection?.connectionId && (
          <iframe
            title="workato"
            src={`https://www.workato.com/direct_link/embedded/connections/${connection.connectionId}?workato_dl_token=${token}`}
            style={{
              height,
              width: "100%",
              border: "none",
            }}
            referrerPolicy="same-origin"
          />
        )}
      </div>
    </Drawer>
  )
}

export default WorkatoAuthDrawer
