import React, { useState } from "react"

import styles from "./dropdown.module.scss"
import Backdrop from "../backdrop/backdrop"
import DropdownButton from "../dropdownButton/dropdownButton"

interface IState {
  optionsVisible: boolean
}

interface IProps {
  displayText: string
  style?: React.CSSProperties
  contentStyle?: React.CSSProperties
  backgroundColor?: string
  caretColor?: string
  buttonClassName?: string
  className?: string
  dropdownClassName: string
  expandedButtonClassName?: string
}

const Dropdown: React.FC<IProps> = ({
  children,
  displayText,
  style,
  contentStyle,
  backgroundColor,
  caretColor,
  buttonClassName,
  className,
  dropdownClassName,
  expandedButtonClassName,
}) => {
  const [optionsVisible, setOptionsVisible] = useState<IState["optionsVisible"]>(false)

  const onClick = (): void => {
    setOptionsVisible(!optionsVisible)
  }

  return (
    <div style={style} className={`${styles.dropdown} ${className ? className : ""}`}>
      <DropdownButton
        backgroundColor={backgroundColor}
        clickHandler={onClick}
        buttonText={displayText}
        expanded={optionsVisible}
        caretColor={caretColor}
        className={buttonClassName}
        expandedClassName={expandedButtonClassName}
      />

      {optionsVisible && (
        <>
          {/*  Below is a backdrop that will trasnparently cover the whole sceen so when we click anywhere on the screen it will trigger the onClick to close the approval list. */}
          <Backdrop onClick={onClick} />
          <div
            className={`${styles.dropdownContent} ${dropdownClassName || ""}`}
            style={contentStyle ? { ...contentStyle } : {}}
            onClick={onClick}
          >
            {children}
          </div>
        </>
      )}
    </div>
  )
}

export default Dropdown
