import React, { useState, useEffect } from "react"

import styles from "./drawer.module.scss"

interface IProps {
  visible: boolean
  width: string
  onClose: () => void
  backdropStyle?: React.CSSProperties
  drawerStyle?: React.CSSProperties
}

interface IProps {}

const Drawer: React.FC<IProps> = ({ visible, onClose, width, children, backdropStyle, drawerStyle }) => {
  const [visibleState, setVisible] = useState(false)
  /*
  This useEffect is what makes the transition work on the first click. We set visibility after the component rendered which triggers
  the translateX transition. WOOP WOOP!
  */
  useEffect(() => {
    setVisible(visible)
  }, [visible])
  return (
    <>
      {/* CONTENT DRAWER */}
      <div
        style={{ width: width || "40%", ...drawerStyle }}
        className={`${styles.sideDrawer} ${visibleState ? styles.open : ""}`}
      >
        {children}
      </div>
      {/* BACKDROP */}
      <div
        style={{ ...backdropStyle }}
        className={`${styles.backdrop} ${visible ? "" : styles.hideBackdrop}`}
        onClick={onClose}
      />
    </>
  )
}

export default Drawer
