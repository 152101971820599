import { Controller } from "@hotwired/stimulus"
import { createPopper, Instance as PopperInstance, Placement, PositioningStrategy } from "@popperjs/core"
import { removeOverlayFromDOM, insertOverlayIntoDOM } from "../utils/overlay"

const TIME_OUT_MS = 200
// Connects to data-controller="popper"
export default class extends Controller {
  static targets = ["element", "dropdown"]
  static values = {
    delay: Boolean,
    placement: { type: String, default: "top" },
    offset: { type: Array, default: [0, 6] },
    overlayId: String,
    overlayClasses: String,
    shouldRenderOverlay: Boolean,
    disallowClicks: Boolean,
    strategy: { type: String, default: "absolute" },
    trigger: String,
    // Un-nests the popper to an upper element in the DOM to
    // prevent issues with z-indexes and overflow: hidden
    unnest: { type: Boolean, default: false },
    unnestTarget: { type: String, default: "body" },
  }

  dropdownTarget: HTMLElement
  elementTarget: HTMLElement
  hasElementTarget: boolean
  hasDropdownTarget: boolean

  delayValue: Boolean
  placementValue: Placement
  offsetValue: number[]
  overlayIdValue: string
  overlayClassesValue: string
  shouldRenderOverlayValue: boolean
  disallowClicksValue: boolean
  strategyValue: string
  triggerValue: string
  unnestValue: boolean
  unnestTargetValue: string

  unnestTarget: Element
  popperInstance: PopperInstance

  popperElementRef: HTMLElement

  timeOut: Function

  connect() {
    this.unnestTarget = this.hasElementTarget ? this.elementTarget : this.element
    this.movePopperToElement()
    this.popperInstance = createPopper(this.unnestTarget, this.popperElement(), {
      placement: this.placementValue,
      modifiers: [
        {
          name: "offset",
          options: {
            offset: this.offsetValue,
            scroll: false,
          },
        },
        {
          name: "preventOverflow",
        },
      ],
      strategy: this.strategyValue as PositioningStrategy,
    })
    if (this.shouldRenderOverlayValue) {
      insertOverlayIntoDOM(this.overlayIdValue, this.overlayClassesValue)
    }
  }

  disconnect() {
    this.movePopperBackToOrigin()
    if (this.popperInstance) {
      this.popperInstance.destroy()
    }
    if (this.shouldRenderOverlayValue) {
      removeOverlayFromDOM(this.overlayIdValue)
    }
  }

  toggle() {
    if (this.popperElement().getAttribute("data-show") === "true") {
      this.popperElement().removeAttribute("data-show")
      if (this.shouldRenderOverlayValue) {
        this.toggleOverlay()
      }
    } else {
      this.show()
    }
  }

  removeActivePopper() {
    document.querySelector("#tooltip[data-show]")?.removeAttribute("data-show")
  }

  show() {
    const handleShow = () => {
      // Hide previous active popper due to delay once a new once is hovered over
      this.removeActivePopper()

      this.popperElement().setAttribute("data-show", "true")
      // We need to tell Popper to update the tooltip position
      // after we show the tooltip, otherwise it will be incorrect
      this.popperInstance.update()
      if (this.shouldRenderOverlayValue) {
        this.toggleOverlay()
      }
    }

    if (this.delayValue) {
      clearTimeout(this.timeOut)
      setTimeout(() => handleShow(), TIME_OUT_MS)
    } else {
      handleShow()
    }
  }

  hide(event) {
    const handleHide = () => {
      if (event && event.type === "click") {
        // Click@window->popper#hide runs on every click. Making sure we want to handle this event.
        if (!this.unnestTarget.contains(event.target) && this.popperElement().getAttribute("data-show")) {
          this.popperElement().removeAttribute("data-show")
          if (this.shouldRenderOverlayValue) {
            this.toggleOverlay()
          }
        }
      } else if (this.triggerValue === "hover" && this.unnestValue && this.popperElement()) {
        // When the tooltip is un-nested it gets moved to an upper element in the DOM.
        // This checks if the tooltip is hovered over, so it prevents it from hiding
        if (!this.popperElement().matches(":hover")) {
          this.popperElement().removeAttribute("data-show")
        }
      } else {
        this.popperElement().removeAttribute("data-show")
        if (this.shouldRenderOverlayValue) {
          this.toggleOverlay()
        }
      }
    }

    if (this.delayValue) {
      this.timeOut = setTimeout(() => handleHide(), TIME_OUT_MS)
    } else {
      handleHide()
    }
  }

  toggleOverlay(): void {
    const overlay = document.getElementById(this.overlayIdValue)

    if (!overlay) {
      return
    }

    if (this.popperElement().getAttribute("data-show") === "true") {
      overlay.classList.remove("hidden")
    } else {
      overlay.classList.add("hidden")
    }
  }

  popperElement() {
    return this.popperElementRef || (this.hasDropdownTarget ? this.dropdownTarget : null)
  }

  handleClick(event: Event) {
    if (this.disallowClicksValue) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  movePopperToElement() {
    if (this.unnestValue) {
      this.popperElementRef = this.element.querySelector('[data-popper-target="dropdown"]')
      const unnestTarget = document.querySelector(this.unnestTargetValue)
      this.popperElementRef && unnestTarget && unnestTarget.appendChild(this.popperElementRef)

      // Attach a mouseleave handler, to hide the tooltip when the mouse pointer leaves the tooltip area
      if (this.triggerValue === "hover" && this.popperElementRef) {
        this.popperElementRef.addEventListener("mouseleave", (e) => {
          this.hide(e)
        })
      }
    }
  }

  movePopperBackToOrigin() {
    if (this.unnestValue && this.popperElementRef) {
      this.element.appendChild(this.popperElementRef)
    }
  }
}
