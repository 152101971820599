const css = `.DataUnavailable-module__graph___AIkft {
  background-image: url("/spend_control.svg");
  min-height: 200px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin: 1rem;
}

.DataUnavailable-module__phrase___gtu9l {
  text-align: center;
  margin-bottom: 24px;
}

.DataUnavailable-module__button___sDjya {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 15px;
  color: white;
  border-radius: 4px;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  background: #6B63E8;
  transition: 0.3s ease-in-out;
  border: unset;
}
.DataUnavailable-module__button___sDjya:hover {
  background: #8982ED;
}

.DataUnavailable-module__buttonWrapper___QTU3q {
  display: flex;
  justify-content: center;
}

.DataUnavailable-module__paddingLeft___ewzua {
  padding-left: 8px;
}

.DataUnavailable-module__confirmationMessage___oROKa {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DataUnavailable-module__absoluteCentered___oXe51 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.DataUnavailable-module__strong___5TOZT {
  font-weight: 500;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "graph": "DataUnavailable-module__graph___AIkft",
  "phrase": "DataUnavailable-module__phrase___gtu9l",
  "button": "DataUnavailable-module__button___sDjya",
  "buttonWrapper": "DataUnavailable-module__buttonWrapper___QTU3q",
  "paddingLeft": "DataUnavailable-module__paddingLeft___ewzua",
  "confirmationMessage": "DataUnavailable-module__confirmationMessage___oROKa",
  "absoluteCentered": "DataUnavailable-module__absoluteCentered___oXe51",
  "strong": "DataUnavailable-module__strong___5TOZT"
};