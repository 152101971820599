import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="canopy--button-component"
export default class extends Controller {
  static targets = ["button", "loader", "label"]
  buttonTarget: HTMLButtonElement
  loaderTarget: HTMLSpanElement
  labelTarget: HTMLSpanElement

  connect() {
    document.addEventListener("turbo:submit-start", this.disableButton)
    document.addEventListener("turbo:submit-end", this.enableButton)
  }

  disconnect() {
    document.removeEventListener("turbo:submit-start", this.disableButton)
    document.removeEventListener("turbo:submit-end", this.enableButton)
  }

  disableButton = () => {
    this.buttonTarget.disabled = true
    this.buttonTarget.ariaBusy = "true"
    this.toggleOpacity()
  }

  enableButton = () => {
    this.buttonTarget.disabled = false
    this.buttonTarget.ariaBusy = "false"
    this.toggleOpacity()
  }

  toggleOpacity = () => {
    this.loaderTarget.classList.toggle("opacity-0")
    this.labelTarget.classList.toggle("opacity-0")
  }
}
