import React, { useContext, useEffect, useState } from "react"
import { Handle, Position } from "reactflow"
import { RequestStatusBar } from "./requestStatusBar"
import { useWorkflow } from "./workflowContext"
import { TaskDueDate } from "./taskDueDate"
import { getVisualBuilderHandleStyles, taskTriggersText } from "../../../../utils"

interface RequestTaskCardNodeProps {
  data: {
    taskId: string
    taskNameAndTooltip: string | null
    badgeWithTooltip: string | null
    assigneeName: string | null
    assignmentType: string
    triggerCondition: string
    triggers: string[]
    groupLabelName: string | null
    dueDate: string | null
    dueDateIcon: string | null
    dueDateIconColor: string | null
    dueDateTextColor: string | null
    taskStatus: string
    creditType: string | null
  }
}

export const RequestTaskCardNode = ({ data }: RequestTaskCardNodeProps) => {
  const [isSelected, setIsSelected] = useState(null)
  const { cardDimensions, selectedTaskId } = useWorkflow()
  const containerStyles = {
    width: `${cardDimensions.width}px`,
    height: `${cardDimensions.height}px`,
  }
  const iconStyles = {
    color: "white",
  }
  const palmIconStyles = {
    color: "#2B285D",
  }
  const leftHandleStyles = getVisualBuilderHandleStyles("left")
  const rightHandleStyles = getVisualBuilderHandleStyles("right")

  useEffect(() => {
    setIsSelected(data.taskId === selectedTaskId)
  }, [selectedTaskId])

  return (
    <div
      id={`request-task-card-${data.taskId}`}
      className={`rounded-md shadow-md bg-white border border-gray-300 ${isSelected ? "bg-purple-100" : ""}`}
      style={containerStyles}
    >
      <Handle type="target" position={Position.Left} style={leftHandleStyles} />
      <RequestStatusBar taskStatus={data.taskStatus} />
      <div className="flex flex-col p-4">
        <div className="space-y-2">
          <div className="flex flex-row justify-between items-center">
            <div className="small-heading truncate" dangerouslySetInnerHTML={{ __html: data.taskNameAndTooltip }} />
            <div dangerouslySetInnerHTML={{ __html: data.badgeWithTooltip }} />
          </div>
          <>
            <div className="flex flex-row items-center space-x-2">
              <div dangerouslySetInnerHTML={{ __html: data.assigneeAvatar }} />
              <div className="text-sm font-normal text-gray-600 truncate">
                {data.assigneeName || data.assignmentType}
              </div>
            </div>

            <div className="flex flex-row items-center space-x-2">
              <div>
                <div className="flex items-center justify-center w-5 h-5 border border-gray-500 rounded-full">
                  <i className="fa-solid fa-play fa-2xs text-gray-500 text-[8px]"></i>
                </div>
              </div>
              <div className="text-sm font-normal text-gray-600 truncate">{taskTriggersText(data.triggers)}</div>
            </div>

            <TaskDueDate
              dueDate={data.dueDate}
              dueDateIcon={data.dueDateIcon}
              dueDateIconColor={data.dueDateIconColor}
              dueDateTextColor={data.dueDateTextColor}
              taskStatus={data.taskStatus}
            />
          </>
        </div>
        {data.groupLabelName && (
          <div className="flex flex-row items-center mt-4 space-x-2">
            <span
              className={`rounded text-gray-800 px-2.5 pb-1 pt-1.5 leading-none small-heading capitalize ${
                isSelected ? "bg-gray-200" : "bg-gray-100"
              }`}
            >
              {data.groupLabelName}
            </span>
          </div>
        )}
        {data.creditType && (
          <div className="flex flex-row items-center mt-4 space-x-2">
            <div className="p-1 pl-2 pr-2 bg-purple-100 flex-shrink-0 rounded flex items-center justify-center">
              <i className="fas fa-tree-palm fa-2xs mr-2" style={palmIconStyles}></i>
              <div className="text-xs text-purple-800">{data.creditType}</div>
            </div>
          </div>
        )}
      </div>
      <Handle type="source" position={Position.Right} style={rightHandleStyles} />
    </div>
  )
}
