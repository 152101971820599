import React from "react"
import { IStageNode } from "../../../../utils/types"

interface StageNodeProps {
  data: IStageNode["data"]
}

const StageNode = ({ data }: StageNodeProps) => {
  const containerStyles = {
    height: "2000px",
  }
  const divStyles = {
    width: "1px",
    position: "relative",
    backgroundImage: "url('/long_vert_dash_bg.svg')",
  }
  const divLabelStyles = {
    height: "33px",
  }

  return (
    <div className="flex flex-row space-x-4" style={containerStyles}>
      <div style={divStyles}></div>
      <div style={divLabelStyles} className="rounded text-gray-800 px-2.5 pt-1.5 small-heading bg-blue-150">
        <i className="fas fa-map-marker-alt px-1"></i>
        {data.name}
      </div>
    </div>
  )
}

export default StageNode
