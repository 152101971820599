import React, { Dispatch, SetStateAction } from "react"

import styles from "./DataAvailable.module.scss"
import GearSettings from "../../../../../assets/images/gear_settings.svg"
import {
  ActiveUsers,
  BarsOrder,
  ColorMap,
  DataKeys,
  Employee,
  GraphFilters,
  WorkatoConnection,
} from "../../../../utils/spendControlConstants"
import DataOutput from "./DataOutput"
import Plus from "../../../../../assets/images/plus.svg"
import InfoIcon from "../../../../../assets/images/info_icon 2.svg"

interface IProps {
  activeUsers: ActiveUsers[]
  graphFilters: GraphFilters
  barsOrder: BarsOrder
  dataKeys: DataKeys
  colorMap: ColorMap
  title?: string
  setIsOpenConnectionDrawer: Dispatch<SetStateAction<boolean>>
  setIsOpenGoogleSocialUsersDrawer: Dispatch<SetStateAction<boolean>>
  updateGraphData: (newFilters: GraphFilters) => void
  ssoProvider: string
  isAvailableToConnect: boolean
  googleSocialData: Employee[]
  workatoConnection: WorkatoConnection
}

const DataAvailable: React.FC<IProps> = ({
  activeUsers,
  setIsOpenConnectionDrawer,
  workatoConnection,
  ...restProps
}) => {
  const showAddConnectionBtn =
    activeUsers?.length > 0 && restProps.isAvailableToConnect && workatoConnection?.active === false

  return (
    <div className={styles.container}>
      {activeUsers.length > 0 ? (
        <DataOutput
          {...restProps}
          activeUsers={activeUsers}
          setIsOpenConnectionDrawer={setIsOpenConnectionDrawer}
          showAddConnectionBtn={showAddConnectionBtn}
        />
      ) : (
        <div className={styles.emptyContainer}>
          <img src={"/emptyGraphState.svg"} alt={"spend control graph"} />
          <div className={styles.absoluteCentered}>Weeehoo, we’re fetching your data!</div>
        </div>
      )}
      {showAddConnectionBtn ? (
        <div
          className={`flex justify-between items-center m-5 py-4 px-6 mt-0 rounded-md border-purple-600 border bg-purple-50`}
        >
          <div className={"flex items-center"}>
            <InfoIcon className={`${styles.infoIcon} mr-4`} /> Add a direct connection for more detailed usage data
          </div>
          <div className={`button-secondary`} onClick={() => setIsOpenConnectionDrawer(true)}>
            <div className={"pr-2"}>
              <Plus />
            </div>
            Add Connection
          </div>
        </div>
      ) : (
        <div className={styles.gearWrapper}>
          <GearSettings onClick={() => setIsOpenConnectionDrawer(true)} className={styles.manageConnection} />
        </div>
      )}
    </div>
  )
}

export default DataAvailable
