import React, { Dispatch, SetStateAction } from "react"
import ActiveUsersGraph from "../ActiveUsersGraph/ActiveUsersGraph"

import styles from "./DataAvailable.module.scss"
import {
  ActiveUsers,
  BarsOrder,
  ColorMap,
  DataKeys,
  Employee,
  GraphFilters,
} from "../../../../utils/spendControlConstants"
import GoogleSocial from "../GoogleSocial/GoogleSocial"

interface IProps {
  activeUsers: ActiveUsers[]
  graphFilters: GraphFilters
  barsOrder: BarsOrder
  dataKeys: DataKeys
  colorMap: ColorMap
  title?: string
  setIsOpenConnectionDrawer: Dispatch<SetStateAction<boolean>>
  setIsOpenGoogleSocialUsersDrawer: Dispatch<SetStateAction<boolean>>
  updateGraphData: (newFilters: GraphFilters) => void
  ssoProvider: string
  isAvailableToConnect: boolean
  googleSocialData: Employee[]
}

const DataOutput: React.FC<IProps> = ({
  activeUsers,
  graphFilters: { granularity },
  barsOrder,
  dataKeys,
  colorMap,
  title = "Usage over time",
  setIsOpenConnectionDrawer,
  updateGraphData,
  ssoProvider,
  isAvailableToConnect,
  googleSocialData,
  setIsOpenGoogleSocialUsersDrawer,
  showAddConnectionBtn,
}) => {
  return (
    <div className={styles.borderContainer}>
      {ssoProvider === "google" ? (
        <GoogleSocial
          setIsOpenConnectionDrawer={setIsOpenConnectionDrawer}
          setIsOpenGoogleSocialUsersDrawer={setIsOpenGoogleSocialUsersDrawer}
          isAvailableToConnect={isAvailableToConnect}
          googleSocialData={googleSocialData}
          showAddConnectionBtn={showAddConnectionBtn}
        />
      ) : (
        <div className={styles.chartContainer}>
          <div className={styles.topRow}>
            <div className={styles.label}>{title}</div>
            <div className={styles.rightRow}>
              <div className={styles.buttonsContainer}>
                <button
                  className={`${styles.button} button-secondary ${
                    (granularity === "week" && styles.activeButton) || ""
                  }`}
                  type="button"
                  onClick={() => updateGraphData({ granularity: "week" })}
                  style={{ borderRadius: "0.375rem 0 0 0.375rem", borderRight: "none" }}
                >
                  Week
                </button>
                <button
                  className={`${styles.button} button-secondary ${
                    (granularity === "month" && styles.activeButton) || ""
                  }`}
                  onClick={() => updateGraphData({ granularity: "month" })}
                  type="button"
                  style={{ borderRadius: "0 0.375rem  0.375rem 0" }}
                >
                  Month
                </button>
              </div>
            </div>
          </div>
          <ActiveUsersGraph data={activeUsers} barsOrder={barsOrder} dataKeys={dataKeys} colorMap={colorMap} />
        </div>
      )}
    </div>
  )
}

export default DataOutput
