import React /* { useState, useEffect } */ from "react"

import styles from "./backdrop.module.scss"

interface IProps {
  onClick(): void
}

// Backdrop has a z-index:50 - make sure to go above that when another compoenent needs to be clicked.

// If non transparent background is ever need we can pass in a style and apply it.
const Backdrop: React.FC<IProps> = ({ onClick }) => {
  return <div onClick={onClick} className={styles.backdrop} />
}

export default Backdrop
