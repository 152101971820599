import { Dispatch, SetStateAction, useEffect } from "react"
import { getWorkatoConnectionURL, WorkatoConnection } from "../../utils/spendControlConstants"
import { put } from "@rails/request.js"

export type Params = {
  setHeight: Dispatch<SetStateAction<string>>
  setConnection: Dispatch<SetStateAction<WorkatoConnection>>
  onClose: () => void
  connection: WorkatoConnection
  organization: any
}

export default function usePostMessageListener({
  setHeight,
  onClose,
  setConnection,
  connection,
  organization,
}: Params): void {
  useEffect(() => {
    const receiveMessage = ({ data }: { data: any }): void => {
      if (typeof data === "string") {
        const parsedData = JSON.parse(data)
        //console.log(parsedData)
        switch (parsedData.type) {
          case "heightChange": {
            // setHeight(`${parsedData.payload.height}px`)
            break
          }
          case "connectionStatusChange": {
            const { id } = parsedData.payload
            if (parsedData.payload.connected !== connection?.active) {
              put(getWorkatoConnectionURL({ organizationSlug: organization.slug, connectionId: connection.id }), {
                body: { connected: parsedData.payload.connected },
                contentType: "application/json",
              })
              setConnection({
                ...connection,
                active: parsedData.payload.connected,
              } as WorkatoConnection)
            }

            if (parsedData.payload.connected) {
              onClose()
            }

            break
          }
          case "error":
            console.log(parsedData.payload.message)
            break
          default:
            break
        }
      }
    }
    window.addEventListener("message", receiveMessage)
    return (): void => window.removeEventListener("message", receiveMessage)
  }, [connection, setConnection, setHeight])
}
