const css = `.dropdown-module__dropbtn___43cSJ {
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown-module__dropbtn___43cSJ:hover,
.dropdown-module__dropbtn___43cSJ:focus {
  background-color: #2980b9;
}

.dropdown-module__dropdown___IeXkP {
  position: relative;
  display: inline-block;
}

.dropdown-module__dropdownContent___RD2kj {
  display: block;
  position: absolute;
  background-color: #f1f1f1;
  width: 200px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.dropdown-module__dropdownContent___RD2kj div {
  letter-spacing: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  background-color: white;
  padding: 6px 16px;
  text-decoration: none;
  cursor: pointer;
  display: block;
}
.dropdown-module__dropdownContent___RD2kj div:hover {
  background-color: rgb(245, 246, 250);
}

.dropdown-module__dropdownContent___RD2kj a {
  letter-spacing: 0px;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  color: white;
  background-color: rgb(135, 152, 173);
  padding: 12px 16px;
  text-decoration: none;
  cursor: pointer;
  display: block;
}
.dropdown-module__dropdownContent___RD2kj a:hover {
  background-color: rgb(159, 173, 189);
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "dropbtn": "dropdown-module__dropbtn___43cSJ",
  "dropdown": "dropdown-module__dropdown___IeXkP",
  "dropdownContent": "dropdown-module__dropdownContent___RD2kj"
};