import { MAX_INPUT_GROUP_PARENT_LEVELS } from "./constants"
import { findElement, replaceWith, removeElement, wrapElement, insert } from "./index"

function hasErrorElement(form, inputId) {
  return findElement(form || document, `#${getRequiredErrorElementId(inputId)}`)
}

function getRequiredErrorElementId(inputId) {
  return `${inputId}_error`
}

function isRequiredInput(input) {
  return input.required
}

function isInputHidden(input) {
  return input.type === "hidden"
}

function isTomSelectInput(input) {
  return input.classList.contains("is-tom-select")
}

function tomSelectHasLoaded(input) {
  return input.classList.contains("tomselected")
}

function hasValue(input) {
  if (input.type === "number") {
    return input.value != null && input.value !== "" && !isNaN(parseFloat(input.value)) && parseFloat(input.value) !== 0
  }
  return input.value != null && input.value !== ""
}

function isRadioButton(input) {
  return input.type === "radio"
}

function isCheckBox(input) {
  return input.type === "checkbox"
}

function isRadioButtonChecked(input) {
  return input.checked
}

function isTrixEditor(input) {
  return (
    input.classList.contains("hidden") &&
    input?.previousElementSibling &&
    input.previousElementSibling.nodeName === "TRIX-EDITOR"
  )
}

function getFieldName(input) {
  const regex = /\[([^\[\]]+)\]/g
  let match
  let innermostElement

  while ((match = regex.exec(input.name)) !== null) {
    innermostElement = match[1]
  }

  return innermostElement
}

function tryGetParentInputGroup(input: HTMLElement, count: number = 0) {
  const parent = input.parentElement
  if (parent?.classList.contains("_input-group")) {
    return parent
  } else if (parent != null && count < MAX_INPUT_GROUP_PARENT_LEVELS) {
    return tryGetParentInputGroup(parent, count + 1)
  } else {
    return null
  }
}

export const manuallyClearRequiredErrors = (input, wrapper) => {
  const inputErrorWrapperId = `${input.id}_error`
  const inputErrorWrapper = document.getElementById(inputErrorWrapperId)

  if (inputErrorWrapper) {
    input.classList.remove("border-red-500")
    const elm = tryGetParentInputGroup(input) || input
    replaceWith(wrapper, elm)
  }
}

export const addCustomRequiredErrorListener = () => {
  const input_types = ["number", "date", "text", "textarea", "checkbox"]

  document.addEventListener(
    "invalid",
    (e) => {
      e.preventDefault()

      const input = e.target as HTMLInputElement | undefined
      const form = input.closest("form") as HTMLFormElement | undefined

      const isNotApplicableTsEvent = isTomSelectInput(input) && !tomSelectHasLoaded(input)

      if (isNotApplicableTsEvent) {
        return
      }

      if (isRadioButton(input)) {
        const name = getFieldName(input) || input.name
        const radioButtonGroupId = `radio_group_${name}`
        const radioButtonGroup = document.getElementById(radioButtonGroupId)
        const errorExists = document.getElementById(getRequiredErrorElementId(radioButtonGroupId))

        if (!errorExists) {
          addErrorToInput(form, radioButtonGroup, radioButtonGroupId)
        }
      } else if (isCheckBox(input)) {
        const alreadyHasErrorElement = hasErrorElement(form, input.id)
        if (!alreadyHasErrorElement) {
          const checkBoxElement = input.closest(".checkbox-wrapper")
          addErrorToInput(form, checkBoxElement, input.id, "required")
        }
      } else {
        const isRequiredInputWithoutValue = isRequiredInput(input) && !hasValue(input)
        const isHidden = isInputHidden(input)
        const inputElement = isTomSelectInput(input) ? findElement(input.parentElement, ".ts-wrapper") : input

        const alreadyHasErrorElement = hasErrorElement(form, input.id)

        if (!isHidden && isRequiredInputWithoutValue && !alreadyHasErrorElement) {
          addErrorToInput(form, inputElement, input.id)
        } else if (input_types.includes(input.type) && !input.validity.valid) {
          if (alreadyHasErrorElement) {
            const wrapper = alreadyHasErrorElement
            manuallyClearRequiredErrors(input, wrapper)
          }
          if (isRequiredInputWithoutValue) {
            addErrorToInput(form, inputElement, input.id, "required")
          } else {
            addErrorToInput(form, inputElement, input.id, "invalid")
          }
        }
      }

      const inputElement = form.querySelector(".invalid")
      inputElement?.scrollIntoView({ block: "center", behavior: "smooth" })

      // check other inputs & radio btns in the form to see if their errors should be cleared
      clearOldErrors(form)
      clearRadioButtonErrors(form)
    },
    true,
  )

  function clearOldErrors(form) {
    const inputs = form?.querySelectorAll("input") || []
    const selects = form?.querySelectorAll("select") || []

    const filteredInputs = [...inputs].filter((input) => !isRadioButton(input))
    const allInputs = [...filteredInputs, ...selects]

    allInputs?.forEach((input) => {
      let existingErrorElement = null

      try {
        existingErrorElement = hasErrorElement(form, input.id)
      } catch (exceptionVar) {
        return
      }

      if (existingErrorElement != null) {
        if (isRequiredInput(input) && !hasValue(input)) {
          return
        }
        if (input_types.includes(input.type) && !input.validity.valid) {
          return
        }

        // remove error element and border error class
        input.classList.remove("border-red-500")
        if (isTomSelectInput(input)) {
          findElement(input.parentElement, ".ts-wrapper")?.classList?.remove("border-red-500")
          removeElement(existingErrorElement)
        } else {
          const elm = tryGetParentInputGroup(input) || input
          replaceWith(existingErrorElement.parentElement, elm)
        }
      }
    })
  }

  function clearRadioButtonErrors(form) {
    const radioButtonGroups = form?.querySelectorAll("div[id^='radio_group_']") || []

    radioButtonGroups?.forEach((radioGroup) => {
      const radioButtons = radioGroup?.querySelectorAll("input[type='radio']") || []

      const existingErrorElement = document.getElementById(getRequiredErrorElementId(radioGroup.id))
      const someRadioBtnIsChecked = [...radioButtons].some(
        (radioBtn) => isRequiredInput(radioBtn) && isRadioButtonChecked(radioBtn),
      )

      if (existingErrorElement != null && someRadioBtnIsChecked) {
        // remove error element and border error class
        removeElement(existingErrorElement)
      }
    })
  }

  function addErrorToInput(form, input, id, message = "required") {
    // add basic error border to the input
    input?.classList.add("border-red-500")

    let shouldUseFriendlyName = false
    let friendlyName

    // get label if possible to display a friendly error message
    if (!input?.classList.contains("no-friendly-name-for-error")) {
      const correspondingLabel: HTMLLabelElement | undefined = form?.querySelector(`label[for="${input.id}"]`)
      friendlyName = correspondingLabel?.innerText
      shouldUseFriendlyName = friendlyName && friendlyName.length <= 28
    }

    // create an error element
    const errorWrapper = document.createElement("div")
    errorWrapper.classList.add("w-full", "invalid", "relative")

    const errorSubText = document.createElement("div")
    errorSubText.classList.add("text-red-500", "caption", "top-1", "left-1")
    if (isTrixEditor(input)) {
      errorSubText.classList.add("absolute")
    } else {
      errorSubText.classList.add("mt-[8px]")
    }
    errorSubText.innerText = shouldUseFriendlyName ? `${friendlyName} is ${message}` : `This field is ${message}`
    errorSubText.id = getRequiredErrorElementId(id)

    const elementToWrap = tryGetParentInputGroup(input) || input

    // check if already is wrapped with errorWrapper
    const parent = elementToWrap.parentElement
    if (!parent?.classList.contains("invalid")) {
      wrapElement(elementToWrap, errorWrapper)
    }

    // combine the error element with the input element
    insert(errorSubText, elementToWrap, true)
  }
}
