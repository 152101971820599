import React, { useContext } from "react"
import { useReactFlow } from "reactflow"
import { useWorkflow } from "./workflowContext"

interface EditTaskButtonProps {
  editPath: string
  isEditing: boolean
  taskId: string
}

export const EditTaskButton = ({ editPath, isEditing, taskId }: EditTaskButtonProps) => {
  const { cardDimensions, editable } = useWorkflow()
  const { getViewport } = useReactFlow()
  const buttonWidth = editable ? 100 : 105
  const editTaskButtonStyles = {
    position: "absolute",
    top: -40,
    width: `${buttonWidth}px`,
    left: `${cardDimensions.width / 2 - buttonWidth / 2}px`,
    pointerEvents: "all",
  }

  const handleOnClick = () => {
    location.href = `${editPath}?viewport_position=${JSON.stringify(getViewport())}&selected=${taskId}`
  }

  return (
    <a onClick={handleOnClick}>
      <div
        style={editTaskButtonStyles}
        id={`edit-task-${taskId}`}
        className={`cursor-pointer rounded-md flex flex-row space-x-2 justify-center text-white text-sm py-1.5 px-3 items-center font-medium ${
          isEditing ? "bg-purple-700" : "bg-gray-800"
        }`}
      >
        {isEditing ? (
          <div>{editable ? "Editing Task" : "Viewing Task"}</div>
        ) : (
          <div className="flex flex-row space-x-2">
            <span>
              <i className={editable ? "fa-regular fa-pen-to-square" : "fa-regular fa-eye"}></i>
            </span>
            <div data-testid="edit-task">{editable ? "Edit Task" : "View Task"}</div>
          </div>
        )}
      </div>
    </a>
  )
}
