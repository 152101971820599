export const textBasedInputTypes = [
  "text",
  "password",
  "email",
  "search",
  "tel",
  "url",
  "number",
  "date",
  "datetime-local",
  "month",
  "week",
  "time",
  "color",
]

export const isTextBasedInput = (element: HTMLElement) => {
  return element.tagName === "TEXTAREA" || (element.tagName === "INPUT" && textBasedInputTypes.includes(element.type))
}
