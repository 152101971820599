const css = `.GoogleSocial-module__container___nlPLk {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.GoogleSocial-module__employeesCount___5DYD4 {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #A0AEC0;
  padding-right: 40px;
  height: 164px;
}

.GoogleSocial-module__count___1srrS {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: #564FBA;
  padding-bottom: 4px;
}

.GoogleSocial-module__button___0p-Of {
  margin-top: 12px;
  background: #6B63E8;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  padding: 6px 12px;
  cursor: pointer;
  color: white;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.GoogleSocial-module__connectButton___BYP0E {
  margin-top: 12px;
  border-radius: 6px;
  padding: 6px 12px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.GoogleSocial-module__connectionDescription___qKB01 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 375px;
  padding-left: 48px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.25px;
  color: #1A202C;
}

.GoogleSocial-module__loader___bUZbp {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "container": "GoogleSocial-module__container___nlPLk",
  "employeesCount": "GoogleSocial-module__employeesCount___5DYD4",
  "count": "GoogleSocial-module__count___1srrS",
  "button": "GoogleSocial-module__button___0p-Of",
  "connectButton": "GoogleSocial-module__connectButton___BYP0E",
  "connectionDescription": "GoogleSocial-module__connectionDescription___qKB01",
  "loader": "GoogleSocial-module__loader___bUZbp"
};