import React from "react"
import { Handle, Position } from "reactflow"
import { getVisualBuilderHandleStyles } from "../../../../utils"
import { useWorkflow } from "./workflowContext"
import { Collapsible } from "../../collapsible/collapsible"

export const requestRequestSubmittedTaskCardNode = ({ data }) => {
  const { cardDimensions } = useWorkflow()
  const containerStyles = {
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.08)",
    width: `${cardDimensions.width}px`,
  }
  const handleStyles = getVisualBuilderHandleStyles("right")
  const newHandleStyles = {
    top: cardDimensions.height / 2,
    ...handleStyles,
  }

  const detailPair = (key, value, image = null) => {
    return (
      <div className="flex justify-between space-x-2">
        <div className="body-text text-gray-500">{key}</div>
        <div className={`body-text flex space-x-1 items-center text-right ${value ? "" : "text-gray-400"}`}>
          {image && <div dangerouslySetInnerHTML={{ __html: image }} />}
          <div>{value || notSet}</div>
        </div>
      </div>
    )
  }

  const NotSet = () => <span className="body-text text-gray-400">Not Set</span>
  const notSet = <NotSet />

  return (
    <div
      id={`request-task-card-${data.taskId}`}
      className="p-4 border border-gray-300 rounded-md bg-gray-50"
      style={containerStyles}
    >
      <Handle type="source" position={Position.Right} style={newHandleStyles} />
      <div className="flex flex-col space-y-3">
        <div className="flex flex-row">
          <div className="truncate small-heading">Request Submitted</div>
        </div>
        {detailPair("Supplier", data.supplierName, data.supplierLogo)}
        {detailPair("Type", data.type)}
        {detailPair("Anticipated Spend", data.budget)}
        {detailPair("Target Date", data.targetDate)}
        <Collapsible>
          <div className="space-y-3">
            {detailPair("Category", data.category)}
            {detailPair("Department", data.department)}
            {detailPair("Requestor", data.requestorName, data.requestorAvatar)}
            {detailPair("Submitted On", data.submittedOn)}
            {detailPair("Addressable", data.addressable)}
            {detailPair("Managed By", data.managedBy)}
            {detailPair("Workflow", data.workflow)}
          </div>
        </Collapsible>
      </div>
    </div>
  )
}

export default requestRequestSubmittedTaskCardNode
