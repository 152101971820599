const css = `.DataAvailable-module__container___0R73O {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.DataAvailable-module__borderContainer___17tcu {
  padding: 0 1rem;
  border-top: 1px solid #CBD5E0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.DataAvailable-module__gearWrapper___l4fzA {
  padding: 1rem;
  border-top: 1px solid #CBD5E0;
}
.DataAvailable-module__gearWrapper___l4fzA svg {
  height: 21px;
  cursor: pointer;
}

.DataAvailable-module__button___CFjmv {
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  width: 84px;
  height: 32px;
  transition: 0.3s ease-in-out;
}

.DataAvailable-module__activeButton___ysNfW {
  background: #E2E8F0;
}

.DataAvailable-module__buttonsContainer___HNlrx {
  display: flex;
  border-radius: 4px;
  width: min-content;
  overflow: hidden;
  align-items: center;
}

.DataAvailable-module__topRow___cIlb- {
  display: flex;
  margin-bottom: 5px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.DataAvailable-module__row___av1hp {
  display: flex;
}

.DataAvailable-module__label___4mKZd {
  font-weight: 500;
  color: #8798AD;
}

.DataAvailable-module__absoluteCentered___333bl {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.DataAvailable-module__emptyContainer___BviSy {
  position: relative;
  font-weight: 400;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DataAvailable-module__noData___E0IG- {
  margin-left: 16px;
}

.DataAvailable-module__rightRow___vuDxz {
  display: flex;
  align-items: center;
}

.DataAvailable-module__dropdownButton___npUL- {
  margin: 0;
  height: 32px;
}

.DataAvailable-module__dropDownElement___nC9Bg {
  font-weight: 400 !important;
}

.DataAvailable-module__dropDownElementActive___lYrgy {
  font-weight: 500 !important;
}

.DataAvailable-module__chartContainer___20LV1 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem 0;
}

.DataAvailable-module__infoIcon___sa6EW path {
  fill: #564FBA;
}
.DataAvailable-module__infoIcon___sa6EW g {
  opacity: 1;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "container": "DataAvailable-module__container___0R73O",
  "borderContainer": "DataAvailable-module__borderContainer___17tcu",
  "gearWrapper": "DataAvailable-module__gearWrapper___l4fzA",
  "button": "DataAvailable-module__button___CFjmv",
  "activeButton": "DataAvailable-module__activeButton___ysNfW",
  "buttonsContainer": "DataAvailable-module__buttonsContainer___HNlrx",
  "topRow": "DataAvailable-module__topRow___cIlb-",
  "row": "DataAvailable-module__row___av1hp",
  "label": "DataAvailable-module__label___4mKZd",
  "absoluteCentered": "DataAvailable-module__absoluteCentered___333bl",
  "emptyContainer": "DataAvailable-module__emptyContainer___BviSy",
  "noData": "DataAvailable-module__noData___E0IG-",
  "rightRow": "DataAvailable-module__rightRow___vuDxz",
  "dropdownButton": "DataAvailable-module__dropdownButton___npUL-",
  "dropDownElement": "DataAvailable-module__dropDownElement___nC9Bg",
  "dropDownElementActive": "DataAvailable-module__dropDownElementActive___lYrgy",
  "chartContainer": "DataAvailable-module__chartContainer___20LV1",
  "infoIcon": "DataAvailable-module__infoIcon___sa6EW"
};