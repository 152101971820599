import { Controller } from "@hotwired/stimulus"
import { get, patch } from "@rails/request.js"
import { disable, enable, hide, show } from "../utils"

// Connects to data-controller="document-edit-form"
export default class extends Controller {
  static targets = [
    "contractSection",
    "contractSelect",
    "contractSelectTomSelect",
    "existingContractRadioBtn",
    "newContractRadioBtn",
    "submitButton",
    "moveToDifferentContractButton",
    "autoSaveStatus",
  ]
  static values = {
    orgId: String,
    mediumConfidenceDocumentTypeId: String,
    handleSubmitButton: { Boolean, default: true },
    orgOrContractDocument: { Boolean, default: true },
  }

  orgIdValue: string
  mediumConfidenceDocumentTypeIdValue: string
  handleSubmitButtonValue: boolean
  contractSectionTarget: HTMLElement
  contractSelectTarget?: HTMLElement
  contractSelectTomSelectTarget?: HTMLElement
  existingContractRadioBtnTarget: HTMLInputElement
  newContractRadioBtnTarget: HTMLInputElement
  submitButtonTarget: HTMLButtonElement
  moveToDifferentContractButtonTarget: HTMLButtonElement
  autoSaveStatusTarget: HTMLElement

  connect() {
    this.element.addEventListener("select", this.renderContractSectionOnSelect.bind(this))
    if (this.orgOrContractDocumentValue) {
      this.enableOrDisableSubmit(false)
    }
  }

  disconnect() {
    this.element.removeEventListener("select", this.renderContractSectionOnSelect.bind(this))
  }

  renderContractSectionOnSelect(e) {
    if (this.supplierSearchComponent() && this.supplierSearchComponent().disabled) {
      return
    }
    const selectedSupplier = e.detail.value
    this.conditionallyRenderContractSection(selectedSupplier)
  }

  renderContractSectionOnClick() {
    const selectedSupplier = this.supplierSearchComponent().value
    this.moveToDifferentContractButtonTarget.remove()
    this.allowEditSupplier()
    this.conditionallyRenderContractSection(selectedSupplier)
  }

  allowEditSupplier() {
    this.element.dispatchEvent(new CustomEvent("enableSupplierChanges", { bubbles: true }))
  }

  conditionallyRenderContractSection(supplierId: string) {
    if (!supplierId) {
      this.clearContractSelect()
    } else {
      hide(this.contractSelectTarget)
      this.updateContractSelect(supplierId)
    }
  }

  async updateContractSelect(newSupplier) {
    const url = `/contract_search?supplier_id=${newSupplier}&org_id=${this.orgIdValue}`
    try {
      const response = await get(url, { responseKind: "json" })
      const contracts = await response.json
      this.processContracts(contracts)
    } catch (error) {
      console.error("Failed to fetch contracts:", error)
    }
  }

  processContracts(contracts) {
    if (contracts.contracts.length === 0) {
      this.handleNoContractsFound()
    } else {
      this.populateContracts(contracts)
    }
  }

  populateContracts(contracts) {
    this.contractSelectTomSelectTarget.tomselect.clear()
    this.contractSelectTomSelectTarget.tomselect.clearOptions()
    contracts.contracts.forEach((group) => {
      this.contractSelectTomSelectTarget.tomselect.addOptionGroup(group.id, { label: group.label })
      group.options.forEach((option) => {
        this.contractSelectTomSelectTarget.tomselect.addOption(option)
      })
    })
    this.contractSelectTomSelectTarget.tomselect.addItem(contracts.selected_contract_id)
    this.showExistingContractsSection()
  }

  clearContractSelect() {
    hide(this.contractSectionTarget)
    this.contractSelectTomSelectTarget.tomselect.clear()
    this.enableOrDisableSubmit(false)
  }

  handleNoContractsFound() {
    hide(this.existingContractRadioBtnTarget.closest('[data-radio-button-target="wrapper"]'))
    this.newContractRadioBtnTarget.checked = true
    this.enableOrDisableSubmit(true)
    show(this.contractSectionTarget)
  }

  showExistingContractsSection() {
    show(this.existingContractRadioBtnTarget.closest('[data-radio-button-target="wrapper"]'))
    this.existingContractRadioBtnTarget.checked = true
    show(this.contractSectionTarget)
    this.onMoveToExistingContract()
  }

  onMoveToExistingContract() {
    show(this.contractSelectTarget)
    if (this.contractSelectTomSelectTarget.tomselect.items.length === 0) {
      this.enableOrDisableSubmit(false)
    } else {
      this.enableOrDisableSubmit(true)
    }
  }

  onMoveToNewContract() {
    hide(this.contractSelectTarget)
    this.contractSelectTomSelectTarget.tomselect.clear()
    this.enableOrDisableSubmit(true)
  }

  onContractSelectChange(e) {
    if (e.target.value === "") {
      this.enableOrDisableSubmit(false)
    } else {
      this.enableOrDisableSubmit(true)
    }
  }

  onDocumentTypeSelectChange(e) {
    this.triggerSave(e.target.value)
  }

  onConfirmMediumConfidenceDocTypeSuggestion() {
    this.triggerSave(this.mediumConfidenceDocumentTypeIdValue)
  }

  enableOrDisableSubmit(enable: Boolean) {
    if (this.orgOrContractDocumentValue) {
      if (this.handleSubmitButtonValue) {
        window.dispatchEvent(
          new CustomEvent("FullScreen:submitButtonStatusChanged", {
            detail: { enable: enable },
          }),
        )
      } else {
        window.dispatchEvent(
          new CustomEvent("DocumentBulkEditForm:supplierCompleted", {
            detail: { enable: enable },
          }),
        )
      }
    }
  }

  triggerSave(value: string) {
    window.dispatchEvent(
      new CustomEvent("AutoSave:save", {
        detail: { param: "document_type", value: value },
      }),
    )
  }

  supplierSearchComponent(): HTMLElement {
    return document.getElementById("supplier-search_input")
  }
}
