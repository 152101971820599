import React from "react"
import { Handle, Position, useReactFlow } from "reactflow"

interface AddTaskButtonProps {
  actionPath: string
  subworkflow: boolean
}

export const AddFirstTaskButton = ({ actionPath, subworkflow }: AddTaskButtonProps) => {
  const { getViewport } = useReactFlow()

  const handleClick = (e) => {
    e.stopPropagation()
    const url = `${actionPath}&viewport_position=${JSON.stringify(getViewport())}`
    location = url
  }

  const handleStyles = {
    minWidth: "2px",
    minHeight: "2px",
    width: "103px",
    height: "1px",
    background: "#6A778A",
    border: "none",
    borderRadius: "0px",
    transform: "none",
    right: "-90px",
    zIndex: -1,
  }

  const content = subworkflow === true ? "Subworkflow is initiated" : "Request is submitted"

  return (
    <>
      <Handle type="source" position={Position.Right} style={handleStyles} />
      <button onClick={handleClick}>
        <div
          id={`add-first-task`}
          className="absolute pointer-events-auto z-10 bg-gray-50 right-[-360px] top-[calc(50%-49px)] border border-gray-300 rounded-md py-4 px-10 text-center shadow space-y-1"
        >
          <div className="font-semibold text-base text-purple-500 flex items-center justify-center">
            <i className="fas fa-square-plus px-1"></i>
            <div>Add a Task</div>
          </div>
          <div className="text-base w-48 text-gray-600">Create a Task to trigger after a {content}</div>
        </div>
      </button>
    </>
  )
}
