import React, { useEffect } from "react"
import { Handle, Position } from "reactflow"
import { AddTaskButton } from "./addTaskButton"
import { EditTaskButton } from "./editTaskButton"
import { getVisualBuilderHandleStyles, taskTriggersText } from "../../../../utils"
import { useWorkflow } from "./workflowContext"
import { Trigger } from "../../../../utils/types"
import { taskState } from "./cardSelectedService"
import { SubworkflowTaskCardNode } from "./subworkflowTaskCardNode"
import clsx from "clsx"

interface WorkflowTaskCardNodeProps {
  data: {
    taskId: string
    editPath: string
    taskNameAndTooltip: string | null
    badgeWithTooltip: string | null
    assigneeName: string | null
    assignmentType: string
    triggerCondition: string
    triggers: Trigger[]
    targets: string[]
    ruleBadge: string
    addTaskPathLeft?: string
    addTaskPathBottom?: string
    addTaskPathRight?: string
    cardHeader?: string
    cardBody?: string
    workflowGuideText?: string
    workflowGuideUrl?: string
    groupLabelName: string | null
    requestStage: string
    subworkflowPlaceholder: boolean | null
    subworkflowToApply: string | null
    subworkflowVisualizationUrl: string | null
    creditType: string | null
    integrationLogo: string | null
  }
}

export const WorkflowTaskCardNode = ({ data }: WorkflowTaskCardNodeProps) => {
  const { cardDimensions, editable, selectedTaskId, drawerOpened, isSubworkflow } = useWorkflow()
  const containerStyles = {
    width: `${cardDimensions.width}px`,
    height: `${cardDimensions.height}px`,
  }
  const iconStyles = {
    color: "white",
  }
  const leftHandleStyles = getVisualBuilderHandleStyles("left")
  const rightHandleStyles = getVisualBuilderHandleStyles("right")

  const isSelected = selectedTaskId === data.taskId

  useEffect(() => {
    if (!isSelected) {
      const workflowTaskCardContainer = document.querySelector(`[data-id="${data.taskId}"]`)
      if (drawerOpened) {
        workflowTaskCardContainer?.classList.add("!pointer-events-none")
      } else {
        workflowTaskCardContainer?.classList.remove("!pointer-events-none")
      }
    }
  }, [drawerOpened])

  const { isEditing, rightButtonSelected, bottomButtonSelected, leftButtonSelected } = taskState({ data })

  return data.subworkflowPlaceholder ? (
    <SubworkflowTaskCardNode data={data} />
  ) : (
    <div
      id={`workflow-task-card-${data.taskId}`}
      className={`rounded-md shadow-md bg-white border border-gray-300 p-4 ${isSelected ? "bg-purple-100" : ""}`}
      style={containerStyles}
    >
      {isSelected && data.editPath && (
        <div>
          <EditTaskButton editPath={data.editPath} taskId={data.taskId} isEditing={isEditing} />
        </div>
      )}
      <Handle type="target" position={Position.Left} style={leftHandleStyles} />
      <div className="flex flex-col">
        <div className="space-y-2">
          <div className="flex flex-row">
            <div className="small-heading truncate" dangerouslySetInnerHTML={{ __html: data.taskNameAndTooltip }} />
            {data.integrationLogo && <img src={data.integrationLogo} className="h-5 w-5 ml-auto"></img>}
          </div>
          <>
            <div className="flex flex-row items-center space-x-2">
              <div dangerouslySetInnerHTML={{ __html: data.assigneeAvatar }} />
              <div className="text-sm font-normal text-gray-600 truncate">
                {data.assigneeName || data.assignmentType}
              </div>
            </div>

            <div className="flex flex-row items-center space-x-2">
              <div>
                <div className="flex items-center justify-center w-6 h-6 bg-gray-400 rounded-full">
                  <i className="fa-solid fa-play fa-2xs" style={iconStyles}></i>
                </div>
              </div>
              <div className="text-sm font-normal text-gray-600 truncate">{taskTriggersText(data.triggers)}</div>
            </div>
          </>
        </div>
        <div className="mt-4 space-y-2">
          <div className={clsx("flex flex-row items-center space-x-2", !data.groupLabelName && "invisible")}>
            <span
              className={`rounded text-gray-800 px-1 py-0.5 text-sm font-medium capitalize ${
                isSelected ? "bg-gray-200" : "bg-gray-100"
              }`}
            >
              {data.groupLabelName || "No Group"}
            </span>
          </div>
          <div dangerouslySetInnerHTML={{ __html: data.ruleBadge }} />
        </div>
      </div>
      {editable && data.addTaskPathLeft && isSelected && !isSubworkflow && (
        <AddTaskButton
          position="left"
          selected={leftButtonSelected}
          actionPath={data.addTaskPathLeft}
          taskId={data.taskId}
        />
      )}
      {editable && data.addTaskPathRight && isSelected && !isSubworkflow && (
        <AddTaskButton
          position="right"
          selected={rightButtonSelected}
          actionPath={data.addTaskPathRight}
          taskId={data.taskId}
        />
      )}
      {editable && data.addTaskPathBottom && isSelected && !isSubworkflow && (
        <AddTaskButton
          selected={bottomButtonSelected}
          position="bottom"
          actionPath={data.addTaskPathBottom}
          taskId={data.taskId}
        />
      )}
      <Handle type="source" position={Position.Right} style={rightHandleStyles} />
    </div>
  )
}
