import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

// Connects to data-controller="flash"
export default class extends Controller {
  static targets = ["wrapper"]
  wrapperTarget: Element

  connect() {
    setTimeout(() => this.close(), 6000)
  }

  close() {
    leave(this.wrapperTarget)
  }
}
