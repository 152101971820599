import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["mainInput", "relatedInput"]

  connect() {
    this.toggle()
  }

  toggle() {
    this.relatedInputTargets.forEach((input) => {
      input.disabled = !this.mainInputTarget.checked

      if (!this.mainInputTarget.checked) {
        input.checked = this.mainInputTarget.checked
      }
    })
  }
}
