import { Controller } from "@hotwired/stimulus"
import { buildUrl } from "../utils/urls"
import { debounce } from "lodash"
import { show, hide } from "../utils"

import { get } from "@rails/request.js"

interface FrameElement extends HTMLElement {
  src: string
}

// Connects to data-controller="product-search"
export default class extends Controller {
  /* Targets */
  static targets = [
    "resultsFrame",
    "input",
    "inputError",
    "option",
    "selection",
    "icon",
    "searchGlassIcon",
    "clearInputIcon",
    "newProductButton",
    "selectionContent",
    "productDetailsSection",
    "confirmMatchButton",
    "rejectMatchButton",
    "matchSuggestionConfirmSection",
  ]
  resultsFrameTarget: FrameElement
  inputTarget: HTMLInputElement
  inputErrorTarget: HTMLDivElement
  selectionTarget: HTMLInputElement
  iconTarget: HTMLDivElement
  iconTargets: [HTMLDivElement]
  optionTargets: [HTMLLIElement]
  hasResultsFrameTarget: boolean
  hasSelectionTarget: boolean
  hasInputTarget: boolean
  hasInputErrorTarget: boolean
  searchGlassIconTarget: SVGAElement
  clearInputIconTarget: SVGAElement
  addNewProductButtonTarget: HTMLButtonElement
  selectionContentTarget: HTMLDivElement
  rejectMatchButtonTarget: HTMLButtonElement
  confirmMatchButtonTarget: HTMLButtonElement
  matchSuggestionConfirmSectionTarget: HTMLElement[]

  /* Values */
  static values = {
    supplier: String,
    output: String,
    label: String,
    required: Boolean,
    orgId: String,
    clearOnBlur: Boolean,
    frameId: String,
    url: String,
    addNewProductUrl: String,
  }
  supplierValue: string
  outputValue: string
  labelValue: string
  requiredValue: boolean
  orgIdValue: string
  clearOnBlurValue: boolean
  frameIdValue: string
  urlValue: string
  addNewProductUrlValue: string

  connect() {
    document.addEventListener("click", this.onClickOutsideOfResults)
    const form = this.element.closest("form")
    form?.addEventListener("keypress", this.onFormKeyDown)
  }

  disconnect() {
    document.removeEventListener("click", this.onClickOutsideOfResults)
    const form = this.element.closest("form")
    form?.removeEventListener("keypress", this.onFormKeyDown)

    if (this.hasInputTarget) {
      this.inputTarget.value = ""
    }
    if (this.hasResultsFrameTarget) {
      this.resultsFrameTarget.src = null
      this.resultsFrameTarget.innerHTML = ""
    }
  }

  initialize() {
    if (this.hasSelectionTarget) {
      this.updateOutput(this.selectionTarget.value)
    }

    this.onSearch = debounce(this.onSearch.bind(this), 200)
  }

  selectionTargetConnected() {
    this.updateOutput(this.selectionTarget.value)
    this.onOptionSelect(this.selectionTarget.value)
  }

  selectionTargetDisconnected() {
    this.updateOutput("")
    this.onOptionSelect("")
  }

  addNewProductButtonTargetConnected(element) {
    element.addEventListener("click", this.onClickAddNewProduct.bind(this))
  }

  addNewProductButtonTargetDisconnected(element) {
    element.removeEventListener("click", this.onClickAddNewProduct.bind(this))
  }

  confirmMatchButtonTargetConnected(element) {
    element.addEventListener("click", this.handleConfirmMatchOnClickHandler.bind(this))
  }

  confirmMatchButtonTargetDisconnected(element) {
    element.removeEventListener("click", this.handleConfirmMatchOnClickHandler.bind(this))
  }

  rejectMatchButtonTargetConnected(element) {
    element.addEventListener("click", this.handleRejectMatchOnClickHandler.bind(this))
  }

  ejectMatchButtonTargetDisconnected(element) {
    element.removeEventListener("click", this.handleRejectMatchOnClickHandler.bind(this))
  }

  inputErrorTargetConnected() {
    this.hideError()
  }

  onClickAddNewProduct() {
    const url = `${this.addNewProductUrlValue}&product_name=${this.inputTarget.value}`
    get(url, { responseKind: "turbo-stream" })
  }

  onFormKeyDown(e) {
    if (e.target.dataset.supplierSearchTarget && e.code == "Enter") {
      e.preventDefault()
      return false
    }
  }

  onSearch(e) {
    if (e.code == "ArrowDown") {
      this.focusOption(0)
    } else if (e.code == "Enter") {
      this.focusOption(0, true)
    } else {
      const params = {
        q: this.inputTarget.value,
        label: this.labelValue,
        required: this.requiredValue,
        org_id: this.orgIdValue,
        frame_id: this.frameIdValue,
      }

      this.resultsFrameTarget.src = buildUrl(this.urlValue, params)
    }
  }

  onClickOutsideOfResults = (e) => {
    if (!this.hasResultsFrameTarget) {
      return false
    }
    const isClickInsideElement = this.element.contains(e.target)
    if (!isClickInsideElement) {
      this.resultsFrameTarget.src = null
      this.resultsFrameTarget.innerHTML = ""

      if (!this.hasInputTarget) {
        return false
      }

      if (this.clearOnBlurValue) {
        this.inputTarget.value = ""
      }
    }
  }

  focusOption(i, click = false) {
    const option = this.optionTargets[i]
    if (option) {
      option.focus()
      if (click) option.getElementsByTagName("a")[0].click()
    }
  }

  onOptionKeyUp(e) {
    if (e.code == "ArrowDown") {
      this.focusOption(e.params.id + 1)
    } else if (e.code == "ArrowUp") {
      this.focusOption(e.params.id - 1)
    } else if (e.code == "Enter") {
      e.target.getElementsByTagName("a")[0].click()
    }
  }

  updateOutput(val) {
    const outputValueElem = document.getElementById(this.outputValue)

    if (outputValueElem) {
      outputValueElem.setAttribute("value", val)
      const inputEvent = new Event("input")
      outputValueElem.dispatchEvent(inputEvent)
    } else {
      this.hideError()
    }
  }

  onFocus(e) {
    const inputValue = e.target.value

    if (!this.clearOnBlurValue && inputValue) {
      setTimeout(() => {
        e.target.dispatchEvent(new KeyboardEvent("keyup"))
      }, 100)
    }
  }

  onBlur(e) {
    if (this.clearOnBlurValue) {
      return
    }
    if (this.inputTarget.value) {
      this.showError()
    } else {
      this.hideError()
    }
  }

  showError() {
    this.hasInputTarget && this.inputTarget.classList.add("border-red-500")
    this.hasInputErrorTarget && this.inputErrorTarget.classList.remove("hidden")
  }

  hideError() {
    this.hasInputTarget && this.inputTarget.classList.remove("border-red-500")
    this.hasInputErrorTarget && this.inputErrorTarget.classList.add("hidden")
  }

  onOptionSelect(value: string) {
    this.element.dispatchEvent(new CustomEvent("select", { detail: { value } }))
  }

  enableChanges(cleanProductSelector) {
    if (this.hasSelectionTarget) {
      this.selectionTarget.disabled = false
    }

    this.selectionContentTarget.classList.remove(
      "bg-gray-50",
      "cursor-not-allowed",
      "border-gray-300",
      "hover:border-gray-300",
    )
    const productSearchCancelLink = document.getElementById("product-search_cancel_selection")
    show(productSearchCancelLink)

    const customLabelBadge = document.getElementById("product-search-custom-badge")
    customLabelBadge.remove()

    if (cleanProductSelector) {
      const productSearchHiddenInput = document.getElementById("product-search_input")
      productSearchHiddenInput.value = ""
      productSearchCancelLink.click()
    }
  }

  handleConfirmMatchOnClickHandler = () => {
    hide(this.matchSuggestionConfirmSectionTarget)
    this.enableChanges(false)
  }

  handleRejectMatchOnClickHandler = () => {
    hide(this.matchSuggestionConfirmSectionTarget)
    this.enableChanges(true)
  }
}
