import React from "react"
import { Handle, Position } from "reactflow"

export const cardDimensions = {
  width: 280,
  height: 116,
}

export const RequestStepCardNode = ({ data }) => {
  const handleStyles = {
    minWidth: "2px",
    minHeight: "2px",
    width: "2px",
    height: "2px",
    background: "transparent",
    border: "none",
  }

  const containerStyles = {
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.08)",
    width: `${cardDimensions.width}px`,
    height: `${cardDimensions.height}px`,
  }

  return (
    <div id={`request-step-card-${data.id}`} className="rounded-md bg-white px-3 py-1.5" style={containerStyles}>
      <Handle type="target" position={Position.Left} style={handleStyles} />
      <div className="flex flex-col space-y-2">
        <div className="space-y-1">
          <div className="flex flex-row">
            <div className="small-heading truncate">{data.label}</div>
          </div>
          <div className="flex flex-row items-center space-x-2">
            {data.requestStepId === "request-submitted-node"
              ? data.supplier && <div className="caption truncate">{data.supplier}</div>
              : data.trigger && <div className="small-text">Trigger: {data.trigger}</div>}
          </div>
        </div>
        <hr className="border-gray-300" />
        <div className="space-y-1">
          {data.requestStepId === "request-submitted-node" ? (
            data.requestor && <div className="small-text">Requestor: {data.requestor}</div>
          ) : (
            <div dangerouslySetInnerHTML={{ __html: data.progress }} />
          )}
          <div dangerouslySetInnerHTML={{ __html: data.status }} />
        </div>
      </div>
      <Handle type="source" position={Position.Right} style={handleStyles} />
    </div>
  )
}
