const css = `.ActiveUsersGraph-module__responsiveContainer___cALRa {
  flex-grow: 1;
}

.ActiveUsersGraph-module__legendRow___JTYAB {
  display: flex;
  justify-content: center;
}

.ActiveUsersGraph-module__legendItem___BTuSV {
  font-size: 14px;
  color: #1c1c1c;
  font-weight: 400;
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.ActiveUsersGraph-module__disabledLegend___merxo {
  color: #DEDEDE;
}

.ActiveUsersGraph-module__legendText___Hi65t {
  line-height: 22px;
  height: 19px;
}
@media (max-width: 1300px) {
  .ActiveUsersGraph-module__legendText___Hi65t {
    width: min-content;
  }
}

.ActiveUsersGraph-module__legendSquare___VEGgd {
  width: 10px;
  min-width: 10px;
  height: 10px;
  border-radius: 2px;
  margin-right: 10px;
  overflow: hidden;
  display: flex;
}

.ActiveUsersGraph-module__halfSquare___rwW0R {
  width: 50%;
  height: 100%;
}

.ActiveUsersGraph-module__tooltip___EzHvg {
  background: #fff;
  padding: 16px;
  border-radius: 6px;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 8px 8px rgba(50, 50, 71, 0.08)) drop-shadow(0px 0px 16px rgba(50, 50, 71, 0.06));
}
.ActiveUsersGraph-module__tooltip___EzHvg .ActiveUsersGraph-module__tooltipBold___trIjQ {
  font-weight: 500;
}

.ActiveUsersGraph-module__tooltipRow___krZXx {
  display: flex;
  align-items: center;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "responsiveContainer": "ActiveUsersGraph-module__responsiveContainer___cALRa",
  "legendRow": "ActiveUsersGraph-module__legendRow___JTYAB",
  "legendItem": "ActiveUsersGraph-module__legendItem___BTuSV",
  "disabledLegend": "ActiveUsersGraph-module__disabledLegend___merxo",
  "legendText": "ActiveUsersGraph-module__legendText___Hi65t",
  "legendSquare": "ActiveUsersGraph-module__legendSquare___VEGgd",
  "halfSquare": "ActiveUsersGraph-module__halfSquare___rwW0R",
  "tooltip": "ActiveUsersGraph-module__tooltip___EzHvg",
  "tooltipBold": "ActiveUsersGraph-module__tooltipBold___trIjQ",
  "tooltipRow": "ActiveUsersGraph-module__tooltipRow___krZXx"
};