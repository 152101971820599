const css = `.dropdownButton-module__dropdownButton___0JiN4 {
  border-radius: 4px;
  background-color: white;
  height: 34px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
}
.dropdownButton-module__dropdownButton___0JiN4:hover {
  background: rgb(230, 231, 235);
}

.dropdownButton-module__dropdownButtonText___BNH8I {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-right: 10px;
}`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
export default {
  "activePurple": "rgb(107, 99, 232)",
  "dropdownButton": "dropdownButton-module__dropdownButton___0JiN4",
  "dropdownButtonText": "dropdownButton-module__dropdownButtonText___BNH8I"
};