import ChartController from "../chart_controller"

// Connects to data-controller="analytics--usage-chart"
export default class extends ChartController {
  static values = {
    type: String,
    datasets: String,
    granularity: String,
  }
  granularityValue: string

  datasetOptions() {
    return {
      tension: 0,
      spanGaps: true,
      borderWidth: 2,
      pointBorderWidth: 2,
      pointRadius: 4,
      pointBackgroundColor: "white",
    }
  }

  chartOptions() {
    const timeFormat =
      this.granularityValue == "week"
        ? {
            unit: "week",
            isoWeekday: true,
            displayFormats: {
              week: "dd MMM",
            },
          }
        : {
            unit: "month",
            displayFormats: {
              month: "MMM",
            },
          }
    return {
      onHover: (evt, activeEls, chart) => {
        const datasets = chart.data.datasets
        if (
          activeEls.length === 0 ||
          chart.getElementsAtEventForMode(evt, "nearest", { intersect: true }, true).length === 0
        ) {
          datasets.forEach((dataset) => {
            dataset.pointBorderColor = dataset.backgroundColor
            dataset.borderColor = dataset.backgroundColor
          })
        } else {
          const activeElsIndexes = activeEls.map((el) => el.datasetIndex)
          datasets.forEach((dataset, index) => {
            if (!(dataset.borderColor.endsWith("4D") || activeElsIndexes.includes(index))) {
              dataset.pointBorderColor = dataset.pointBorderColor + "4D"
              dataset.borderColor = dataset.borderColor + "4D"
            }
          })
        }
        chart.update()
      },
      maintainAspectRatio: false,
      parsing: {
        xAxisKey: "formattedTime",
        yAxisKey: "activity",
      },
      scales: {
        y: {
          suggestedMax: 100,
          suggestedMin: 0,
          grid: {
            color: "#E2E8F0",
            drawBorder: false,
          },
          beginAtZero: true,
          ticks: {
            stepSize: 20,
            count: 6,
            maxTicksLimit: 10,
            color: "#6A778A",
            font: {
              size: 14,
            },
            callback: (value, _index, _values) => value + "%",
          },
          title: {
            display: true,
            text: "Active Users (%)",
            color: "#6A778A",
            font: {
              size: 14,
            },
          },
        },
        x: {
          offset: true,
          grid: {
            drawOnChartArea: false,
          },
          type: "time",
          time: timeFormat,
          ticks: {
            color: "#6A778A",
            font: {
              size: 14,
            },
          },
        },
      },
      plugins: {
        legend: {
          onClick: (e, legendItem, legend) => {
            const index = legendItem.datasetIndex
            const ci = legend.chart
            if (ci.isDatasetVisible(index)) {
              ci.hide(index)
            } else {
              ci.show(index)
            }
          },
          position: "bottom",
          labels: {
            generateLabels: (chart) => {
              const datasets = chart.data.datasets
              const {
                labels: { pointStyle, color },
              } = chart.legend.options

              return datasets.map((dataset, i) => {
                const meta = chart.getDatasetMeta(i)
                const style = meta.controller.getStyle(i)
                return {
                  text: datasets[meta.index].label,
                  fillStyle: meta.visible ? style.borderColor : "#E2E8F0",
                  fontColor: color,
                  strokeStyle: meta.visible ? style.borderColor : "#E2E8F0",
                  lineWidth: 0,
                  pointStyle: "rectRounded",
                  hidden: !meta.visible,
                  lineCap: style.borderCapStyle,
                  lineDash: style.borderDash,
                  lineDashOffset: style.borderDashOffset,
                  lineJoin: style.borderJoinStyle,
                  datasetIndex: meta.index,
                }
              })
            },
            boxWidth: 10,
            boxHeight: 10,
            usePointStyle: true,
            padding: 16,
          },
        },
        tooltip: {
          enabled: false,
          padding: 12,
          yAlign: "bottom",
          external: (context) => {
            // Tooltip Element
            let tooltipEl = document.getElementById("chartjs-tooltip")

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement("div")
              tooltipEl.id = "chartjs-tooltip"
              tooltipEl.innerHTML = "<table></table>"
              document.body.appendChild(tooltipEl)
            }

            // Hide if no tooltip
            const tooltipModel = context.tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0
              return
            }

            // Set caret Position
            tooltipEl.classList.remove("above", "below", "no-transform")
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign)
            } else {
              tooltipEl.classList.add("no-transform")
            }

            // Set Text
            if (tooltipModel.body) {
              const bodyHTML = tooltipModel.dataPoints
                .map(
                  (el) => `
                    <span><b>${el.dataset.label} (${el.formattedValue}%)</b></span>
                    <span>Total Contract Value: <b>${el.raw.totalContractValue}</b></span>
                    <span>Next Purchase Date: <b>${el.raw.nextPurchaseDate}</b></span>
                    <span>Active Users: <b>${el.raw.Active}</b></span>
                    <span>Inactive Users: <b>${el.raw.Inactive}</b></span>
                    <span>Total: <b>${el.raw.provisioned}</b></span>
              `,
                )
                .join("</br>")

              tooltipEl.innerHTML = `
                <style>
                 .tooltip-content {
                    min-width: 300px;
                    background-color: black;
                    text-align: left;
                    position: absolute;
                    z-index: 1;
                    bottom: 160%;
                    left: 50%;
                    margin-bottom: 20px;
                    margin-left: -80px;
                    background-color: rgba(45,55,72, 0.9);
                    display:flex;
                    flex-direction:column;
                    align-items: flex-start;
                    padding: 10px 16px;
                    gap: 5px;
                    border-radius: 6px;
                    font-style: normal;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.25px;
                    color: #FFFFFF;
                  }
                 .tooltip-content:after {
                  content: "";
                  position: absolute;
                  top: 100%;
                  left: 50%;
                  margin-left: -5px;
                  border-width: 5px;
                  border-style: solid;
                  border-color: rgba(45,55,72, 0.9) transparent transparent transparent;
                 }
                </style>
                <div class="tooltip-content">
                    ${bodyHTML}
                </div>
              `
            }

            const position = context.chart.canvas.getBoundingClientRect()

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1
            tooltipEl.style.position = "absolute"
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + "px"
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + "px"
            tooltipEl.style.padding = tooltipModel.padding + "px " + tooltipModel.padding + "px"
            tooltipEl.style.pointerEvents = "none"
          },
        },
      },
    }
  }

  chartPlugins() {
    return [
      {
        id: "tooltipLine",
        beforeDraw: (chart) => {
          if (chart.tooltip._active && chart.tooltip._active.length) {
            const ctx = chart.ctx
            ctx.save()
            const activePoint = chart.tooltip._active[0]
            ctx.beginPath()
            ctx.setLineDash([5, 7])
            ctx.moveTo(activePoint.element.x, chart.chartArea.top)
            ctx.lineTo(activePoint.element.x, chart.chartArea.bottom)
            ctx.lineWidth = 2
            ctx.strokeStyle = "#A0AEC0"
            ctx.stroke()
            ctx.restore()
          }
        },
      },
    ]
  }
}
