import { Controller } from "@hotwired/stimulus"
import { show, hide } from "../../utils"

// Connects to data-controller="analytics--chart-filter"
export default class extends Controller {
  static targets = ["submitButton", "supplierSelect"]
  submitButtonTarget: HTMLButtonElement
  supplierSelectTarget: HTMLElement

  static values = {
    filter: String,
  }
  filterValue: string

  connect() {
    if (!this.filterValue.startsWith("Choose suppliers")) {
      hide(this.supplierSelectTarget)
    }
  }

  optionChanged(event) {
    if (event.target.value == "Choose suppliers") {
      show(this.supplierSelectTarget)
      this.supplierChanged()
    } else {
      hide(this.supplierSelectTarget)
      this.submitButtonTarget.disabled = false
      this.submitButtonTarget.classList.remove("disabled")
    }
  }

  supplierChanged() {
    let select = this.supplierSelectTarget.querySelector("#custom_suppliers_list")
    if (select && select.value) {
      this.submitButtonTarget.disabled = false
      this.submitButtonTarget.classList.remove("disabled")
    } else {
      this.submitButtonTarget.disabled = true
      this.submitButtonTarget.classList.add("disabled")
    }
  }
}
