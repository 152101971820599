import React, { Dispatch, SetStateAction } from "react"
import styles from "./GoogleSocial.module.scss"
import Plus from "../../../../../assets/images/plus.svg"
import { Employee } from "../../../../utils/spendControlConstants"

interface IProps {
  isAvailableToConnect: boolean
  setIsOpenConnectionDrawer: Dispatch<SetStateAction<boolean>>
  setIsOpenGoogleSocialUsersDrawer: Dispatch<SetStateAction<boolean>>
  googleSocialData: Employee[]
}

const GoogleSocial: React.FC<IProps> = ({
  isAvailableToConnect,
  setIsOpenConnectionDrawer,
  googleSocialData,
  setIsOpenGoogleSocialUsersDrawer,
  showAddConnectionBtn,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.employeesCount}>
        <div className={styles.count}>{googleSocialData.data.length}</div>
        <div>
          Employees accessed via
          <br />
          Google Social
        </div>
        <div className={`${styles.button} button-primary`} onClick={() => setIsOpenGoogleSocialUsersDrawer(true)}>
          View employees
        </div>
      </div>
      <div className={styles.connectionDescription}>
        <div>
          Google Social does not consistently track application usage, but it does show if an employee has accessed the
          application with their Google account.
          {showAddConnectionBtn && " Add a direct connection for better usage data."}
        </div>
        {showAddConnectionBtn && (
          <div className={`${styles.connectButton} button-secondary`} onClick={() => setIsOpenConnectionDrawer(true)}>
            <div className={"pr-2"}>
              <Plus />
            </div>
            Add Connection
          </div>
        )}
      </div>
    </div>
  )
}

export default GoogleSocial
