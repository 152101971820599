import React, { useCallback } from "react"
import { Panel, useReactFlow } from "reactflow"
import Dropdown from "../../dropdown/dropdown"
import { IStageNode } from "../../../../utils/types"

interface StageNavigationProps {
  stageNodes: IStageNode[]
}

const StageNavigation = (props: StageNavigationProps) => {
  const { stageNodes } = props
  const { setViewport, getViewport } = useReactFlow()

  const jumpToStage = useCallback(
    (jumpToViewport) => {
      setViewport(jumpToViewport)
    },
    [setViewport],
  )

  const jumpToPrevStage = useCallback(() => {
    let prevStage = calculatePrevStage()
    if (prevStage) {
      setViewport(prevStage.data.jumpToViewport)
    }
  }, [setViewport])

  const jumpToNextStage = useCallback(() => {
    let nextStage = calculateNextStage()
    if (nextStage) {
      setViewport(nextStage.data.jumpToViewport)
    }
  }, [setViewport])

  const calculatePrevStage = () => {
    // sorts the stage nodes by x position in descending order
    let stageNodesByXPositionDesc = stageNodes.sort((a, b) => b.position.x - a.position.x)
    // returns the first stage node with a jumpToViewport x position that is greater than the current viewport x position
    return stageNodesByXPositionDesc.find((node) => node.data.jumpToViewport.x > getViewport().x)
  }

  const calculateNextStage = () => {
    // sorts the stage nodes by x position in ascending order
    let stageNodesByXPositionAsc = stageNodes.sort((a, b) => a.position.x - b.position.x)
    // returns the first stage node with a jumpToViewport x position that is less than the current viewport x position
    return stageNodesByXPositionAsc.find((node) => node.data.jumpToViewport.x < getViewport().x)
  }

  const sortedStageNodes = stageNodes.sort((a, b) => a.data.order - b.data.order)

  return (
    <Panel position="top-right" className="jump-to-stage-nav">
      <div className="flex flex-row gap-2">
        <button
          className="text-white bg-gray-800 hover:bg-purple-500 active:bg-purple-800 rounded-md shadow-sm px-2 py-1 w-7 h-7 flex items-center justify-center jump-to-prev-stage-button"
          onClick={() => jumpToPrevStage()}
        >
          <i className="fas fa-arrow-left"></i>
        </button>

        <Dropdown
          displayText={`Jump To Stage`}
          contentStyle={{ width: 216 }}
          style={{ minWidth: 120 }}
          className="jump-to-stage-dropdown"
          buttonClassName={"text-white !bg-gray-800 hover:!bg-purple-500 rounded-md shadow-sm pl-2 pr-1 py-1 !h-7"}
          dropdownClassName={
            "border border-gray-300 shadow-lg rounded-md right-0 p-[6px] !bg-white jump-to-stage-dropdown-items"
          }
          caretColor={"white"}
          expandedButtonClassName={"!bg-purple-800"}
        >
          {sortedStageNodes.map((node) => (
            <div
              className="py-[6px] px-[8px] text-gray-800 jump-to-stage-dropdown-item"
              key={node.data.name}
              onClick={() => jumpToStage(node.data.jumpToViewport)}
            >
              {node.data.dropdownName}
            </div>
          ))}
        </Dropdown>

        <button
          className="text-white bg-gray-800 hover:bg-purple-500 active:bg-purple-800 rounded-md shadow-sm px-2 py-1 w-7 h-7 flex items-center justify-center jump-to-next-stage-button"
          onClick={() => jumpToNextStage()}
        >
          <i className="fas fa-arrow-right"></i>
        </button>
      </div>
    </Panel>
  )
}

export default StageNavigation
